import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { postDciApi } from "../../utils/callDciApi";

interface WorkQueue {
    workQueueId: number,
    name: string
}

const fetchWorkQueues = async (token: string): Promise<WorkQueue[]> => {
    const response = await postDciApi('{allWorkQueues{workQueueId,name}}', token);
    if (response.errors) {
        throw new Error(response.errors[0]);
    } else {
        return response.data.allWorkQueues as WorkQueue[];
    }
    // TODO: Error handling
}

const useWorkQueues = () => {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery({
        queryKey: ["work-queues"],
        queryFn: async () => fetchWorkQueues(await getAccessTokenSilently())
    })
}

export { fetchWorkQueues, useWorkQueues }
export type { WorkQueue }