import { FilterNode } from "./TableFilter";
import { SetterFunction } from "../Zustand/Shared";
import { FILTER_TYPE, getFilterUrlSerializer } from "./FilterRepository";
import { TableColumnFilter } from "./types";
import React, { useContext } from "react";
import { StoreApi, useStore } from "zustand";

const FilterStoreContext = React.createContext<StoreApi<FilterState>>(null as any as StoreApi<FilterState>);

const useFilterState = <TProp,>(selector: (state: FilterState) => TProp) => {
    const context = useContext(FilterStoreContext);
    return useStore(context, selector);
}

interface FilterState {
    filter: FilterNode[],
    filterColumns: TableColumnFilter[],
    setFilterNode: (node: FilterNode) => void
    removeFilterNode: (nodeId: string) => void
    showFilter: boolean
    setShowFilter: (show: boolean) => void
}

const setFilterNode = (set: SetterFunction<FilterState>, node: FilterNode) => {
    set(existing => {
        const newList: FilterNode[] = [];
        let found = false;
        existing.filter.forEach(existingNode => {
            if (existingNode.id === node.id) {
                newList.push(node);
                found = true;
            } else {
                newList.push(existingNode);
            }
        });

        if (!found) {
            newList.push(node);
        }

        return {
            currentPage: 0,
            filter: newList 
        };
    });

    // TODO: This basically triggers the refresh of data, it would be good to see if we can do this
    // differently, ideally have the state subscribed to itself and watching for it
    // const state = get();
    // state.updateData(false, false);
}

const removeFilterNode = (set: SetterFunction<FilterState>, nodeId: string) => {
    set(state => ({
        currentPage: 0,
        filter: state.filter.filter(n => n.id !== nodeId)
    }));

    // TODO: This basically triggers the refresh of data, it would be good to see if we can do this
    // differently, ideally have the state subscribed to itself and watching for it
    // const state = get();
    // state.updateData(false, false);
}

const saveFilterStateToURLSearchParams = (state: FilterState, searchParams: URLSearchParams, prefix: string) => {
    const filtersWithNoValue = state.filterColumns.map(f => `${prefix}${f.name}`)

    state.filter
        .filter(f => f.valueIsValid && typeof f !== 'undefined' && f !== null && f.column !== null)
        .map(f => {
            const serializer = getFilterUrlSerializer(f.column!.type);
            if (serializer !== null) {
                return {
                    key: `${prefix}${f.column?.name}`,
                    value: serializer.serialize(f.value)
                }
            }

            return null;
        })
        .forEach(p => {
            if (p !== null) {
                const index = filtersWithNoValue.findIndex(f => f === p.key);
                if (index !== -1) {
                    filtersWithNoValue.splice(index, 1);
                }
                searchParams.delete(p.key);
                searchParams.set(p.key, p.value);
            }
        });

    filtersWithNoValue.forEach(f => searchParams.delete(f));
}

// TODO: It would be ideal if we could get these columns from the state, however as this is
// called at the point of hydrating the store, this is not possible in the normal way, keep
// searching for a solution
const getFilterStateFromUrl = async (filterColumns: TableColumnFilter[], prefix: string = ''): Promise<Partial<FilterState>> => {
    const queryStringParams = new URLSearchParams(window.location.search);
    const result: Partial<FilterState> = {
        filter: []
    };

    for (const fc of filterColumns) {
        const key = `${prefix}${fc.name}`;
        const values = queryStringParams.getAll(key);
        if (values.length === 0) {
            continue;
        }

        const serializer = getFilterUrlSerializer(fc.type);
        if (serializer === null) {
            continue;
        }

        for (const v of values) {
            const newValue = await serializer.deserialize(v);
            if (newValue !== null) {
                result.filter!.push({
                    column: fc,
                    id: crypto.randomUUID(),
                    valueIsValid: true,
                    value: newValue
                })
            }
        }
    }

    return result;
}

export {
    FilterStoreContext,
    getFilterStateFromUrl,
    removeFilterNode,
    saveFilterStateToURLSearchParams,
    setFilterNode,
    useFilterState
}
export type { FilterState }