const isUpperCase = (char: string) => char.toUpperCase() === char;
const isDigit = (char: string) => char.charAt(0) >= '0' && char.charAt(0) <= '9';
const charClass = (char: string) => isDigit(char) ? 'digit' : isUpperCase(char) ? 'upper' : 'lower';
const needSpace = (prev: CharClass, next: CharClass) => prev === next || (prev === 'upper' && next === 'lower') ? false : true;

type CharClass = 'digit' | 'upper' | 'lower' | null;

const getDisplayName = (propertyName: string) => {
    let name = '';

    let previousCharClass: CharClass = null;
    for (let index = 0; index < propertyName.length; index++) {
        const element = propertyName[index];
        
        const currentCharClass = charClass(element);
        if (currentCharClass !== previousCharClass) {
            if (index > 0 && needSpace(previousCharClass, currentCharClass)) {
                name += ' ';
            }

            if (index === 0 || (previousCharClass === 'digit' && currentCharClass === 'lower')) {
                name += element.toUpperCase();
            }
            else {
                name += element;
            }
        }
        else {
            name += element;
        }

        previousCharClass = currentCharClass;
    }

    return name;
}

export { getDisplayName }