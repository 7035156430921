import { Flag, Person, PersonOff } from "@mui/icons-material";
import { Stack, TableCell, ToggleButton } from "@mui/material";
import { differenceInCalendarDays, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import dciPaths from "../../utils/dciPaths";
import DciLink from "../DciLink";
import { MeValue } from "../Filtering/UserFilter";
import { useTableState } from "../Table/Stores/TableStore";
import { Table } from "../Table/Table";
import { TableContainer } from "../Table/TableContainer";
import { TableColumn } from "../Filtering/types";
import { WorkItemActionsMenu } from "./WorkItemActionsMenu";
import { FILTER_TYPE } from "../Filtering/FilterRepository";

interface RuleCellProps {
    row: WorkItem
}

const RuleCell = ({ row }: RuleCellProps) => {
    return (
        <TableCell 
            style={{ textAlign:'center' }} 
            key='ruleNo'
        >
            <DciLink href={dciPaths.rule.buildLink(row.reportTestId)} onClick={e => e.stopPropagation()}>
                {row.reportTestId}
            </DciLink>
        </TableCell>
    )
}

const columns: TableColumn[] = [
    {
        displayName: "ID",
        selector: "workItemId",
        style: { width:'78px' }
    },
    {
        displayName: 'Notes',
        selector: 'noteCount',
        justify: 'center',
        sortable: false,
        style: { width:'58px' }
    },
    {
        displayName: "Found",
        selector: 'creationDatetime',
        render: (row: WorkItem) => <TableCell style={{ padding:'10px' }} key='creationDatetime'>{row.creationDatetime.toLocaleDateString()}</TableCell>,
        style: { width:'96px' }
    },
    { 
        displayName: "Due",
        selector: 'dueDate',
        render: (row: WorkItem) => <TableCell style={{ padding:'10px', color: differenceInCalendarDays(new Date(), row.dueDate) > 0 ? 'red' : 'inherit' }} key='dueDate'>{row.dueDate.toLocaleDateString()}</TableCell>,
        style: { width:'96px' }
    },
    {
        displayName: "Message",
        selector: "reportMessage"
    },
    {
        displayName: "Rule",
        selector: 'reportTestId',
        justify: 'center',
        render: row => <RuleCell row={row} />,
        style: { width:'78px', maxWidth:'78px' }
    },
    {
        displayName: "Status",
        sortable: 'workItemStatusId',
        selector: 'status',
        style: { width:'158px' }
    },
    { 
        displayName: "Priority",
        sortable: 'workItemPriority',
        justify: 'center',
        selector: "priority",
        style: { width:'92px' }
    },
    { 
        displayName: "RI",
        sortable: 'regulatoryImpact.regulatoryImpactValue',
        justify: 'center',
        render: (row: WorkItem) => 
            <TableCell 
                style={{ textAlign:'center' }} 
                key='regImpact' 
                padding='checkbox'
            >
                { row.regulatoryImpactDisplayIcon && 
                    <Flag
                        style={{ color:row.regulatoryImpactDisplayIcon }} 
                        // onMouseEnter={event => handlePopoverOpen(event, row.regulatoryImpactDescription)}
                        // onMouseLeave={handleRegImpactPopoverClose} 
                    />
                }
            </TableCell>,
        selector: 'regulatoryImpactValue',
        style: { width:'58px' }
    },
];

interface WorkItem {
    workItemId: number,
    noteCount: number,
    creationDatetime: Date,
    dueDate: Date,
    reportMessage: string,
    reportTestId: number,
    status: string,
    priority: number,
    regulatoryImpact: string,
    regulatoryImpactValue: number,
    regulatoryImpactDisplayIcon: string,
    [property: string]: any
}

const MyItemsOnlyFilterNodeId = 'MyItemsOnly';
const MyItemsOnlyButton = () => {
    const filter = useTableState(s => s.filter);
    const removeFilterNode = useTableState(s => s.removeFilterNode);
    const setFilterNode = useTableState(s => s.setFilterNode);

    const enabled = filter.findIndex(n => n.id === MyItemsOnlyFilterNodeId) !== -1;

    const onToggle = () => {
        if (enabled) {
            removeFilterNode(MyItemsOnlyFilterNodeId);
        } else {
            setFilterNode({
                column: {
                    name: 'filter.processingUserId',
                    displayName: 'Processing User',
                    type: FILTER_TYPE.USER
                },
                id: MyItemsOnlyFilterNodeId,
                valueIsValid: true,
                value: [ MeValue ]
            })
        }
    }

    return <ToggleButton
        value={enabled}
        onClick={onToggle}
        style={{ padding:'6px', margin:'0px 4px' }}
    >
        { enabled ? <Person color='secondary' /> : <PersonOff /> }
    </ToggleButton>
}

const ToolbarButtons = () => {
    const selectedData = useTableState(s => s.selectedData);
    const updateData = useTableState(s => s.updateRowData);

    return <Stack direction='row'>
        <WorkItemActionsMenu workItemIds={selectedData.map(m => m.workItemId)} refreshParent={() => updateData(true)} />
        <MyItemsOnlyButton />
    </Stack>
}

interface WorkItemsProps {
    title: string,
}

const WorkItemsList = ({ title }: WorkItemsProps) => {
    const navigate = useNavigate();
    const onRowClick = (row: WorkItem) => navigate(dciPaths.workItem.buildLink(row.workItemId));

    return (
        <TableContainer
            title={title}
            toolbarButtons={<ToolbarButtons />}
            paged
            enableGlobalSelectAll
            style={{ height:'100%', width:'100%' }}
            enableFilter
            enableExport
        >
            <Table
                selectable={true}
                uniqueSortColumn='workItemId'
                columns={columns}
                idFromValue={row => row.workItemId}
                onRowClick={onRowClick}
            />
        </TableContainer>
    )
}

const defaultQueryColumns = '{workItemId creationDatetime dueDate reportMessage reportTestId regulatoryImpact{regulatoryImpactId displayIcon regulatoryImpactValue description}workItemPriority workItemStatus{description}workItemStatusId notes{workItemNoteId}}';

const defaultMappingFunction = (input: any): WorkItem => ({
    workItemId: input.workItemId,
    noteCount: input.notes.length,
    creationDatetime: parseISO(input.creationDatetime),
    dueDate: parseISO(input.dueDate),
    reportMessage: input.reportMessage,
    reportTestId: input.reportTestId,
    status: input.workItemStatus.description,
    priority: input.workItemPriority,
    regulatoryImpact: input.regulatoryImpact.description,
    regulatoryImpactValue: input.regulatoryImpact.regulatoryImpactValue,
    regulatoryImpactDisplayIcon: input.regulatoryImpact.displayIcon
});

export { WorkItemsList, defaultQueryColumns, defaultMappingFunction }
export type { WorkItem }