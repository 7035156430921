import {
    Typography
} from '@mui/material';
import ScreenCapture from './Images/ActiveItemsChart.png';

const WNActiveItemsChart = () => {
    return (
        <>
            <Typography variant='h5'>Active Items Chart</Typography>
            <Typography variant='body1'>A new chart is now shown on the dashboard with the option to show Active, Overdue, Added or Resolved Work Items. The grouping and timeframe are configurable.</Typography>
            <img style={{ display:'block', margin:'20px auto' }} src={ScreenCapture} alt="Active Items Chart screen capture" />
            <Typography variant='body1'>The new options are also availble in the Reporting screen.</Typography>
        </>
    )
}

export { WNActiveItemsChart }