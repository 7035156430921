import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { isEqual, sortBy } from 'lodash';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead
} from '@mui/material';
import callDciApi from '../utils/callDciApi';

type AddEditUserDialogProps = {
    teamId: number,
    isOpen: boolean,
    close: (refreshParent: boolean) => void,
    editUserId: number | null,
    existingRoleIds: number[],
    readOnly: boolean
}

type User = {
    userId: number,
    firstName: string,
    surname: string
}

type Role = {
    roleId: number,
    description: string
}

export default function AddEditUserDialog({ teamId, isOpen, close, editUserId, existingRoleIds, readOnly = false }: AddEditUserDialogProps) {
    const [ title, setTitle ] = useState('Add User');
    const [ users, setUsers ] = useState<User[]>([]);
    const [ userValue, setUserValue ] = useState<User | null>(null);
    const [ roles, setRoles ] = useState<Role[]>([]);
    const [ selectedRoles, setSelectedRoles ] = useState<number[]>([]);
    const { getAccessTokenSilently } = useAuth0();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        const getData = async <T,>(token: string, query: string, queryName: string, success: (body: T[]) => void) => {
            callDciApi(query, token)
            .then(body => {
                if (!body.errors) {
                    success(body.data[queryName].nodes);
                }
            })
            .catch(error => {
                console.error(`[AddUser] ${queryName}: ${error}`);
            });
        }

        const getAllData = async () => {
            const token = await getAccessTokenSilently();
            await getData<User>(token, '{allUsers(where:{enabled:{eq:true}}){nodes{userId,firstName,surname}}}', 'allUsers', body => setUsers(body));
            await getData<Role>(token, '{allRoles{nodes{roleId,description}}}', 'allRoles', body => setRoles(body));

            if (editUserId) {
                setTitle(readOnly ? 'User' : 'Edit User');
                var editUser = users.find(m => m.userId === editUserId);
                if (editUser) {
                    setUserValue(editUser);
                    setTitle(`${readOnly ? '' : 'Edit '}User: ${editUser.firstName} ${editUser.surname}`);
                }

                setSelectedRoles([ ...existingRoleIds ]);
            }
            else {
                setTitle('Add User');
                setUserValue(null);
                setSelectedRoles([]);
            }
        };

        getAllData();
    }, [ getAccessTokenSilently, isOpen, editUserId ]);

    const onSave = async () => {
        const token = await getAccessTokenSilently();
        callDciApi(`mutation{setUserTeamRoles(newRoleIds:[${selectedRoles.join()}],teamId:${teamId},userId:${userValue!.userId})}`, token)
        .then(body => {
            if (!body.errors) {
                close(true);
            }
        })
        .catch(error => {
            console.error(`[AddEditUserDialog] addUserToTeam: ${error}`);
        });
    };

    const onRoleCheck = (roleId: number, checked: boolean) => {
        if (checked === true) {
            setSelectedRoles([
                ...selectedRoles,
                roleId
            ])
        }
        else {
            var index = selectedRoles.indexOf(roleId);
            if (index !== -1) {
                setSelectedRoles([
                    ...selectedRoles.slice(0, index),
                    ...selectedRoles.slice(index + 1)
                ]);
            }
        }
    };

    const selectAll = (checked: boolean) => {
        if (checked === true) {
            setSelectedRoles(roles.map(m => m.roleId));
        }
        else {
            setSelectedRoles([]);
        }
    };

    const onUserChange = (_: React.SyntheticEvent, newValue: User | null) => {
        setUserValue(newValue);
    };

    return (
        <Dialog open={isOpen} fullScreen={fullScreen} maxWidth={'sm'} fullWidth aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Select a user and the roles which they should have for this team
            </DialogContentText>
            
            <Autocomplete<User>
                id="user"
                disabled={ readOnly || typeof(editUserId) === 'number' }
                options={users}
                getOptionLabel={(user) => [user.firstName, user.surname].join(' ')}
                isOptionEqualToValue={(o, v) => o.userId === v.userId}
                style={{ marginBottom:'10px' }}
                renderInput={(params) => <TextField {...params} label="User" variant="outlined" />}
                value={userValue}
                onChange={onUserChange}
                />
            <Box border={1} style={{ minHeight:'300px', width:'100%', overflowY:'scroll' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell padding='checkbox'><Checkbox color='secondary' disabled={readOnly} checked={selectedRoles.length === roles.length} onChange={event => selectAll(event.target.checked)} /></TableCell>
                            <TableCell>Role</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {roles.map(m => 
                            <TableRow key={m.roleId}>
                                <TableCell padding='checkbox'><Checkbox color='secondary' disabled={readOnly} checked={selectedRoles.indexOf(m.roleId) !== -1} onChange={event => onRoleCheck(m.roleId, event.target.checked)} /></TableCell>
                                <TableCell>{m.description}</TableCell>
                            </TableRow>)}
                    </TableBody>
                </Table>
            </Box>
            
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close(false)}>{ readOnly ? 'Close' : 'Cancel' }</Button>
                { !readOnly && 
                <Button 
                    disabled={typeof(editUserId) === 'number' ? isEqual(sortBy(selectedRoles), sortBy(existingRoleIds)) : (userValue === null || selectedRoles.length === 0)} 
                    onClick={onSave}
                >
                    {editUserId ? 'Save' : 'Add'}
                </Button>}
            </DialogActions>
        </Dialog>
    );
};