import React from 'react';
import {
    Card,
    CardContent,
    CircularProgress,
    Typography
} from '@mui/material';
import { ConditionalLink } from '../ConditionalLink';
import { ActiveWorkItemCountByPriorityDataContext } from '../DataProviders/ActiveWorkItemCountByPriorityDataContext';
import dciPaths from '../../utils/dciPaths';
import { ReportTypes } from '../../utils/ReportTypes';
import { encodeURI } from 'js-base64';
import { PARAMETER_TYPE, QueryParameter } from '../../GraphQLShared';

interface ActiveWorkItemCountByPriorityCardProps {
    workQueueIds: number[],
    teamIds: number[]
}

const ActiveWorkItemCountByPriorityCard = ({ workQueueIds, teamIds }: ActiveWorkItemCountByPriorityCardProps) => {
    const data = React.useContext(ActiveWorkItemCountByPriorityDataContext);

    const reportLink = (priority: number) => {
        var reportParameters = {
            title: `Active Work Items with Priority: ${priority > 0 ? priority : 'Not Defined'}`,
            queryFilter: [ "{workItemStatusId:{in:[1,2,3,4,6,8]}}", `{workItemPriority:{eq:${priority}}}` ],
            parameters: [
                {
                    name: "fixedFilter.workItemPriority",
                    type: PARAMETER_TYPE.NUMBER,
                    value: [ priority ]
                },
                {
                    name: "fixedFilter.workItemStatusId",
                    type: PARAMETER_TYPE.NUMBER,
                    value: [ 1, 2, 3, 4, 6, 8 ]
                }
            ] as QueryParameter[]
        };

        if (workQueueIds.length > 0) {
            reportParameters.parameters.push({
                name: 'fixedFilter.workQueueId',
                type: PARAMETER_TYPE.NUMBER,
                value: workQueueIds
            });
        }

        if (teamIds.length > 0) {
            reportParameters.parameters.push({
                name: 'fixedFilter.teamId',
                type: PARAMETER_TYPE.NUMBER,
                value: teamIds
            });
        }

        const base64 = encodeURI(JSON.stringify(reportParameters));
        return `/workitems?q=${base64}`;
    };

    return (data 
      ? <Card>
            <CardContent>
                <Typography gutterBottom variant="h6" component="h2">Active Work Items by Priority</Typography>
                { data.fetching
                    ? <CircularProgress />
                    : data.rows.map(m => 
                        <ConditionalLink key={m.priority} link={reportLink(m.priority)}>
                            <Typography>{m.label} = {m.count}</Typography>
                        </ConditionalLink>) 
                }
            </CardContent>
        </Card> 
      : <p>No data available.</p>
    )
}

export { ActiveWorkItemCountByPriorityCard };