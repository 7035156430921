import { addMultipleParameters, addParameterToObject, addToUniqueParameters, finaliseParameters, getParameterApplicator, ParameterType } from "../../GraphQLShared";
import { buildQueryUsingPrebuiltFilter } from "../../QueryBuilder";
import { convertToCsvFromEntities } from "../../QueryResultToCsvConverter";
import { callDciApiCancellableNew, cancelledMessage } from "../../utils/callDciApi";
import authClient from "../Auth/auth-client";
import { getParameterConverter } from "../Filtering/FilterRepository";
import { SetterFunction } from "../Zustand/Shared";
import { ColumnSort, RowId } from "./Shared";
import { TableState } from "./Stores/TableStore";

const MAX_ROWS = 5000;

async function rawGetData(state: TableState, numRows: number) {
    const token = await authClient.getTokenSilently();
    const validFilters = state.filter.filter(f => f.valueIsValid && f.column !== null);
    const parameters = {};
    const uniqueParameterNameTypes: { name: string, type: ParameterType }[] = [];

    validFilters.forEach(f => {
        const converter = getParameterConverter(f.column!.type);
        if (converter === null) {
            throw Error('Unable to convert filter value to a parameter.');
        }

        const parameter = converter(f.column!.name, f.value);
        addToUniqueParameters(uniqueParameterNameTypes, parameter);

        const applicator = getParameterApplicator(parameter.type);
        if (applicator === null) {
            throw Error('Unable to apply value to the parameters.');
        }

        addParameterToObject(parameter.name, parameter.value, applicator, parameters);
    });

    addMultipleParameters(state.fixedParameters, parameters, uniqueParameterNameTypes);

    const finalParameters = finaliseParameters(parameters, uniqueParameterNameTypes);

    const query = true ? buildQueryUsingPrebuiltFilter(
        numRows,
        0,
        state.uniqueSortColumn,
        true,
        finalParameters,
        state.sortOrder,
        state.graphQLQueryName,
        state.graphQLQueryColumns
    ) : '';

    const cancellablePromise = callDciApiCancellableNew(query, token);
    const body = await cancellablePromise.promise;
    if (body.errors) {
        throw new Error(body.errors[0]);
    }

    return { 
        data: body.data[state.graphQLQueryName].nodes.map(state.mappingFunction),
        totalRows: body.data[state.graphQLQueryName].totalCount
    }
}

async function getDataImplementation(state: TableState, set: SetterFunction<TableState>) {
    // getData(state.sortOrder, state.filter, state.currentPage, state.pageSize);
    if (state.rowDataCancellablePromise !== null) {
        state.rowDataCancellablePromise.abortController.abort();
        set({
            rowData: {
                data: [],
                isCancelled: true,
                isFetching: false,
                isError: false
            },
            rowDataCancellablePromise: null
        });
    }

    const token = await authClient.getTokenSilently();
    const validFilters = state.filter.filter(f => f.valueIsValid && f.column !== null);
    const parameters = {};
    const uniqueParameterNameTypes: { name: string, type: ParameterType }[] = [];

    validFilters.forEach(f => {
        const converter = getParameterConverter(f.column!.type);
        if (converter === null) {
            throw Error('Unable to convert filter value to a parameter.');
        }

        const parameter = converter(f.column!.name, f.value);
        addToUniqueParameters(uniqueParameterNameTypes, parameter);

        const applicator = getParameterApplicator(parameter.type);
        if (applicator === null) {
            throw Error('Unable to apply value to the parameters.');
        }

        addParameterToObject(parameter.name, parameter.value, applicator, parameters);
    });

    addMultipleParameters(state.fixedParameters, parameters, uniqueParameterNameTypes);

    const finalParameters = finaliseParameters(parameters, uniqueParameterNameTypes);

    const query = true ? buildQueryUsingPrebuiltFilter(
        state.pageSize,
        state.currentPage,
        state.uniqueSortColumn,
        state.paged,
        finalParameters,
        state.sortOrder,
        state.graphQLQueryName,
        state.graphQLQueryColumns
    ) : '';

    const cancellablePromise = callDciApiCancellableNew(query, token);
    set({
        initialised: true,
        globalSelectAllState: 'UNAVAILABLE',
        selectedData: [],
        selectionEnabled: false,
        rowData: {
            data: [],
            isCancelled: false,
            isError: false,
            isFetching: true
        },
        rowDataCancellablePromise: cancellablePromise
    });

    try {
        const body = await cancellablePromise.promise;
        if (body.errors) {
            // TODO: Throw better stuff and allow injected(?) handlers
            if (Array.isArray(body.errors) && body.errors.length > 0 && body.errors[0] === cancelledMessage) {
                // TODO: Propagate cancelled flag
                return {
                    data: [],
                    totalRows: 0
                }
            }

            throw new Error(body.errors[0]);
        }
        
        set({
            rowData: {
                isCancelled: false,
                isError: false,
                isFetching: false,
                data: state.paged 
                    ? body.data[state.graphQLQueryName].nodes.map(state.mappingFunction)
                    : body.data[state.graphQLQueryName].map(state.mappingFunction)
            },
            rowDataCancellablePromise: null,
            totalRows: state.paged
                ? body.data[state.graphQLQueryName].totalCount
                : body.data[state.graphQLQueryName].length
        })
    } catch (error) {
        console.error(error);
        set({
            selectedData: [],
            selectionEnabled: false,
            globalSelectAllState: 'UNAVAILABLE',
            rowDataCancellablePromise: null,
            rowData: {
                data: [],
                isCancelled: false,
                isError: true,
                isFetching: false
            },
            totalRows: 0
        })
    }
}

async function updateRowData(
    set: SetterFunction<TableState>,
    get: () => TableState,
    retainSelection: boolean
) {
    let retainedSelectionData = {
        globalSelectAll: false,
        selectedIds: [] as RowId[]
    };

    let state = get();
    if (retainSelection) {
        retainedSelectionData = {
            globalSelectAll: state.globalSelectAllState === 'ACTIVE',
            selectedIds: state.selectedData.map((r: any) => state.idFromRow(r))
        };
    }

    await getDataImplementation(state, set);
    
    state = get();
    if (retainSelection) {
        if (retainedSelectionData.globalSelectAll) {
            // Retrigger now that data has been reloaded
            state.globalSelectAllClick();
        } else {
            set({
                globalSelectAllState: 'UNAVAILABLE',    
                selectionEnabled: true,
                selectedData: state.rowData.data.filter((row: any) => retainedSelectionData.selectedIds.includes(state.idFromRow(row)))
            })
        }
    } else {
        set({
            selectedData: [],
            selectionEnabled: true,
            globalSelectAllState: 'UNAVAILABLE',
        })
    }
}

function selectAllClick(set: SetterFunction<TableState>, get: () => TableState) {
    const state = get();

    if (state.rowData.isFetching || state.rowData.isError || state.rowData.isCancelled || state.globalSelectAllState === 'LOADING' ) {
        throw new Error(`[selectAllClick] Invalid state, data.isError: ${state.rowData.isError}, data.isFetching:${state.rowData.isFetching}, data.isCancelled:${state.rowData.isCancelled}, globalSelectAllState:${state.globalSelectAllState}`);
    }

    if (state.selectedData.length === state.rowData.data.length || state.globalSelectAllState === 'ACTIVE') {
        set({
            selectedData: [],
            globalSelectAllState: 'UNAVAILABLE'
        });
    } else {
        set({
            selectedData: [ ...state.rowData.data ],
            globalSelectAllState: state.totalRows > state.rowData.data.length ? 'AVAILABLE' : 'UNAVAILABLE'
        });
    }
}

function globalSelectAllClick(set: SetterFunction<TableState>, get: () => TableState) {
    const state = get();
    if (state.globalSelectAllState !== 'AVAILABLE' && state.globalSelectAllState !== 'ERROR') {
        throw new Error('[globalSelectAllClick] globalSelectState must be either AVAILABLE or ERROR before this function can be called.');
    }

    set({
        selectedData: [],
        globalSelectAllState: 'LOADING',
        selectionEnabled: false
    });

    //state.rowDataSource.getData(state.sortOrder, state.filter, 0, MAX_ROWS)
    rawGetData(state, MAX_ROWS)
    .then(response => {
        set({
            selectedData: response.data,
            globalSelectAllState: 'ACTIVE',
            totalRows: response.totalRows
        })
    })
    .catch(e => {
        set({
            globalSelectAllState: 'ERROR'
        })
    });
}

function clearSelection(set: SetterFunction<TableState>) {
    set({
        selectedData: [],
        globalSelectAllState: 'UNAVAILABLE',
        selectionEnabled: true
    })
}

function rowSelectClick(set: SetterFunction<TableState>, get: () => TableState, row: any) {
    const state = get();
    if (state.globalSelectAllState === 'ACTIVE' || state.globalSelectAllState === 'LOADING') {
        throw new Error('[rowSelectClick] Not valid to call this function while globalSelectAllState is ACTIVE or LOADING.');
    }

    const existingIndex = state.selectedData.findIndex(r => r === row);
    if (existingIndex === -1) {
        set({
            selectedData: [ ...state.selectedData, row ],
            globalSelectAllState: state.selectedData.length + 1 === state.pageSize && state.totalRows > state.rowData.data.length ? 'AVAILABLE' : 'UNAVAILABLE'
        });
    } else {
        set({
            selectedData: state.selectedData.filter(r => r !== row),
            globalSelectAllState: 'UNAVAILABLE'
        });
    }
}

function setCurrentPage(set: SetterFunction<TableState>, get: () => TableState, page: number) { 
    set({ currentPage: page });
    const state = get();
    state.updateRowData(false);
}

function setPageSize(set: SetterFunction<TableState>, get: () => TableState, pageSize: number) {
    set({
        currentPage: 0,
        pageSize: pageSize
    });
    const state = get();
    state.updateRowData(false);
}

function toggleSortColumn(set: SetterFunction<TableState>, get: () => TableState, column: string, uniqueSortColumn: string) {
    set(state => ({
        currentPage: 0,
        sortOrder: applySortImpl(state.sortOrder, column, uniqueSortColumn)
    }));
    const state = get();
    state.updateRowData(false);
}

const applySortImpl = (current: ColumnSort[], column: string, uniqueSortColumn: string): ColumnSort[] => {
    const existing = current.find(s => s.column === column);
    if (existing) {
        return [ { column: column, direction: existing.direction === 'DESC' ? 'ASC' : 'DESC' } ]
    }

    return [ { column: column, direction: 'ASC' } ];
}

const getExportCsv = async (get: () => TableState) => {
    const state = get();
    //const res = await state.rowDataSource.getData(state.sortOrder, state.filter, 0, 100000);
    const res = await rawGetData(state, 100000);

    const csv = convertToCsvFromEntities(res.data);
    return csv;
}

const setGraphQLQueryName = (set: SetterFunction<TableState>, name: string) => set({ graphQLQueryName: name });

export {
    updateRowData,
    selectAllClick,
    getExportCsv,
    globalSelectAllClick,
    clearSelection,
    rowSelectClick,
    setCurrentPage,
    setGraphQLQueryName,
    setPageSize,
    toggleSortColumn
}