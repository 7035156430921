import React from 'react';

type WorkItemCountByRegulatoryImpactDataSet = {
    rows: WorkItemCountByRegulatoryImpact[],
    fetching: boolean
}

type WorkItemCountByRegulatoryImpact = {
    regulatoryImpactValue: number,
    label: string,
    colour: string,
    count: number
};

const ActiveWorkItemCountByRegulatoryImpactDataContext = React.createContext<WorkItemCountByRegulatoryImpactDataSet | null>(null);
export { ActiveWorkItemCountByRegulatoryImpactDataContext };
export type { WorkItemCountByRegulatoryImpact, WorkItemCountByRegulatoryImpactDataSet };