import {
    Autocomplete,
    Box,
    Button,
    InputBase,
    Typography,
    Stack,
    alpha
} from '@mui/material';
import { filterControls, filterDefaultValues, TableColumnFilter } from './types';
import { FilterPill } from './FilterPill';
import { FilterAlt } from '@mui/icons-material';
import { useEffect, useMemo, useState } from 'react';
import { useFilterState } from './StoreFunctions';

type TableFilterProps = {
    dontAddInitial?: boolean,
    showDebug?: boolean
}

interface FilterNode {
    id: string
    column: TableColumnFilter | null
    value?: any
    valueIsValid: boolean
}

const ConditionAdder = ({ onAddCondition }: { onAddCondition: () => void }) => {
    return (
        <Box display='inline-flex'>
            <Button 
                variant='outlined'
                color='primary'
                onClick={() => onAddCondition()}
                sx={theme => ({
                    padding:'0px',
                    border:`1px ${theme.palette.text.primary} solid`,
                    borderRadius:'25px',
                    height:'30px',
                    '&:hover': {
                        border:`1px ${theme.palette.action.disabledBackground} solid`,
                        backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity)
                    }
                })}
            >
                <FilterAlt sx={theme => ({ color:theme.palette.text.primary })} />
                <Typography sx={theme => ({ color:theme.palette.text.primary, fontSize:'20px', marginLeft:'-8px', marginBottom:'-4px' })}>+</Typography>
            </Button>
        </Box>
    )
}

type ColumnSelectorProps = {
    value: TableColumnFilter | null,
    setValue: (value: TableColumnFilter | null) => void,
    columns: TableColumnFilter[],
    editMode: boolean
    initialOpen?: boolean
}

const ColumnSelector = ({ value, setValue, columns, editMode, initialOpen = false }: ColumnSelectorProps) => {
    const [ open, setOpen ] = useState(initialOpen);

    return editMode ?
        <Autocomplete<TableColumnFilter>
            style={{ 
                minWidth:'200px',
                marginRight:'5px',
                height:'26px'
            }}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            options={columns}
            value={value}
            onChange={(_event, value, _reason) => setValue(value)}
            getOptionLabel={o => o.displayName}
            isOptionEqualToValue={(o, v) => o.name === v.name}
            renderOption={(props, option) => <li style={{ padding:'2px 6px' }} {...props}><Typography style={{ fontSize:'14px' }} >{option.displayName}</Typography></li>}
            renderInput={({ InputProps, InputLabelProps, ...rest }) => 
                <InputBase
                    { ...InputProps }
                    {...rest}
                    sx={theme => ({ 
                        border:`1px solid ${theme.palette.text.primary}`,
                        marginRight:'5px',
                        height:'26px',
                        '& > input': {
                            padding:'2px 6px',
                            fontSize:'14px'
                        }
                    })}
                />}
        /> :
            value?.displayName ?
                <Typography style={{ fontSize:'14px' }}>{value.displayName}</Typography> :
                <Typography style={{ fontSize:'14px', fontStyle:'italic', marginRight:'2px' }}>None selected</Typography>
}

type NodeProps = {
    node: FilterNodeWithMetadata,
    updateNode: (node: FilterNodeWithMetadata, isEditComplete: boolean) => void,
    removeNode: (nodeId: string) => void,
    columns: TableColumnFilter[],
    isNewNode: boolean
}

const LeafNode = ({ node, updateNode, columns, removeNode }: NodeProps) => {
    const [ editMode, setEditMode ] = useState(node.isNew ?? false);

    const Control = useMemo(() => node.column !== null ? filterControls[node.column?.type] : null, [ node.column ]);

    const onColumnSelect = (column: TableColumnFilter | null) => {
        updateNode({ 
            ...node, 
            column: column, 
            value: column === null ? null : filterDefaultValues[column.type],
            isNew: node.isNew ? false : node.isNew
        }, false);
    }

    const onClickAway = () => {
        if (editMode) {
            setEditMode(false);
            updateNode(node, true);
        }
    }

    return (
        <FilterPill
            editable
            editMode={editMode}
            onClickAway={onClickAway}
            setEditMode={setEditMode}
            valid={node.valueIsValid ?? false}
            removable
            onRemove={() => removeNode(node.id)}
        >
            <Stack direction='row' alignItems='center'>
                <ColumnSelector value={node.column} setValue={onColumnSelect} columns={columns} editMode={editMode} initialOpen={node.isNew} />
                { Control && 
                    <Control
                        editMode={editMode}
                        setEditMode={setEditMode}
                        value={node.value}
                        setValue={(value, isValid, isComplete) => updateNode({ ...node, value: value, valueIsValid: isValid }, isComplete)}
                    />
                }
            </Stack>
        </FilterPill>
    )
}

interface FilterNodeWithMetadata extends FilterNode {
    isNew?: boolean
}

function TableFilter({ showDebug = false, dontAddInitial = false }: TableFilterProps) {
    const [ editingNodes, setEditingNodes ] = useState<{ [key: string]: FilterNodeWithMetadata }>({});

    const setFilterNode = useFilterState(s => s.setFilterNode);
    const removeFilterNode = useFilterState(s => s.removeFilterNode);
    const filterColumns = useFilterState(s => s.filterColumns);
    const filter = useFilterState(s => s.filter);

    const createNewNode = (): FilterNode => ({
        id: crypto.randomUUID(),
        column: null,
        valueIsValid: false
    })

    // const filter = useStore(tableStore, selectors.filter);
    // const setFilterNode = useStore(tableStore, selectors.setFilterNode);
    // const removeStoreNode = useStore(tableStore, selectors.removeFilterNode);

    const updateEditingNode = (node: FilterNodeWithMetadata, complete: boolean) => {
        if (complete) {
            if (node.valueIsValid) {
                // As it is valid, update source store and remove from editing list
                setEditingNodes(existing => {
                    const { [node.id]: _, ...rest } = existing;
                    return rest; 
                });
                setFilterNode(node);
            }
        } else {
            // Just update the local copy as not completed yet
            setEditingNodes({ ...editingNodes, [node.id]: node });
        }
    }

    const addLocalNode = () => {
        const newNode = createNewNode();
        setEditingNodes(existing => ({ ...existing, [newNode.id]: { ...newNode, isNew: true } }));
    }

    const removeNode = (id: string) => {
        if (editingNodes.hasOwnProperty(id)) {
            const { [id]: _, ...rest } = editingNodes;
            setEditingNodes(rest);
        }

        //removeStoreNode(id);
        removeFilterNode(id);
    }

    useEffect(() => {
        if (!dontAddInitial) {
            addLocalNode();
        }

        return () => {
            setEditingNodes({});
        }
    }, []);

    const nodesToRender: FilterNodeWithMetadata[] = filter.map(n => editingNodes.hasOwnProperty(n.id) ? editingNodes[n.id] : n);
    const newNodeIds = Object.keys(editingNodes).filter(editId => nodesToRender.findIndex(n => n.id === editId) === -1);
    newNodeIds.forEach(id => nodesToRender.push(editingNodes[id]));

    return <Box>
        <Stack direction='row' flexWrap='wrap' spacing={1}>
            { nodesToRender.map(n => 
                <LeafNode 
                    key={n.id}
                    node={n}
                    columns={filterColumns}
                    updateNode={updateEditingNode}
                    removeNode={() => removeNode(n.id)}
                    isNewNode={n.isNew ?? false}
                />)
            }
            <ConditionAdder onAddCondition={addLocalNode} />
        </Stack>
        { showDebug && <Typography variant='caption'>{JSON.stringify(filter, null, 4)}</Typography> }
    </Box>
}

export { TableFilter }
export type { FilterNode }