import { MenuItem, Select, Stack } from "@mui/material"
import { useEffect, useState } from "react";
import { addDays, format, parse } from "date-fns";
import { ChartStoreContext, createChartStore, useChartState } from "../Reporting/Store/ChartStore";
import { NewChart } from "../Reporting/NewChart";
import { PARAMETER_TYPE, QueryParameter } from "../../GraphQLShared";
import { workItemReportingEventType } from "../../utils/dciConstants";
import { useNavigate } from "react-router-dom";
import dciPaths from "../../utils/dciPaths";
import { useSnackbar } from "notistack";
import { LineItemIdentifier } from "@mui/x-charts";

interface InnerProps {
    metric: MetricOption
    grouping: GroupingOption
    workQueueIds: number[]
    teamIds: number[]
}

const Inner = ({ metric, grouping, workQueueIds, teamIds }: InnerProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const chartData = useChartState(s => s.chartData);

    // const onAxisClick = (event: MouseEvent, data: AxisData | null) => {
    //     if (metric === 'Active' || metric === 'Overdue') {
    //         enqueueSnackbar('Click-through for Active and Overdue items coming soon.', { variant: 'info' });
    //         return;
    //     }

    //     if (data === null) {
    //         return;
    //     }
    
    //     if (typeof data.axisValue !== 'string') {
    //         return;
    //     }
    
    //     const matches = data.axisValue.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
    //     if (matches === null || matches?.[0] === null) {
    //         return;
    //     }
    
    //     let url = `${dciPaths.reporting.buildLink()}?event=Created&reportType=Item+List&on=${matches[3]}-${matches[2]}-${matches[1]}`;
    //     if (workQueueIds.length > 0) {
    //         url += `&filter.workQueueId=${workQueueIds.join()}`
    //     }
    
    //     if (teamIds.length > 0) {
    //         url += `&filter.workQueueId=${teamIds.join()}`
    //     }
    
    //     navigate(url);
    // }
    
    const onMarkClick = (lineItemIdentifier: LineItemIdentifier, metric: MetricOption, grouping: GroupingOption) => {
        if (metric === 'Active' || metric === 'Overdue') {
            enqueueSnackbar('Click-through for Active and Overdue items coming soon.', { variant: 'info' });
            return;
        }
    
        if (!chartData.data || !lineItemIdentifier.dataIndex) {
            return;
        }

        const on = chartData.data.identifiers[lineItemIdentifier.dataIndex]
    
        let url = `${dciPaths.reporting.buildLink()}?event=${metric === 'Added' ? 'Created' : metric}&reportType=Item+List&on=${on}`;
        if (workQueueIds.length > 0) {
            url += `&filter.workQueueId=${workQueueIds.join()}`
        }
    
        if (teamIds.length > 0) {
            url += `&filter.workQueueId=${teamIds.join()}`
        }

        switch(grouping) {
            case 'regulatoryImpactId':
                url += `&filter.regulatoryImpactId=${lineItemIdentifier.seriesId}`;
                break;
            case 'workItemPriority':
                url += `&filter.workItemPriority=${lineItemIdentifier.seriesId}`;
                break;
            case 'workQueueId':
                url += `&filter.workQueueId=${lineItemIdentifier.seriesId}`;
                break;
        }
    
        navigate(url);
    }
    
    return <NewChart
        //onAxisClick={onAxisClick}
        onMarkClick={(_, lineItemIdentifier) => onMarkClick(lineItemIdentifier, metric, grouping)}
        style={{ height:'350px' }}
    />
}

const createParameters = (metric: string, grouping: string, numDays: number, workQueueIds: number[], teamIds: number[]) => {
    const dataset = {} as ChartDataset;

    if (metric === 'Added') {
        dataset.metricType = 'EVENT_COUNT';
        dataset.eventTypes = [ workItemReportingEventType.CREATED ];
    } else if (metric === 'Resolved') {
        dataset.metricType = 'EVENT_COUNT';
        dataset.eventTypes = [
            workItemReportingEventType.OVERRIDE_APPROVED,
            workItemReportingEventType.OVERRIDE_APPROVED_SAME_DATA_ONLY,
            workItemReportingEventType.CORRECTED,
            workItemReportingEventType.CORRECTED_AT_SOURCE
        ];
    } else if (metric === 'Active') {
        dataset.metricType = 'ACTIVE';
    } else {
        dataset.metricType = 'OVERDUE';
    }

    if (grouping !== 'None') {
        dataset.groupingColumn = grouping;
    }

    const now = new Date();
    const end = parse(format(now, 'yyyy-MM-dd'), 'yyyy-MM-dd', now);
    const start = addDays(end, -numDays + 1); // Inclusive of today

    const params = [] as QueryParameter[];
    params.push({
        name: 'datasets',
        type: PARAMETER_TYPE.CHART_DATASET,
        value: dataset
    });

    params.push({
        name: 'fixedFilter.reportingEventDate',
        type: PARAMETER_TYPE.DATE,
        value: { 
            onOrAfter: start,
            onOrBefore: end
        }
    });

    if (Array.isArray(workQueueIds) && workQueueIds.length > 0) {
        params.push({
            name: 'fixedFilter.workQueueId',
            type: PARAMETER_TYPE.NUMBER,
            value: workQueueIds
        });
    }

    if (Array.isArray(teamIds) && teamIds.length > 0) {
        params.push({
            name: 'fixedFilter.teamId',
            type: PARAMETER_TYPE.NUMBER,
            value: teamIds
        });
    }

    return params;
}

const createStoreByGroupingColumn = (metric: string, grouping: string, numDays: number, workQueueIds: number[], teamIds: number[]) => createChartStore({ 
    graphQLQueryName: 'timeSeriesWorkItemReport' 
}, {
    fixedParameters: createParameters(metric, grouping, numDays, workQueueIds, teamIds)
});

interface ConfigurableChartCardProps {
    workQueueIds: number[],
    teamIds: number[],
    disableOverdue: boolean
}

type MetricType = 'ACTIVE' | 'OVERDUE' | 'EVENT_COUNT';

interface ChartDataset {
    metricType: MetricType
    groupingColumn: string | null
    eventTypes: number[]
    
    // If null, this will be determined by the API. If an integer, the API will resolve it based on the palette. Otherwise should be an CSS colour e.g
    // 'red' or '#a64523'
    colour?: string | null

    // If null, this will be determined by the API.
    label?: string | null

    // If null, this will be determined by the API.
    id?: number
}

type GroupingOption = 'None' | 'regulatoryImpactId' | 'workItemPriority' | 'workQueueId';
type MetricOption = 'Active' | 'Overdue' | 'Added' | 'Resolved';

const ConfigurableChartCard = ({ workQueueIds, teamIds, disableOverdue = false }: ConfigurableChartCardProps) => {
    const [ store ] = useState(() => createStoreByGroupingColumn('Active', 'None', 30, workQueueIds, teamIds));

    const [ metric, setMetric ] = useState<MetricOption>('Active');
    const [ grouping, setGrouping ] = useState<GroupingOption>('None');
    const [ numDays, setNumDays ] = useState(30);

    useEffect(() => {
        const state = store.getState();
        state.setFixedParameters(createParameters(metric, grouping, numDays, workQueueIds, teamIds));
    }, [ metric, grouping, numDays, workQueueIds, teamIds, store ])

    const metricOnChange = (value: MetricOption) => {
        setMetric(value);
    }

    const groupingOnChange = (value: GroupingOption) => {
        setGrouping(value);
    }

    const numDaysOnChange = (value: number) => {
        setNumDays(value);
    }

    return (
        <Stack>
            <Stack direction='row' justifyContent='center'>
                <Select
                    value={metric}
                    onChange={e => metricOnChange(e.target.value as MetricOption)} 
                    sx={{
                        width:'175px',
                        marginRight: '5px',
                        '& > .MuiSelect-outlined': { 
                            padding:'2px 10px',
                            fontSize:'14px'
                        }
                    }}>
                    <MenuItem value='Active'>Active</MenuItem>
                    { !disableOverdue ? <MenuItem value='Overdue'>Overdue</MenuItem> : null }
                    <MenuItem value='Added'>Created</MenuItem>
                    <MenuItem value='Resolved'>Resolved</MenuItem>
                </Select>

                <Select
                    value={grouping}
                    onChange={e => groupingOnChange(e.target.value as GroupingOption)}
                    sx={{
                        width:'175px',
                        marginRight: '5px',
                        '& > .MuiSelect-outlined': { 
                            padding:'2px 10px',
                            fontSize:'14px'
                        }
                    }}>
                    <MenuItem value='None'>No Grouping</MenuItem>
                    <MenuItem value='regulatoryImpactId'>Regulatory Impact</MenuItem>
                    <MenuItem value='workItemPriority'>Priority</MenuItem>
                    <MenuItem value='workQueueId'>Work Queue</MenuItem>
                </Select>

                <Select
                    value={numDays}
                    onChange={e => numDaysOnChange(e.target.value as number)}
                    sx={{
                        width:'175px',
                        marginRight: '5px',
                        '& > .MuiSelect-outlined': { 
                            padding:'2px 10px',
                            fontSize:'14px'
                        }
                    }}>
                    <MenuItem value={5}>5 Days</MenuItem>
                    <MenuItem value={10}>10 Days</MenuItem>
                    <MenuItem value={15}>15 Days</MenuItem>
                    <MenuItem value={20}>20 Days</MenuItem>
                    <MenuItem value={25}>25 Days</MenuItem>
                    <MenuItem value={30}>30 Days</MenuItem>
                </Select>
            </Stack>
            {/* <ConfigurableChart metric={metric} grouping={grouping} start={start} end={end} workQueueIds={workQueueIds} teamIds={teamIds} dataProvider={dataProvider} /> */}

            <ChartStoreContext.Provider value={store}>
                <Inner metric={metric} grouping={grouping} teamIds={teamIds} workQueueIds={workQueueIds} />
            </ChartStoreContext.Provider>
        </Stack>
    )
}

export { ConfigurableChartCard }
export type { ChartDataset }