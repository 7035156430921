import React, { useState } from 'react';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import callDciApi from '../../utils/callDciApi';

type DialogAction = 'enable' | 'disable';

type AddRuleGroupDialogProps = {
    userId: number,
    userName: string,
    dialogAction: DialogAction,
    isOpen: boolean,
    close: (refresh: boolean) => void
};

const DisableUserDialog = ({ userId, userName, dialogAction, isOpen, close }: AddRuleGroupDialogProps) => {
    const [ typedUserName, setTypedUserName ] = useState('');
    const { getAccessTokenSilently } = useAuth0();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const onAction = async () => {
        const token = await getAccessTokenSilently();
        callDciApi(`mutation{${dialogAction}User(userId:${userId})}`, token)
        .then(body => {
            if (!body.errors) {
                closeDialog(true);
            }
        })
        .catch(error => {
            console.error(`[DisableUserDialog] ${dialogAction}: ${error}`);
        });
    };

    const closeDialog = (refresh: boolean) => {
        setTypedUserName('');
        close(refresh);
    }

    return (
        <Dialog open={isOpen} fullScreen={fullScreen} maxWidth={'sm'} fullWidth aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{dialogAction === 'disable' ? 'Disable' : 'Enable'} User</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label={`Please type the name of the user '${userName}' to ${dialogAction}`}
                    type="text"
                    fullWidth
                    variant={'outlined'}
                    value={typedUserName}
                    onChange={(event) => setTypedUserName(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeDialog(false)}>Cancel</Button>
                <Button disabled={typedUserName !== userName} onClick={onAction}>{dialogAction === 'disable' ? 'Disable' : 'Enable'}</Button>
            </DialogActions>
        </Dialog>
    );
};

export { DisableUserDialog };