import { Typography} from "@mui/material";
import { FilterControlProps } from "./types";
import { FilterAutocomplete } from "./FilterAutocomplete";
import { FilterUrlValueDeserializer, FilterUrlValueSerializer } from "../Table/UrlRestorer";
import authClient from "../Auth/auth-client";
import { Team, fetchTeams, useTeams } from "../Api/Teams";
import { FILTER_TYPE, FilterToParameterConverter, FilterUrlSerializer, registerFilterUrlSerializer, registerParameterConverter } from "./FilterRepository";
import { PARAMETER_TYPE } from "../../GraphQLShared";

const TeamFilter = ({ editMode, value, setValue }: FilterControlProps) => {
    const { data, isFetching } = useTeams();

    const selectedTeams = value as Team[];

    const getTextValue = (value: Team[]) => value. length === 1 
        ? selectedTeams[0].name
        : `${selectedTeams.length} selected`

    const setAutoCompleteValue = (newValue: Team[]) => {
        setValue(newValue, newValue.length > 0, false);
    }

    if (editMode) {
        return <FilterAutocomplete
            data={data}
            getOptionLabel={o => o.name}
            getTextValue={getTextValue}
            isLoading={isFetching}
            isOptionEqualToValue={(o, v) => o.teamId === v.teamId}
            selectedOptions={selectedTeams}
            setValue={setAutoCompleteValue}
        />
    }

    return (
        <Typography style={{
            padding:'0px 5px',
            fontSize:'14px',
            maxWidth:'150px',
            overflow:'hidden',
            textOverflow:'ellipsis',
            whiteSpace:'nowrap',
            fontStyle:!value || value === '' ? 'italic' : 'inherit'
        }}>
            {selectedTeams.length === 1 
                ? ['=', selectedTeams[0].name].join(' ')
                : `= ${selectedTeams.length} selected`}
        </Typography>
    )
}

const TeamFilterDefaultValue = [] as Team[];

const TeamFilterUrlValueSerializer: FilterUrlValueSerializer = filterNode => {
    if (!filterNode.valueIsValid) {
        return null;
    }

    return (filterNode.value as Team[]).map(t => t.teamId).join();
}

const TeamFilterUrlValueDeserializer: FilterUrlValueDeserializer = async value => {
    const token = await authClient.getTokenSilently();
    const teams = await fetchTeams(token);

    const newValues: Team[] = [];
    value.split(',').forEach(v => {
        const nv = parseInt(v);
        if (!isNaN(nv)) {
            const team = teams.find(t => t.teamId === nv);
            if (team) {
                newValues.push(team);
            }
        }
    });

    return newValues.length > 0 ? newValues : null;
}

const converter: FilterToParameterConverter = (name: string, value: Team[]) => ({
    name: name,
    type: PARAMETER_TYPE.NUMBER,
    value: value.map(t => t.teamId)
});

registerParameterConverter(FILTER_TYPE.TEAM, converter);

const serializer: FilterUrlSerializer = {
    serialize: (f: Team[]) => f.map(t => t.teamId).join(),
    deserialize: async s => {
        const token = await authClient.getTokenSilently();
        const teams = await fetchTeams(token);

        return s
            .split(',')
            .map(v => parseInt(v))
            .filter(v => !isNaN(v))
            .map(v => teams.find(t => t.teamId === v))
            .filter(v => v !== undefined)
            .map(v => ({
                teamId: v!.teamId,
                name: v!.name
            }) as Team)
    }
}

registerFilterUrlSerializer(FILTER_TYPE.TEAM, serializer);

export { TeamFilter, TeamFilterDefaultValue, TeamFilterUrlValueSerializer, TeamFilterUrlValueDeserializer }