import { alpha, Box, Button, CircularProgress, Divider, IconButton, Paper, Stack, TablePagination, ToggleButton, Toolbar, Typography } from "@mui/material"
import { TableStoreContext, useTableState } from './Stores/TableStore';
import { FilterAlt, FilterAltOff, ImportExport, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { TableFilter } from "../Filtering/TableFilter";
import { useContext, useEffect, useState } from "react";
import DciLink from "../DciLink";
import { useSnackbar } from "notistack";

// Maybe have a TChild that determins the child type and make the children a (props: TChild) => JSX.Element
// Otherwise maybe have a child props type that has all the things we **can** pass, leaving the rest to them
// Then otherwise pass back those props: {props => <MyInnerType {...props} {otherpropsnotavailablefromhere} />}
// Finally maybe clone so we can keep the simple <MyInnerType /> not sure if possible it will complain of missing types to be passed from here

// What can this provide specifically to the children that parents cannot:
//
// - disableSelect?: boolean,
// - selectedRowIds?: RowId[],
// - onSelectionChange?: (newValue: RowId[]) => void, 
// - * filter eg. for My Items Only
// - * lots for Export

const BottomPagination = () => {
    const currentPage = useTableState(s => s.currentPage);
    const pageSize = useTableState(s => s.pageSize);
    const totalRows = useTableState(s => s.totalRows);

    const setCurrentPage = useTableState(s => s.setCurrentPage);
    const setPageSize = useTableState(s => s.setPageSize);
    
    const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value));
    }

    // TODO: Re-implement intermined row count for loading/error states etc.
    // if (loading) {
    //     return <Typography>Loading...</Typography>
    // } else if (loadError) {
    //     return <Typography color='red'>Error</Typography>
    // }

    return (
        <TablePagination
            onPageChange={(_, page) => setCurrentPage(page)}
            onRowsPerPageChange={onChangeRowsPerPage}
            component='div'
            rowsPerPage={pageSize}
            page={currentPage}
            count={totalRows}
        />
    )
}

const TopPagination = () => {
    const currentPage = useTableState(s => s.currentPage);
    const pageSize = useTableState(s => s.pageSize);
    const totalRows = useTableState(s => s.totalRows);

    const setCurrentPage = useTableState(s => s.setCurrentPage);

    const text = totalRows ? `${currentPage * pageSize + 1}-${Math.min(totalRows, (currentPage + 1) * pageSize)} of ${totalRows}` : 'No data';

    return <>
        <Typography style={{ minWidth:'105px', marginLeft:10, textAlign:'right' }} variant='body2'>{text}</Typography>
        <div style={{ marginLeft:10 }}>
            <IconButton onClick={() => setCurrentPage(currentPage - 1)} disabled={totalRows === null || currentPage === 0}><KeyboardArrowLeft /></IconButton>
            <IconButton onClick={() => setCurrentPage(currentPage + 1)} disabled={totalRows === null || (currentPage + 1) * pageSize >= totalRows}><KeyboardArrowRight /></IconButton>
        </div>
    </>
};

const GlobalSelectAllBar = () => {
    const pageSize = useTableState(s => s.pageSize);
    const totalRows = useTableState(s => s.totalRows);
    const clearSelection = useTableState(s => s.clearSelection);
    const globalSelectAllState = useTableState(s => s.globalSelectAllState);
    const globalSelectAllClick = useTableState(s => s.globalSelectAllClick);
    
    if (globalSelectAllState !== 'UNAVAILABLE') {
        return (
            <Stack
                direction='row'
                spacing={1}
                justifyContent='center'
                alignItems='center'
                sx={theme => ({
                    backgroundColor: theme.palette.mode === 'light' ? '#f3f3f3' : theme.palette.background.paper,
                    minHeight:50 
                })}
            >
                { globalSelectAllState === 'AVAILABLE' && <><Typography>All {pageSize} items on this page selected.</Typography><DciLink onClick={globalSelectAllClick}>Select all items across all pages</DciLink></> }
                { globalSelectAllState === 'ACTIVE' && <><Typography>All {totalRows} items selected.</Typography><DciLink onClick={clearSelection}>Clear selection</DciLink></> }
                { globalSelectAllState === 'LOADING' && <><CircularProgress /><Typography>Selecting all {totalRows} items...</Typography></> }
                { globalSelectAllState === 'ERROR' && <><Typography>An error occurred while selecting all items.</Typography><DciLink onClick={globalSelectAllClick}>Retry</DciLink></> }
            </Stack>
        )
    }

    return null;
}

interface TableContainerProps {
    children?: React.ReactNode,
    toolbarButtons?: React.ReactNode,
    toolbarChildren?: React.ReactNode,
    title: string,
    paged: boolean,
    enableGlobalSelectAll?: boolean,
    enableFilter?: boolean,
    enableExport?: boolean,
    style?: React.CSSProperties,
}

const UTF8BOM = "\uFEFF";

function TableContainer({ 
    title,
    toolbarButtons,
    toolbarChildren,
    children,
    paged = true,
    enableGlobalSelectAll = false,
    enableFilter = false,
    enableExport = false,
    style
}: TableContainerProps) {
    const { enqueueSnackbar } = useSnackbar();
    const [ exportLoading, setExportLoading ] = useState(false);

    const tableStore = useContext(TableStoreContext);
    const selectedData = useTableState(s => s.selectedData);
    const globalSelectAllState = useTableState(s => s.globalSelectAllState);
    const initialised = useTableState(s => s.initialised);
    const clearFilter = useTableState(s => s.clearFilter);
    const getExportCsv = useTableState(s => s.getExportCsv);
    const [ showFilter, setShowFilter ] = useState(false);

    const toggleShowFilter = () => {
        if (showFilter) {
            setShowFilter(false);
            clearFilter();
        } else {
            setShowFilter(true);
        }
    }

    useEffect(() => {
        if (!initialised) {
            tableStore.getState().updateRowData(false);
        }
    }, [ initialised, tableStore ]);

    const exportClick = async () => {
        try {
            setExportLoading(true);
            const csv = await getExportCsv();
            var blob = new Blob(
                [ UTF8BOM + csv ],
                {
                    type : "text/csv"
                }
            );

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `export.csv`);

            // 3. Append to html page
            document.body.appendChild(link);
            
            // 4. Force download
            link.click();
            
            // 5. Clean up and remove the link
            link.parentNode?.removeChild(link);
        } catch (error) {
            console.error(`[exportClick] ${error}`);
            enqueueSnackbar('An error occurred while preparing the export.', { variant: 'error' });
        } finally {
            setExportLoading(false);
        }
    };

    return (
        <Paper id='TableContainerRoot' style={{ ...style, display:'flex', flexDirection:'column' }}>
            <Toolbar>
                <Typography style={{ flexGrow:1 }} variant='h6'>{title}</Typography>
                { toolbarButtons }
                { enableFilter && 
                <ToggleButton
                    value={showFilter}
                    onClick={toggleShowFilter}
                    style={{ padding:'6px', margin:'0px 4px' }}
                >
                    { showFilter ? <FilterAlt color='secondary' /> : <FilterAltOff /> }
                </ToggleButton> }
                { enableExport &&
                    <Button 
                        sx={theme => ({ 
                            color:theme.palette.text.primary,
                            borderColor:theme.palette.divider,
                            '&:hover': {
                                borderColor: theme.palette.action.disabledBackground,
                                backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity)
                            }
                        })}
                        style={{ margin:'0px 4px' }}
                        variant='outlined'
                        endIcon={exportLoading ? <CircularProgress size={20} /> : <ImportExport />}
                        onClick={exportClick}
                        disabled={exportLoading}
                    >
                        Export
                    </Button>
                }
                { paged && <TopPagination /> }
            </Toolbar>
            { (showFilter || toolbarChildren) &&
            <>
                <Divider />
                <Box style={{ padding:'10px 20px' }}>
                    { showFilter && <TableFilter /> }
                    { toolbarChildren }
                </Box>
            </>
            }
            { paged && enableGlobalSelectAll && <GlobalSelectAllBar /> }
            <Divider />
            <Box style={{ flexGrow:1, overflowY:'auto' }}>
                {children}
            </Box>
            <Stack direction='row' spacing={2}>
                <Box style={{ paddingLeft:'14px', display:'flex', alignItems:'center' }}>
                    { globalSelectAllState === 'LOADING'
                        ? <CircularProgress />
                        : <Typography>{selectedData.length} row{selectedData.length === 1 ? '' : 's'} selected</Typography>
                    }
                </Box>
                <Box style={{ flexGrow:1 }} />
                { paged && <BottomPagination /> }
            </Stack>
        </Paper>
    )
}

export { TableContainer }