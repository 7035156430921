import React from 'react';

type WorkItemCountByPriorityDataSet = {
    rows: WorkItemCountByPriority[],
    fetching: boolean
}

type WorkItemCountByPriority = {
    priority: number,
    label: string,
    count: number
};

const ActiveWorkItemCountByPriorityDataContext = React.createContext<WorkItemCountByPriorityDataSet | null>(null);
export { ActiveWorkItemCountByPriorityDataContext };
export type { WorkItemCountByPriority, WorkItemCountByPriorityDataSet };