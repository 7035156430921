import { CurrentUser } from "../AppGlobalStore";

export default function userHasPermission(currentUser: CurrentUser, permissionId: number, teamId?: number) {
    const permission = currentUser.permissions.find(x => x.permissionId === permissionId);
    if (typeof(permission) === 'undefined') {
        return false;
    }

    if (typeof(teamId) === 'number') {
        return permission.teamIds.indexOf(teamId) !== -1;
    } 
    
    return true;
};