import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';

type DciDialogProps = {
    open: boolean,
    handleClose: () => void,
    title: string,
    content: string
}

const DciDialog = ({ open, handleClose, title, content }: DciDialogProps) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="dci-dialog-title"
            aria-describedby="dci-dialog-description"
        >
            <DialogTitle id="dci-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>OK</Button>
            </DialogActions>
        </Dialog>
    )
}

export { DciDialog }