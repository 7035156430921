import {
    Card,
    CardContent,
    styled
} from '@mui/material'

// const useStyles = makeStyles((theme) => ({
//     content: {
//         flex:1,
//         height:'calc(100vh - 135px)',

//         display:'flex',
//         flexDirection:'column'
//     },
//     card: {
//         flex:1,
//         minWidth:0
//     }
// }));

const Content = styled('div')`
    flex:1;
    height:calc(100vh - 135px);
    display:flex;
    flex-direction:column;

    & .MuiCard-root {
        flex:1;
        min-width:0;
    }
`;

export default function NotFound({ message }: { message: string }) {
    return (
        <Content>
            <Card>
                <CardContent style={{ height:'100%', display:'flex', justifyContent:'center', alignItems:'center' }}>
                    <p>{message}</p>
                </CardContent>
            </Card>
        </Content>
    )
}