const convertToCsv = (queryResult: any, queryName: string, paged: boolean) => {
    let results = queryResult.data[queryName];
    if (paged === true) {
        results = results.nodes;
    }

    const r = results as {[key: string]: any}[];
    return convertToCsvBase(r);
}

const convertToCsvFromEntities = (rows: any[]) => {
    return convertToCsvBase(rows);
}

const convertToCsvBase = (rows: {[key: string]: any}[]) => {
    let csv = '';
    if (rows.length > 0) {
        csv += Object.keys(rows[0]).join();
        csv += '\n';
        csv += rows.map(m => rowResult(m)).join('\n');
    }

    return csv;
}

const rowResult = (row: {[key: string]: any}): string =>
    Object.keys(row).map(m => {
        if (m === null) {
            return 'null';
        }

        switch (typeof row[m]) {
            case 'string':
                return `"${(row[m]).replaceAll('"', '""') as string}"`;
            default:
                return row[m].toString();
        }
    }).join();

export { convertToCsv, convertToCsvFromEntities }