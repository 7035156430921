import React from 'react';
import OrgContainer from '../Organisation/OrgContainer';
import { SearchableRuleList } from './SearchableRuleList';

const Rules = () => {
    return (
        <OrgContainer>
            <SearchableRuleList />
        </OrgContainer>
    )
}

export { Rules };