import { useAuth0 } from "@auth0/auth0-react";
import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, styled } from "@mui/material"
import { useQuery } from "@tanstack/react-query";
import { getDciApiResponse } from "../../utils/callDciApi";
import { Error as ErrorIcon, WarningAmber } from "@mui/icons-material";
import { useState } from "react";

interface TableKvp {
    key: string,
    value: TableDefinition
}

interface TableDefinition {
    missingFromOrganisations: string[]
    presentInOrganisations: string[]
    columns: ColumnKvp[]
}

interface ColumnKvp {
    key: string,
    value: ColumnDefinition[]
}

interface ColumnDefinition {
    orgIdentifiers: string[]
    isMissing: boolean
    dataType: string
    isNullable: "YES" | "NO"
    columnDefault: string | null
}

const fetchSchemas = async (token: string): Promise<TableKvp[]> => {
    const response = await getDciApiResponse('{organisationSchemas{key value{missingFromOrganisations presentInOrganisations columns{key value{orgIdentifiers isMissing dataType isNullable columnDefault}}}}}', token);
    if (response.errors) {
        throw new Error(response.errors[0]);
    } else {
        return response.data.organisationSchemas as TableKvp[];
    }
    // TODO: Error handling
}

const useSchemas = () => {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery({
        queryKey: ["organisation-schemas"],
        queryFn: async () => fetchSchemas(await getAccessTokenSilently())
    })
}

const CoreSchema = () => {
    const { isFetched, data } = useSchemas();

    return (isFetched && data !== undefined) ? <TableList tables={data} /> : <Typography>Fetching Schemas</Typography>
}

const TableList = ({ tables }: { tables: TableKvp[] }) => {
    return <table>
        <tbody>
            { tables.map(t => <SchemaTableRow key={t.key} table={t} />) }
        </tbody>
    </table>
}

const SchemaTableRow = ({ table }: { table: TableKvp }) => {
    const allDefinitionsAreEqual = table.value.missingFromOrganisations.length === 0 &&
        table.value.columns.every(c => c.value.length === 1);

    if (allDefinitionsAreEqual) {
        return <ConsistentTable table={table} />
    }

    if (table.value.missingFromOrganisations.length > 0) {
        return <MissingTableRow table={table} />
    }

    return <MismatchedColumnTable table={table} />
}

const ConsistentTable = ({ table }: { table: TableKvp }) =>
    <tr><td style={{ color: 'green' }}>{table.key}</td></tr>

const MissingTableRow = ({ table }: { table: TableKvp }) => {
    return <tr>
        <td style={{ color: 'orange' }}>
            <Stack direction='row' alignItems='center' spacing={0.5}>
                <WarningAmber />
                <Box component='span'>{table.key}</Box>
                <Stack direction='row' spacing={0.25} style={{ fontSize: '0.8rem', color:'initial' }} component='span'>
                    <Box component='span' style={{ fontWeight: 'bold' }}>present in:</Box>
                    <Box component='span'>{table.value.presentInOrganisations.join(', ')}</Box>
                    <Box component='span' style={{ fontWeight: 'bold' }}>missing from:</Box>
                    <Box component='span'>{table.value.missingFromOrganisations.join(', ')}</Box>
                </Stack>
            </Stack>
        </td>
    </tr>
}

const MismatchedColumnTable = ({ table }: { table: TableKvp }) => {
    return <tr>
        <td>
            <Stack>
                <Stack style={{ color: 'red' }} direction='row' alignItems='center' spacing={0.5}>
                    <ErrorIcon />
                    <Box component='span'>{table.key}</Box>
                </Stack>
                <Box sx={{ marginLeft: '25px' }}>
                    <table>
                        <tbody>
                            {/* { table.value.map(def => <td style={{ border: '1px solid lightgray' }}>{def.organisationIdentifiers.join(', ')}</td>) } */}
                            { table.value.columns.map(c => <tr><td><ColumnBreakdownRow column={c} /></td></tr>) }
                        </tbody>
                    </table>
                </Box>
            </Stack>
        </td>
    </tr>
}

const ColumnBreakdownRow = ({ column }: {column: ColumnKvp}) => {
    const [ expanded, setExpanded ] = useState(true);

    const isConsistent = column.value.length === 1;

    return <Stack style={{ color: isConsistent ? 'green' : 'orange' }}>
        <Typography>{column.key}</Typography>
        { !isConsistent && expanded &&
            <ColumnSchemaTable>
                <TableHead>
                    <TableRow>
                        <th>Organisations</th>
                        <th>Data Type</th>
                        <th>Nullable</th>
                        <th>Default Value</th>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { column.value.map(def =>
                        <TableRow key={def.orgIdentifiers.join('_')}>
                            <td style={{ fontWeight: 'bolder' }}>{def.orgIdentifiers.join(', ')}</td>
                            { def.isMissing ? <td colSpan={3} style={{ color: 'lightgrey' }}>MISSING</td> :
                                <>
                                    <td>{def.dataType}</td>
                                    <td>{def.isNullable}</td>
                                    <td>{def.columnDefault ?? 'NONE'}</td>
                                </>
                            }
                        </TableRow>
                    ) }
                </TableBody>
            </ColumnSchemaTable>
        }
    </Stack>
}

const ColumnSchemaTable = styled(Table)(({ theme }) =>`
    margin-left: 25px;
    font-size: 0.8rem;
    color: ${theme.palette.text.primary};

    & > .MuiTableHead-root {
        text-align: left;
    }
`);

export { CoreSchema }