import {
    Typography
} from '@mui/material';
import ScreenCapture from './Images/NewFiltersJune23.png';

const WNNewFiltersJune23 = () => {
    return (
        <>
            <Typography variant='h5'>New Work Item Filters</Typography>
            <img style={{ display:'block', margin:'20px auto' }} src={ScreenCapture} alt="Shareable URLs" />
            <Typography variant='body1'>Additional filters are now available to apply to the Work Items views: Team and Work Queue.</Typography>
        </>
    )
}

export { WNNewFiltersJune23 }