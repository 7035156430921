import userHasPermission from '../utils/userHasPermission';
import { useStore } from 'zustand';
import { appGlobalStore } from '../AppGlobalStore';

type PermissionedComponentProps = {
    permissionId: number | number[],
    children: JSX.Element
}

export const PermissionedComponent = ({ permissionId, children }: PermissionedComponentProps) => {
    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    if (Array.isArray(permissionId)) {
        return permissionId.some(element => userHasPermission(currentUser, element)) ? children : null;
    } else {
        return (userHasPermission(currentUser, permissionId) ? children : null);
    }
}