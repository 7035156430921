
type LocalKey =
    'User.Settings.ColorMode';

const LocalClient = {
    set: (key: LocalKey, value: any) => localStorage.setItem(key, JSON.stringify(value)),
    get: (key: LocalKey) => { 
        const rawValue = localStorage.getItem(key);
        return rawValue === null ? null : JSON.parse(rawValue);
    }
}

export { LocalClient }