import React, { useEffect, useState } from 'react';
import { SingleItem } from '../SingleItem';
import { useAuth0 } from '@auth0/auth0-react';
import { permissions } from '../../utils/dciConstants';
import { CheckboxField, ControlButton, FormCardContent, NumberField } from '../DciControls';
import { AuditItemsByEntity } from '../AuditItemsByEntity';
import {
    Autocomplete, 
    AutocompleteChangeReason,
    Box,
    Card,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TableCell,
    TextField,
    Typography,
    Button,
    useTheme,
    useMediaQuery,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import {
    AddBox,
    Edit,
    Flag,
    Save, 
    Delete
} from '@mui/icons-material';
import OrgContainer from '../Organisation/OrgContainer';
import callDciApi from '../../utils/callDciApi';
import { SegmentMap } from '../Layout/DciBreadcrumb';
import { appGlobalStore } from '../../AppGlobalStore';
import { useStore } from 'zustand';
import userHasPermission from '../../utils/userHasPermission';
import { PARAMETER_TYPE } from '../../GraphQLShared';
import { TableStoreContext, createTableStore, useTableState } from '../Table/Stores/TableStore';
import { TableContainer } from '../Table/TableContainer';
import { Table } from '../Table/Table';
import { TableColumn } from '../Filtering/types';

const NOT_SET_AT_GROUP_LEVEL = 'Not Set at Rule Group Level';
const NOT_SET_VALUE = 9999;

type AddRuleDialogProps = {
    isOpen: boolean,
    close: (refresh: boolean) => void,
    ruleGroupId: number
}

type RuleSummary = {
    reportTestId: number,
    ruleDescription: string,
    ruleGroupId: number,
    ruleGroup: {
        ruleGroupId: number,
        name: string
    } | null
}

const dialogColumns: TableColumn[] = [
    { displayName:'Rule', selector:'reportTestId' },
    { displayName:'Description', selector:'ruleDescription' },
    { displayName:'Current Group', selector:'NOT USED', sortable:'ruleGroupId', render:item => <TableCell key='ruleGroup'>{item.ruleGroup === null ? <Typography style={{ width:'150px', color:'lightgrey' }}>Not Set</Typography> : item.ruleGroup.name}</TableCell> }
];

const createDialogStore = (ruleGroupId: number) => createTableStore({
    graphQLQueryName: 'allRules',
    graphQLQueryColumns: '{reportTestId,ruleDescription,ruleGroupId,ruleGroup{ruleGroupId,name}}',
    idFromRow: row => row.reportTestId,
    uniqueSortColumn: 'reportTestId'
},
{
    paged: true,
    sortOrder: [{ column:'reportTestId' }],
    fixedParameters: [{ name:'where.ruleGroupId.neq', type:PARAMETER_TYPE.RAW_NUMBER, value: ruleGroupId }]
}, 'allRules');

const AddRuleDialog = ({ isOpen, close, ruleGroupId }: AddRuleDialogProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [ refreshRulesTableOnClose, setRefreshRulesTableOnClose ] = useState(false);

    const updateData = useTableState(s => s.updateRowData);
    const selectedData = useTableState(s => s.selectedData);

    useEffect(() => {
        if (isOpen) {
            updateData(false);
        }
    }, [ isOpen ])

    const addRules = async () => {
        const token = await getAccessTokenSilently();
        callDciApi(`mutation{addRulesToGroup(ruleGroupId:${ruleGroupId},ruleIds:[${selectedData.map((r: RuleSummary) => r.reportTestId).join()}])}`, token)
        .then(body => {
            if (body.errors) {
                alert(body.errors[0].message);
            } else {
                setRefreshRulesTableOnClose(true);
            }
        });
    };

    return (
        <Dialog open={isOpen} fullScreen={fullScreen} maxWidth={'md'} fullWidth aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Rules</DialogTitle>
            <DialogContent>
                <Table
                    uniqueSortColumn='reportTestId'
                    columns={dialogColumns}
                    idFromValue={(row: RuleSummary) => row.reportTestId}
                    size='small' // TODO: Not working
                    selectable
                />
            </DialogContent>
            <DialogActions>
                <Button disabled={selectedData.length === 0} onClick={addRules}>Add</Button>
                <Button onClick={() => close(refreshRulesTableOnClose)}>Done</Button>
            </DialogActions>
        </Dialog>
    );
}

type RulesListControlsProps = {
    ruleGroupId: number
}

const RulesListControls = ({ ruleGroupId }: RulesListControlsProps) => {
    const [ store ] = useState(() => createDialogStore(ruleGroupId))

    const { getAccessTokenSilently } = useAuth0();
    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const [ addRuleDialogIsOpen, setAddRuleDialogIsOpen ] = useState(false);
    const hasEditPermission = userHasPermission(currentUser, permissions.EDIT_RULE);

    const selectedData = useTableState(s => s.selectedData) as RuleGroup[];
    const updateData = useTableState(s => s.updateRowData);
    
    const closeDialog = (refresh: boolean) => {
        setAddRuleDialogIsOpen(false);
        if (refresh === true) {
            updateData(false);
        }
    }

    const removeFromGroup = async () => {
        const token = await getAccessTokenSilently();
        callDciApi(`mutation{removeRulesFromGroup(ruleGroupId:${ruleGroupId},ruleIds:[${selectedData.map(m => m.reportTestId).join()}])}`, token)
        .then(body => {
            if (body.errors) {
                alert(body.errors[0].message);
            } else {
                updateData(false);
            }
        });
    };

    return (
        hasEditPermission 
        ?   <TableStoreContext.Provider value={store}>
                <Button onClick={removeFromGroup} disabled={selectedData.length === 0} startIcon={<Delete />}>Remove</Button>
                <AddRuleDialog isOpen={addRuleDialogIsOpen} close={closeDialog} ruleGroupId={ruleGroupId} />
                <IconButton onClick={() => setAddRuleDialogIsOpen(true)} aria-label='add rule' component='span'><AddBox /></IconButton>
            </TableStoreContext.Provider>
        : null
    );
};

type RuleGroupRule = {
    reportTestId: number,
    ruleDescription: string
}

type RulesListProps = {
    id: number
}

const columns: TableColumn[] = [
    { displayName: 'Rule ID', selector:'reportTestId' },
    { displayName: 'Description', selector:'ruleDescription' }
];

const createStore = (id: number) => createTableStore({
        graphQLQueryName: 'rulesByRuleGroupId',
        graphQLQueryColumns: '{reportTestId,ruleDescription}',
        idFromRow: row => row.reportTestId,
        uniqueSortColumn: 'reportTestId'
    },
    {
        paged: true,
        sortOrder: [{ column:'reportTestId' }],
        fixedParameters: [{ name: 'ruleGroupId', type: PARAMETER_TYPE.RAW_NUMBER, value: id }]
    }, 'rulesInRuleGroup');
    
const RulesList = ({ id }: RulesListProps) => {
    const [ store ] = useState(() => createStore(id))

    return (
        <TableStoreContext.Provider value={store}>
            <TableContainer
                title='Rules in Group'
                paged
                style={{ minHeight:'400px', maxHeight:'400px', width:'100%' }}
                enableExport
                toolbarButtons={<RulesListControls ruleGroupId={id} />}
            >
                <Table
                    uniqueSortColumn='reportTestId'
                    columns={columns}
                    idFromValue={row => row.reportTestId}
                    size='small' // TODO: Not working
                    selectable
                />
            </TableContainer>
        </TableStoreContext.Provider>
    )
};

type RenderItemProps = {
    item: RuleGroup,
    refresh: () => void,
    setBreadcrumbs: React.Dispatch<React.SetStateAction<SegmentMap[]>>
}

const RenderItem = ({ item, refresh, setBreadcrumbs }: RenderItemProps) => {
    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const [ editMode, setEditMode ] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    
    const [ defaultWorkQueueIsOpen, setDefaultWorkQueueIsOpen ] = useState(false);
    const [ workQueuesLoading, setWorkQueuesLoading ] = useState(true);
    const [ workQueues, setWorkQueues ] = useState([ { workQueueId:0, name:NOT_SET_AT_GROUP_LEVEL } ]);
    
    const [ regulatoryImpactIsOpen, setRegulatoryImpactIsOpen ] = useState(false)
    const [ regulatoryImpactsLoading, setRegulatoryImpactsLoading ] = useState(true);
    const [ regulatoryImpacts, setRegulatoryImpacts ] = useState([ { regulatoryImpactId:NOT_SET_VALUE, description:NOT_SET_AT_GROUP_LEVEL, displayIcon:'white' } ]);

    const [ amendedItem, setAmendedItem ] = useState<{ [key: string]: any }>({});

    const getValue = <T,>(propertyName: string, fallbackValue?: T) => {
        let value = amendedItem[propertyName];
        if (typeof(value) === 'undefined') {
            value = item[propertyName];
        }

        return value === null && typeof(fallbackValue) !== 'undefined' ? fallbackValue : value;
    };

    const hasEditPermission = userHasPermission(currentUser, permissions.EDIT_RULE);
    useEffect(() => {
        let active = true;
    
        if (!workQueuesLoading) {
          return undefined;
        }
    
        (async () => {
            const token = await getAccessTokenSilently();
            callDciApi('{allWorkQueues{workQueueId,name}}', token)
            .then(body => {
                if (active && !body.errors) {
                    setWorkQueues(w => [ ...w, ...body.data.allWorkQueues ]);
                    setWorkQueuesLoading(false);
                }
            });
        })();
    
        return () => {
          active = false;
        };
    }, [ workQueuesLoading ]);

    useEffect(() => {
        let active = true;
    
        if (!regulatoryImpactsLoading) {
          return undefined;
        }
    
        (async () => {
            const token = await getAccessTokenSilently();
            callDciApi('{allRegulatoryImpacts{regulatoryImpactId,description,displayIcon,regulatoryImpactValue}}', token)
            .then(body => {
                if (active && !body.errors) {
                    setRegulatoryImpacts(w => [ ...w, ...body.data.allRegulatoryImpacts ]);
                    setRegulatoryImpactsLoading(false);
                }
            });
        })();
    
        return () => {
          active = false;
        };
    }, [ regulatoryImpactsLoading ]);

    // TODO: Fix the setBreadCrumbs causing infinite re-loading. Hopefully find a proper way of doing breadcrumbs also
    // useEffect(() => {
    //     setBreadcrumbs(oldValue => [ ...oldValue ].map((m, i) => i === oldValue.length - 1 ? { text: item.name } : m));
    // }, []);

    const changesMade = () => {
        return Object.keys(amendedItem).some(element => item[element] !== amendedItem[element]);
    };

    const serializePropertyValue = (prop: any) => {
        if (prop === null) {
            return 'null';
        } else if (typeof(prop) === 'string') {
            return `"${prop}"`;
        } else {
            return prop.toString();
        }
    };

    const saveChanges = async () => {
        const entityToSave = {
            ...item,
            ...amendedItem,
            defaultWorkQueueId: typeof(amendedItem.defaultWorkQueue) === 'undefined'
                ? (item.defaultWorkQueue && item.defaultWorkQueue.workQueueId)
                : (amendedItem.defaultWorkQueue && amendedItem.defaultWorkQueue.workQueueId),
            regulatoryImpactId: typeof(amendedItem.regulatoryImpact) === 'undefined'
                ? (item.regulatoryImpact && item.regulatoryImpact.regulatoryImpactId)
                : (amendedItem.regulatoryImpact && amendedItem.regulatoryImpact.regulatoryImpactId)
        } as any;

        delete entityToSave.defaultWorkQueue;
        delete entityToSave.regulatoryImpact;

        const token = await getAccessTokenSilently();
        callDciApi(`mutation{editRuleGroup(ruleGroup:{${Object.keys(entityToSave).map(x => `${x}:${serializePropertyValue(entityToSave[x])}`).join()}}){ruleGroupId}}`, token)
        .then(body => {
            if (body.errors) {
                alert (body.errors[0].message);
            } else {
                setEditMode(false);
                setAmendedItem({});
                refresh();
            }
        })
        .catch(error => {
            console.error(`[RuleGroup] editRuleGroup: ${error}`);
        });
    };

    const cancelChanges = () => {
        setEditMode(false);
        setAmendedItem({});
    };

    return (
        <>
            <Card style={{ marginBottom:10 }}>
                <FormCardContent>
                    <Box style={{ float:'right' }}>
                        {hasEditPermission && !editMode && <Button startIcon={<Edit />} onClick={() => setEditMode(true)}>Edit</Button>}
                    </Box>
                    { editMode
                        ? <TextField variant='standard' label='Name' value={getValue('name', '')} onChange={e => setAmendedItem({ ...amendedItem, name:e.target.value })} />
                        : <Typography variant='h5' paragraph>Rule Group: {item.name}</Typography>
                    }
                    { editMode
                        ? <TextField variant='standard' style={{ marginTop:'10px' }} multiline maxRows={5} label='Description' value={getValue('description', '')} onChange={e => setAmendedItem({ ...amendedItem, description:e.target.value === '' ? null : e.target.value })} />
                        : <Typography paragraph>{item.description}</Typography>
                    }
                    <CheckboxField
                        disabled={!editMode}
                        displayName='Enabled' 
                        id='enabled'
                        value={getValue('enabled', false)} 
                        onChange={e => setAmendedItem({ ...amendedItem, enabled:e.target.checked})}
                    />
                    <CheckboxField
                        disabled={!editMode}
                        displayName='Override Requests Allowed' 
                        id='can-request-override'
                        value={getValue('canRequestAcceptance', false)} 
                        onChange={e => setAmendedItem({ ...amendedItem, canRequestAcceptance:e.target.checked})}
                    />
                    <CheckboxField
                        disabled={!editMode}
                        displayName='Overrides Valid for Same Values Only' 
                        id='override-same-values-only'
                        value={getValue('acceptanceSameValueOnly', false)} 
                        onChange={e => setAmendedItem({ ...amendedItem, acceptanceSameValueOnly:e.target.checked})}
                    />
                    <Autocomplete
                        disabled={!editMode}
                        id="regulatory-impact"
                        open={regulatoryImpactIsOpen}
                        onChange={(obj: React.ChangeEvent<{}>, value: RegulatoryImpact | null, reason: AutocompleteChangeReason) => setAmendedItem({ ...amendedItem, regulatoryImpact:value === null || value.regulatoryImpactId === NOT_SET_VALUE ? null : value })}
                        onOpen={() => setRegulatoryImpactIsOpen(true)}
                        onClose={() => setRegulatoryImpactIsOpen(false)}
                        isOptionEqualToValue={(option, value) => option.regulatoryImpactId === value.regulatoryImpactId}
                        getOptionLabel={option => option.description}
                        renderOption={ (props, option) =>
                            <li {...props}>
                                <Flag style= {{ 
                                    color: option.displayIcon,
                                    marginBottom: -2,
                                    marginRight: 5
                                }} />
                                {option.description}
                            </li>
                        }
                        value={regulatoryImpactsLoading ? regulatoryImpacts[0] : getValue('regulatoryImpact', regulatoryImpacts[0])}
                        options={regulatoryImpacts}
                        loading={regulatoryImpactsLoading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant='standard'
                                label="Regulatory Impact"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <>
                                        {regulatoryImpactsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                    ),
                                }}
                            />
                        )}
                    />
                    <Autocomplete
                        disabled={!editMode}
                        id="default-work-queue"
                        open={defaultWorkQueueIsOpen}
                        onChange={(obj: React.ChangeEvent<{}>, value: WorkQueue | null, reason: AutocompleteChangeReason) => setAmendedItem({ ...amendedItem, defaultWorkQueue:value === null || value.workQueueId === 0 ? null : value })}
                        onOpen={() => setDefaultWorkQueueIsOpen(true)}
                        onClose={() => setDefaultWorkQueueIsOpen(false)}
                        isOptionEqualToValue={(option, value) => option.workQueueId === value.workQueueId}
                        getOptionLabel={option => option.name}
                        value={ workQueuesLoading ? workQueues[0] : getValue('defaultWorkQueue', workQueues[0])}
                        options={workQueues}
                        loading={workQueuesLoading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant='standard'
                                label="Default Work Queue"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <>
                                        {workQueuesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                    ),
                                }}
                            />
                        )}
                    />
                    <FormControl disabled={!editMode} variant='standard'>
                        <InputLabel id="rule-priority">Priority</InputLabel>
                        <Select
                            
                            labelId="rule-priority"
                            id="rule-priority-select"
                            value={getValue('rulePriority', 0)}
                            onChange={e => setAmendedItem({ ...amendedItem, rulePriority:e.target.value === 0 ? null : e.target.value })}
                        >
                            <MenuItem value={0}>{NOT_SET_AT_GROUP_LEVEL}</MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                        </Select>
                    </FormControl>
                    <NumberField
                        disabled={!editMode}
                        displayName='Target Turnaround Time' 
                        id='target-turnaround-time'
                        value={getValue<number | null>('targetTurnaroundTime')} 
                        onChange={value => setAmendedItem({ ...amendedItem, targetTurnaroundTime:value })}
                        notSetLabel={NOT_SET_AT_GROUP_LEVEL}
                    />
                    { editMode &&
                        <>
                            <ControlButton onClick={saveChanges} disabled={!changesMade()} startIcon={<Save />} variant='contained'>Save</ControlButton>
                            <ControlButton onClick={cancelChanges} variant='contained'>Cancel</ControlButton>
                        </>
                    }
                </FormCardContent>
            </Card>
        </>
    );
};

type RegulatoryImpact = {
    displayIcon: string,
    description: string,
    regulatoryImpactId: number
}

type WorkQueue = {
    workQueueId: number,
    name: string
}

type RuleGroup = {
    name: string,
    description: string,
    acceptanceSameValueOnly: boolean | null,
    canRequestAcceptance: boolean | null,
    regulatoryImpact: RegulatoryImpact | null,
    defaultWorkQueue: WorkQueue | null,
    enabled: boolean,
    informationRequiredNotificationDelay: number | null,
    ruleGroupId: number,
    rulePriority: number,
    sendAlert: boolean | null,
    targetTurnaroundTime: number | null,
    [key: string]: any
}

type RuleViewProps = {
    id: number,
    setBreadcrumbs: React.Dispatch<React.SetStateAction<SegmentMap[]>>
}

const RuleGroupView = ({ id, setBreadcrumbs }: RuleViewProps) => {
    return (
        <OrgContainer>
            <SingleItem<RuleGroup>
                queryName={'ruleGroupById'}
                queryParameters={`id:${id}`}
                queryColumns='{name,description,acceptanceSameValueOnly,canRequestAcceptance,regulatoryImpact{regulatoryImpactId,displayIcon,description},defaultWorkQueue{workQueueId,name},enabled,informationRequiredNotificationDelay,ruleGroupId,rulePriority,sendAlert,targetTurnaroundTime}'
                ItemComponent={({ item, refresh }) => <RenderItem item={item} refresh={refresh} setBreadcrumbs={setBreadcrumbs} />}
            />
            <RulesList id={id} />
            <AuditItemsByEntity style={{ marginTop:10 }} type='RuleGroup' entityKey={[{ fieldName:'RuleGroupId', fieldValue:id }]} />
        </OrgContainer>
    )
}

export { RuleGroupView }