import { postDciApi } from "../../utils/callDciApi";
import authClient from "../Auth/auth-client";
import { ApiClient } from "./ApiClient";

interface User {
    userId: number,
    firstName: string,
    emailAddress: string,
    enabled: boolean,
    surname: string | null,
    workTelephone: string | null,
    workExtension: string | null,
    mobileTelephone: string | null,
    managerId: number | null,
    branch: string | null,
    location: string | null,
    jobTitle: string | null
}

const UsersClient: ApiClient<User> = {

    get: async (predicate?: (u: User) => boolean) => {
        const token = await authClient.getTokenSilently();
        const apiResponse = await postDciApi('{allUsers{nodes{userId,firstName,emailAddress,enabled,surname,workTelephone,workExtension,mobileTelephone,managerId,branch,location,jobTitle}}}', token);

        let result = apiResponse.data.allUsers.nodes as User[];
        if (predicate) {
            result = result.filter(predicate);
        }

        return result;
    }

}

export { UsersClient }
export type { User }