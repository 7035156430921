import { useEffect, useState } from 'react';
import { parseISO } from 'date-fns';
import { useAuth0 } from '@auth0/auth0-react';
import callDciApi from '../utils/callDciApi';
import { noteType } from '../utils/dciConstants';
import {
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    TextField
} from '@mui/material';
import { styled } from '@mui/material/styles'

import { Typography, Box } from '@mui/material';
import { 
    AddBox,
    Note 
} from '@mui/icons-material';

const NoteCard = styled(Card)(({ theme }) => `
    background-color: ${theme.palette.action.disabledBackground};
    margin-bottom: 10px;
`);

const RootCard = styled(Card)(({ theme }) => `
    margin-bottom: 10;
    & .MuiButton {
        margin-left: ${theme.spacing(0.5)}
    }
  `
);

type ApiNote = {
    workItemNoteId: number,
    createdByName: string,
    creationDatetime: string,
    note: string,
    noteType: {
        noteTypeId: number,
        description: string
    }
}

type Note = {
    workItemNoteId: number,
    note: string,
    notetypeId: number,
    noteTypeDescription: string,
    creationDatetime: Date,
    createdByName: string
}

type NotesDisplayProps = {
    workItemId: number,
    initialNotes: ApiNote[]
}

const NotesDisplay = ({ workItemId, initialNotes }: NotesDisplayProps) => {
    const [ newNoteContent, setNewNoteContent ] = useState<string | null>(null);
    // TODO: Remove once we are happy no type of cloning is needed
    //const [ notes, setNotes ] = useState(cloneDeep(initialNotes).sort((a, b) => a.creationDatetime < b.creationDatetime ? 1 : -1));
    const [ notes, setNotes ] = useState<Note[]>([]);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => setNotes(initialNotes.map(m => convertApiNote(m))), [ initialNotes ]);

    const onAddNote = () => {
        const addNote = async () => {
            const token = await getAccessTokenSilently();
            callDciApi(`mutation{addNote(workItemId:${workItemId},noteContent:"""${newNoteContent}"""){note,workItemNoteId,createdByName,noteType{noteTypeId,description}creationDatetime}}`, token)
            .then(body => {
                if (!body.errors) {
                    setNotes(previousNotes => [ 
                        convertApiNote(body.data.addNote),
                        ...previousNotes 
                    ]);
                    setNewNoteContent(null);
                }
            });
        };

        addNote();
    };

    const convertApiNote = (apiNote: ApiNote): Note => ({
        workItemNoteId: apiNote.workItemNoteId,
        createdByName: apiNote.createdByName,
        creationDatetime: parseISO(apiNote.creationDatetime),
        note: apiNote.note,
        notetypeId: apiNote.noteType.noteTypeId,
        noteTypeDescription: apiNote.noteType.description
    });

    return (
        <RootCard>
            <CardContent>
                <Grid container>
                    <Grid item style={{ flexGrow: 1 }}>
                        <Typography variant='h5' gutterBottom>Notes</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => setNewNoteContent('')} disabled={newNoteContent !== null} aria-label='add note' component='span'>
                            <AddBox />
                        </IconButton>
                    </Grid>
                </Grid>
                { newNoteContent === null ? null :
                    <Box>
                        <TextField
                            inputRef={input => input && input.focus()}
                            style={{ width: '100%', marginBottom: '5px' }}
                            id='outlined-multiline-static'
                            label='Type your note here'
                            multiline
                            rows={4}
                            value={newNoteContent}
                            onChange={(event) => setNewNoteContent(event.target.value)}
                            variant='outlined'
                            onKeyUp={(event) => { if (event.key === 'Escape') { setNewNoteContent(null); }}}
                            />
                        <Box style={{ textAlign: 'right', marginBottom: '10px' }}>
                            <Button onClick={() => setNewNoteContent(null)} size='small' variant='contained'>Cancel</Button>
                            <Button onClick={onAddNote} size='small' variant='contained'>Add</Button>
                        </Box>
                    </Box>
                }
                {/* Line breaks -  */}
                { notes.map(n => 
                    <NoteCard key={n.workItemNoteId}>
                        <CardContent>{n.note.split('\n').map((o, i) => o === '' ? <br key={i} /> : <p key={i} style={{ margin:0 }}>{o}</p>)}</CardContent>
                        <Box>
                            <Typography style={{ fontWeight:'bold', fontStyle:'italic', fontSize:'0.75rem', textAlign: 'right', marginBottom:5, marginRight:5 }}>{n.notetypeId !== noteType.GENERAL && `${n.noteTypeDescription} | `}{n.createdByName} @ {n.creationDatetime.toLocaleString()}</Typography>
                        </Box>
                    </NoteCard>)
                }
            </CardContent>
        </RootCard>
    );
}

export { NotesDisplay }
export type { ApiNote }