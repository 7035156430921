import { parseISO } from "date-fns";
import { postDciApi } from "../../utils/callDciApi";
import authClient from "../Auth/auth-client";
import { ColorMode } from "../Layout/DciTheme";
import { LegacyDashboardSettings } from "../../AppGlobalStore";

type CurrentUserQueryResult = {
    firstName: string,
    surname: string,
    appUserId: number,
    organisationName: string,
    userSettings?: string | null,
    permissions: UserPermission[]
};

type UserPermission = {
    permissionId: number,
    teamIds: number[]
};

interface UserSettings {
    lastWhatsNewSeen: Date,
    colorMode: ColorMode,
    legacyDashboard: LegacyDashboardSettings
};

const defaultUserSettings = {
    lastWhatsNewSeen: parseISO('1970-01-01'),
    colorMode: 'light'
};

const UserClient = {
    getCurrentUser: async () => {
        const token = await authClient.getTokenSilently();
        const apiResponse = await postDciApi('{currentUser{firstName,surname,appUserId,organisationName,userSettings,permissions{permissionId,teamIds}}}', token);

        const result = apiResponse.data.currentUser as CurrentUserQueryResult;

        const parsedUserSettings = JSON.parse(result.userSettings ?? '{}');
        const parsedLegacyDashboardSettings = typeof parsedUserSettings.legacyDashboard === 'string'
            ? JSON.parse(parsedUserSettings.legacyDashboard)
            : {
                filterType: 'none',
                workQueueIds: [],
                teamIds: []
              }

        const mapped = {
            firstName: result.firstName,
            surname: result.surname,
            appUserId: result.appUserId,
            organisationName: result.organisationName,
            userSettings: {
                lastWhatsNewSeen: parseISO(parsedUserSettings.lastWhatsNewSeen ?? '1970-01-01'),
                colorMode: typeof parsedUserSettings.colorMode === 'string' && [ 'light', 'dark' ].includes(parsedUserSettings.colorMode.toLowerCase()) ? parsedUserSettings.colorMode.toLowerCase() : 'light',
                legacyDashboard: {
                    filterType: parsedLegacyDashboardSettings.filterType ?? 'none',
                    workQueueIds: parsedLegacyDashboardSettings.workQueueIds ?? [],
                    teamIds: parsedLegacyDashboardSettings.teamIds ?? [],
                }
            },
            permissions: result.permissions
        };

        return mapped;
    },

    saveCurrentUserSettings: async (settings: UserSettings) => {
        const token = await authClient.getTokenSilently();

        const query = `
            mutation {
                saveUserSettings(
                    settings:{
                        lastWhatsNewSeen:"${settings.lastWhatsNewSeen.toISOString()}",
                        colorMode:${settings.colorMode.toUpperCase()},
                        legacyDashboard:"${JSON.stringify(settings.legacyDashboard).replaceAll('"', '\\"')}"
                    }
                ) {
                    result
                }
            }
        `.replaceAll(' ', '').replaceAll('\n', '');

        postDciApi(query, token);
    }
};

export { UserClient }