import {
    Typography
} from '@mui/material';

const WNOctober2024 = () => {
    return (
        <>
            <Typography variant='h5'>October Update</Typography>
            <Typography variant='body1'>A number of improvements have been applied across the system:</Typography>
            <ul>
                <li>Chart grouping colours have been pinned to their values, allowing for consistency when running reports at different times</li>
                <li>Click-through now available in more charts, with more to follow</li>
                <li>Setup summary shown when configuration panels collapsed in reporting screen</li>
                <li>Performance improvements to all server data requests</li>
                <li>Minor fixes applied throughout</li>
            </ul>
        </>
    )
}

export { WNOctober2024 }