import {
    Typography
} from '@mui/material';
import ScreenCapture from './Images/CustomChartColours.png';

const WNCustomChartColours = () => {
    return (
        <>
            <Typography variant='h5'>Custom Chart Colours</Typography>
            <Typography variant='body1'>You now have the ability to choose custom chart colours for Work Queues and Users.</Typography>
            <img style={{ display:'block', margin:'20px auto' }} src={ScreenCapture} alt="Custom chart colours screen capture" />
            <Typography variant='body1'>This is an opt-in feature, and can be activated on a per-record basis. Any Work Queue or User that does not have a custom colour will use the existing system default colours.</Typography>
        </>
    )
}

export { WNCustomChartColours }