import { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import {
    Alert,
    AlertTitle
} from '@mui/lab';
import { useSnackbar } from 'notistack';
import callDciApi from '../../utils/callDciApi';
import { useAuth0 } from '@auth0/auth0-react';

type ActionResponse = {
    result: 'SUCCESS' | 'FAILURE' | 'WARNINGS',
    messages: string[]
};

type AddUserDialogProps = {
    isOpen: boolean,
    close: (refresh: boolean) => void
};

const AddUserDialog = ({ isOpen, close }: AddUserDialogProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const { enqueueSnackbar } = useSnackbar();

    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ repeatPassword, setRepeatPassword ] = useState('');
    const [ showError, setShowError ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState('');

    const passwordTest = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/m;
    const passwordHelperText = 'Password must be at least 8 characters long and contain 1 each of lower case letter, upper case letter, number and special character: !@#$%^&*';
    const passwordIsStrong = passwordTest.test(password);

    const addUser = async () => {
        setShowError(false);
        const token = await getAccessTokenSilently();
        callDciApi(`mutation{createUser(email:"${email}",firstName:"${firstName}",lastName:"${lastName}",password:"${password}"){result,messages}}`, token)
        .then(body => {
            if (!body.errors) {
                const response = body.data.createUser as ActionResponse;
                if (response.result === 'SUCCESS') {
                    enqueueSnackbar(`User '${firstName} ${lastName}' created`, { variant: 'success' });
                    close(true);
                    return;
                }

                setErrorMessage(response.messages[0]);
                setShowError(true);
            }
        });
    };

    const disableAddButton = 
        firstName === '' ||
        lastName === '' ||
        email === '' ||
        password === '' ||
        repeatPassword === '' ||
        repeatPassword !== password ||
        !passwordIsStrong;
    
    return (
        <Dialog open={isOpen} maxWidth={'sm'} fullWidth aria-labelledby='form-dialog-title'>
            <DialogTitle id='form-dialog-title'>Add User</DialogTitle>
            { showError &&
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {errorMessage}
                </Alert>
            }
            <DialogContent>
                <TextField
                    autoFocus
                    margin='dense'
                    id='first-name'
                    label='First Name'
                    type='text'
                    fullWidth
                    variant='standard'
                    value={firstName}
                    onChange={event => setFirstName(event.target.value)}
                />

                <TextField
                    margin='dense'
                    id='last-name'
                    label='Last Name'
                    type='text'
                    fullWidth
                    variant='standard'
                    value={lastName}
                    onChange={event => setLastName(event.target.value)}
                />

                <TextField
                    margin='dense'
                    id='email'
                    label='E-mail Address'
                    type='email'
                    fullWidth
                    variant='standard'
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                />

                <TextField
                    margin='dense'
                    id='password'
                    label='Password'
                    type='password'
                    fullWidth
                    variant='standard'
                    value={password}
                    error={password !== '' && !passwordIsStrong}
                    helperText={password !== '' && !passwordIsStrong ? passwordHelperText : undefined}
                    onChange={event => setPassword(event.target.value)}
                />

                <TextField
                    margin='dense'
                    id='repeat-password'
                    label='Repeat Password'
                    type='password'
                    fullWidth
                    variant='standard'
                    value={repeatPassword}
                    error={password !== repeatPassword}
                    helperText={password !== repeatPassword ? 'Passwords do not match' : undefined}
                    onChange={event => setRepeatPassword(event.target.value)}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={() => close(false)}>Cancel</Button>
                <Button disabled={disableAddButton} onClick={addUser}>Add</Button>
            </DialogActions>
        </Dialog>
    );
};

export { AddUserDialog };