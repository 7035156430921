import React from 'react';
import { getDisplayName } from '../../utils/getDisplayName';
import {
    Breadcrumbs,
    Typography
} from '@mui/material';
import dciPaths from '../../utils/dciPaths';
import DciLink from '../DciLink';

type SegmentMap = {
    match?: string,
    text: string,
    linkTo?: string,
    viewState?: any
};

export type { SegmentMap };

const segmentMapping: SegmentMap[] = [
    {
        match:'organisation',
        text:'Organisation',
        linkTo:dciPaths.organisation.buildLink()
    },
    {
        match:'configuration',
        text:'Configuration',
        linkTo:dciPaths.configuration.buildLink()
    },
    {
        match:'reporting',
        text:'Reporting',
        linkTo:'/reporting'
    },
    {
        match:'teams',
        text:'Teams',
        linkTo:dciPaths.teams.buildLink()
    },
    {
        match:'team',
        text:'Teams',
        linkTo:dciPaths.teams.buildLink()
    },
    {
        match:'users',
        text:'Users',
        linkTo:dciPaths.users.buildLink()
    },
    {
        match:'user',
        text:'Users',
        linkTo:dciPaths.users.buildLink()
    },
    {
        match:'role',
        text:'Roles',
        linkTo:dciPaths.roles.buildLink()
    },
    {
        match:'rule',
        text:'Rules',
        linkTo:dciPaths.rules.buildLink()
    },
    {
        match:'inprogress',
        text:'In Progress',
        linkTo:'/workitems/inprogress'
    },
    {
        match:'overriderequest',
        text:'Override Request',
        linkTo:'/workitems/overriderequest'
    },
    {
        match:'rulegroups',
        text:'Rule Groups',
        linkTo:dciPaths.ruleGroups.buildLink()
    },
    {
        match:'rulegroup',
        text:'Rule Groups',
        linkTo:dciPaths.ruleGroups.buildLink()
    },
    {
        match:'corrected',
        text:'Corrected',
        linkTo:'/workitems/corrected'
    },
    {
        match:'override',
        text:'Override',
        linkTo:'/workitems/override'
    },
    {
        match:'waitinginfo',
        text:'Awaiting Information'
    },
    {
        match:'queued',
        text:'Queued'
    },
    {
        match:'referred',
        text:'Referred'
    },
    {
        match:'distribution',
        text:'Distribution'
    },
    {
        match:'acceptancerequest',
        text:'Acceptance Request'
    },
    {
        match:'workqueues',
        text:'Work Queues'
    },
    {
        match:'workqueue',
        text:'Work Queues',
        linkTo: dciPaths.workQueues.buildLink()
    },
    {
        match:'workitems',
        text:'Work Items'
    },
    {
        match:'orgconfig',
        text:'Settings'
    },
    {
        match:'orgconstants',
        text:'Constants'
    },
    {
        match:'orgvaluelists',
        text:'Value Lists'
    },
    {
        match:'useractivity',
        text:'User Activity'
    }
];

const getBreadcrumbMapping = (matchOn: string) => {
    return segmentMapping.find(x => x.match === matchOn);
};

const getBreadcrumbs = (path: string): SegmentMap[] => {
    let pathSegments = path.split('/').filter((x) => x && x !== '/dashboard');

    return pathSegments.map(m => {
        const mapping = getBreadcrumbMapping(m);
        if (typeof(mapping) === 'undefined') {
            return { text: getDisplayName(m) } as SegmentMap;
        } else {
            return mapping;
        }
    });
};

type BreadcrumbLinkProps = {
    breadcrumb: SegmentMap
};

const BreadcrumbLink: React.FC<BreadcrumbLinkProps> = ({ breadcrumb }) => 
    <DciLink href={breadcrumb.linkTo}>{breadcrumb.text}</DciLink>;

type DciBreadcrumbProps = {
    breadcrumbs: SegmentMap[]
}

const DciBreadcrumb: React.FC<DciBreadcrumbProps> = ({ breadcrumbs }) => {
    return (
        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize:'0.9rem', marginTop:-10, marginBottom:8 }}>
            <BreadcrumbLink breadcrumb={{ text:'Home', linkTo:'/' }} />
            { breadcrumbs.map((m, i) => m &&
                (typeof(m.linkTo) === 'string' && i < breadcrumbs.length - 1
                    ? <BreadcrumbLink key={i} breadcrumb={m} />
                    : <Typography key={i} style={{ fontSize:'0.9rem' }} color="textPrimary">{m.text}</Typography>))
            }
        </Breadcrumbs>
    );
};

export { getBreadcrumbs, DciBreadcrumb, segmentMapping, getBreadcrumbMapping };