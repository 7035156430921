import {
    Typography
} from '@mui/material';
import ScreenCapture from './Images/ShareableUrls.png';

const WNShareableUrls = () => {
    return (
        <>
            <Typography variant='h5'>Shareable URLs</Typography>
            <img style={{ display:'block', margin:'20px auto' }} src={ScreenCapture} alt="Shareable URLs" />
            <Typography variant='body1'>The address bar will now update with every change of the Work Item views, allowing URLs to be shared with colleagues, or bookmarked for easy access. The URL will retain the following attributes:</Typography>
            <ul>
                <li>Sorting</li>
                <li>Filtering</li>
                <li>My Items Only</li>
                <li>Page Number</li>
                <li>Page Size</li>
            </ul>
        </>
    )
}

export { WNShareableUrls }