import React from 'react';
import { styled } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)(({ theme }) =>`
    cursor: pointer;
    font-weight: bold;
    color: ${theme.palette.secondary.main};
`);

type DciLinkProps = {
    href?: string,
    onClick?: (event: React.MouseEvent) => void | undefined,
    style?: React.CSSProperties,
    children: React.ReactNode
};

const DciLink = ({ href, onClick, children, style }: DciLinkProps) => {
    const url = useLocation();
    const currentPath = url.pathname + url.search;
    return (
        <StyledLink 
            to={href ?? currentPath} 
            onClick={onClick}
        >
            {children}
        </StyledLink>
    );
}

export default DciLink;