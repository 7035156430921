
const serializeAsXML = (obj: any) => (new XMLSerializer()).serializeToString(obj);

const encodeAsUTF8 = (s: string) => `data:image/svg+xml;charset=utf-8,${encodeURIComponent(s)}`;

const loadImage = async (url: string) => {
    const $img = document.createElement('img')
    $img.src = url
    return new Promise((resolve, reject) => {
        $img.onload = () => resolve($img)
        $img.onerror = reject
        $img.src = url
    })
}

const createCanvas = (svg: SVGSVGElement, img: CanvasImageSource) => {
    const canvas = document.createElement('canvas')
    canvas.width = svg.clientWidth
    canvas.height = svg.clientHeight
    canvas
        .getContext('2d')!
        .drawImage(img, 0, 0, svg.clientWidth, svg.clientHeight);

    return canvas.toDataURL(`image/png`, 1.0)
}

const exportImage = async (svg: SVGSVGElement, fileType: string, width: number, height: number) => {
    debugger;
    
    const defs = getDefs()
    svg.insertAdjacentHTML('afterbegin', '<rect width="100%" height="100%" fill="white"/>');
    svg.insertAdjacentHTML("afterbegin", defs)

    // Encode it as a data string:
    const serialized = serializeAsXML(svg);
    const svgData = encodeAsUTF8(serialized);

    const img = await loadImage(svgData);

    const linkSrc = createCanvas(svg, img as CanvasImageSource);

    let mimeType = 'image/png';
    let fileExtension = 'png';
    switch(fileType) {
        case 'PNG':
            mimeType = 'image/png';
            fileExtension = 'png';
            break;
        case 'JPG':
            mimeType = 'image/jpg';
            fileExtension = 'jpg';
            break;
    }

    const dlLink = document.createElement('a');
    dlLink.download = `chart.${fileExtension}`;
    dlLink.href = linkSrc;
    dlLink.dataset.downloadurl = [mimeType, dlLink.download, dlLink.href].join(':');

    dlLink.click();
}

const getDefs = () => {
    const styles = getStyles()
  
    return `<defs><style type=\"text/css\"><![CDATA[${styles}}]]></style></defs>`
  }
  
const stringifyStylesheet = (stylesheet: CSSStyleSheet) =>
    stylesheet.cssRules
        ? Array.from(stylesheet.cssRules)
            .map(rule => rule.cssText || '')
            .join('\n')
        : ''
  
const getStyles = () =>
    Array.from(document.styleSheets)
        .map(s => stringifyStylesheet(s))
        .join("\n")

export { exportImage }