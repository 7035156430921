import React from 'react';

import {
  Box,
  Card,
  CardContent,
  Grid,
  SxProps,
  Theme
} from '@mui/material';

import { ActiveWorkItemCountByPriorityCard } from '../Reporting/ActiveWorkItemCountByPriorityCard';
import { DciChart } from '../Reporting/DciChart';
import { HeadlineCard } from '../Reporting/HeadlineCard2';
import { ActiveWorkItemCountByRegulatoryImpactCard } from '../Reporting/ActiveWorkItemCountByRegulatoryImpactCard';
import { CoreKpisCard } from '../Reporting/CoreKpisCard';
import { DoughnutChart } from '../Reporting/DoughnutChart';
import { AFActiveWorkItemsByPriorityProvider, AFActiveWorkItemsByRegulatoryImpactProvider, AFActiveWorkItemsCardProvider, AFCoreKpisProvider, AFDailyWorkItemsPriorityProvider, AFDailyWorkItemsRegulatoryImpactProvider, AFOverdueWorkItemsCardProvider, AFTopRulesByVolumeDataProvider } from './AlternativeFactsProviders';
import dciPaths from '../../utils/dciPaths';
import { useNavigate } from 'react-router-dom';
import { ChartData, ChartElement } from '../../types';
import { ConfigurableChartCard } from './ConfigurableChartCardDemo';
import { demoData, last30DaysIdentifiers, last30DaysLabels } from './DemoData';
import { intervalToDuration } from 'date-fns';

const classes= {
  root: {
    '& .MuiCard-root': {
      height:'100%'
    },
    '& .MuiCardContent-root': {
      height:'100%'
    }
  } as SxProps<Theme>,
  rootBox: {
    flexGrow: 1,
  } as SxProps<Theme>,
  chartCard: {
    height: 350
  } as SxProps<Theme>,
  headlineCard: {
    '& .MuiCardContent-root': {
      display:'flex',
      flexDirection:'column',
    },
    '& .MuiCardContent-root .MuiTypography-root:first-of-type': {
      flex: '1 0 auto'
    },
    '& .MuiCardContent-root .MuiTypography-root:last-child': {
      marginBottom: 0
    }
  } as SxProps<Theme>
}

type ChartCardProps = {
  children?: React.ReactNode
}

const ChartCard = ({ children }: ChartCardProps) => {
  return (
        <Grid style={{ minWidth:0, minHeight:'350px' }} item xs={12} md={6}>
            <Card>
                <CardContent sx={classes.chartCard}>
                    {children}
                </CardContent>
            </Card>
        </Grid>
  );
}

const WorkItemsPerDayByRegulatoryImpact = () => {
    const navigate = useNavigate();
    
    const chartOnClick = (chartElement: ChartElement, chartData: ChartData) => {
        const url = `${dciPaths.reporting.buildLink('WorkItemsPerTimePeriod')}&eventType=Created&on=${chartData.identifiers[chartElement.dataIndex]}&outputType=ItemList`;
        navigate(url);
    };
    
    return (
        <AFDailyWorkItemsRegulatoryImpactProvider>
            <DciChart onClick={chartOnClick} chartOptions={{ type:'line' }} />
        </AFDailyWorkItemsRegulatoryImpactProvider>
    )
}

const WorkItemsPerDayByPriority = () => {
    const navigate = useNavigate();
    
    const chartOnClick = (chartElement: ChartElement, chartData: ChartData) => {
        const url = `${dciPaths.reporting.buildLink('WorkItemsPerTimePeriod')}&eventType=Created&on=${chartData.identifiers[chartElement.dataIndex]}&outputType=ItemList`;
        navigate(url);
    };
    
    return (
        <AFDailyWorkItemsPriorityProvider>
            <DciChart onClick={chartOnClick} chartOptions={{ type:'line' }} />
        </AFDailyWorkItemsPriorityProvider>
    )
}

const configurableChartCardDataProvider = (metric: string, grouping: string, start: Date, end: Date, workQueueIds: number[], teamIds: number[], token: string) => {
    const datasets = demoData[metric][grouping];
    
    const numDays = intervalToDuration({ start: start, end: end }).days ?? 0;

    let title = `${metric} Work Items`;
    if ([ 'Active', 'Overdue' ].includes(metric)) {
        title += ' (cumulative)'
    }

    if (grouping !== 'None') {
        title += ` by ${grouping}`
    }

    title += ` for the Last ${numDays} Days`;

    return {
      promise: Promise.resolve({
          data: {
            cumulativeWorkItemQuery: {
              title: title,
              dataSets: datasets.map(ds => ({ ...ds, data: ds.data.slice(-numDays) })),
              labels: last30DaysLabels.slice(-numDays),
              identifiers: last30DaysIdentifiers.slice(-numDays)
            }
          }
      }),
      abortController: new AbortController()
    }
}

const AlternativeFactsDashboard = () => {
    return (
        <Box sx={classes.root}>
            <Grid sx={classes.rootBox} container spacing={4}>
            <Grid sx={classes.headlineCard} item xs={12} md={6} lg={3}>
                <AFActiveWorkItemsCardProvider>
                  <HeadlineCard />
                </AFActiveWorkItemsCardProvider>
            </Grid>
            <Grid sx={classes.headlineCard} item xs={12} md={6} lg={3}>
                <AFOverdueWorkItemsCardProvider>
                  <HeadlineCard />
                </AFOverdueWorkItemsCardProvider>  
            </Grid>
            <Grid sx={classes.headlineCard} item xs={12} md={6} lg={3}>
                <AFActiveWorkItemsByPriorityProvider>
                  <ActiveWorkItemCountByPriorityCard workQueueIds={[]} teamIds={[]} />
                </AFActiveWorkItemsByPriorityProvider>
            </Grid>
            <Grid sx={classes.headlineCard} item xs={12} md={6} lg={3}>
                <AFActiveWorkItemsByRegulatoryImpactProvider>
                  <ActiveWorkItemCountByRegulatoryImpactCard workQueueIds={[]} teamIds={[]} />
                </AFActiveWorkItemsByRegulatoryImpactProvider>
            </Grid>
            <ChartCard>
                <WorkItemsPerDayByRegulatoryImpact />
            </ChartCard>
            <ChartCard>
                <WorkItemsPerDayByPriority />
            </ChartCard>
            <ChartCard>
                <ConfigurableChartCard dataProvider={configurableChartCardDataProvider} workQueueIds={[]} teamIds={[]} disableOverdue={false} />
            </ChartCard>
            <Grid item xs={12} md={6}>
                <AFCoreKpisProvider>
                    <CoreKpisCard />
                </AFCoreKpisProvider>
            </Grid>
            <ChartCard>
                <AFTopRulesByVolumeDataProvider>
                    <DoughnutChart />
                </AFTopRulesByVolumeDataProvider>
            </ChartCard>
            </Grid>
        </Box>
    );
}

export { AlternativeFactsDashboard };