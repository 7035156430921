import { useContext } from 'react';
import { ActiveElement, CategoryScale, Chart, ChartEvent, Filler, LinearScale, LineElement, PointElement, Title } from 'chart.js';
import Color from 'color';
import { ChartData, ChartElement, ChartOptions } from '../../types';
import { Box, CircularProgress, Stack, useTheme } from '@mui/material';
import { DciChartDataContext } from '../DataProviders/DciChartDataContext';
import { Line } from 'react-chartjs-2';

Chart.register(CategoryScale, Filler, LinearScale, LineElement, PointElement, Title);

type DciChartProps = {
  chartOptions: ChartOptions,
  onClick?: (chartElement: ChartElement, chartData: ChartData) => void
};

const DciChart = ({ chartOptions, onClick }: DciChartProps) => {
    const chartData = useContext(DciChartDataContext);
    const theme = useTheme();

    let max: number | undefined = undefined;
    let stepSize: number | undefined = undefined;

    if (chartData && chartData.data) {
      for (let set = 0; set < chartData.data.dataSets.length; set++) {
        const element = chartData.data.dataSets[set];
  
        for (let j = 0; j < element.data.length; j++) {
          const dataPoint = element.data[j];
          if (!max || dataPoint > max) {
            max = dataPoint;
          }
        }
      }
      
      // Ensure that the scale isn't silly if there is a low number of items
      if ((max ?? 0) < 5) {
        stepSize = 1;
        max = 5;
      } else {
        // Setting these properties to undefined allows them chartjs to resize when datasets are filtered out - see DCI-244
        stepSize = undefined;
        max = undefined;
      }
    }

    const chartOnClick = (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
      if (chartData === null || chartData.data === null) {
        return;
      }

      if (onClick && event.native) {
        const elements = chart.getElementsAtEventForMode(event.native, 'index', { intersect: true }, false);
        if (elements.length > 0) {
          // In reality elements only seems to return 1 element at most
          const firstElement = elements[0];
          onClick({ dataIndex: firstElement.index, datasetIndex: firstElement.index }, chartData.data);
        }
      }
    };

    return (
      chartData != null && (chartData.fetching || chartData.data !== null)
        ? <Stack style={{ width:'100%', height:'100%' }} alignItems='center' justifyContent='center'>
            { chartData.fetching
              ? <CircularProgress />
              : <Line
              data={{
                labels: chartData.data!.labels,
                datasets: chartData.data!.dataSets.map(m => ({
                  data: m.data,
                  label: m.label,
                  fill: chartOptions.stacked === true,
                  backgroundColor: Color(m.colour).lighten(0.25).hex(),
                  pointRadius: 3,
                  borderColor: m.colour,
                  borderWidth: 2,
                  lineTension: 0.5
                }))
              }}
              options={{
                onClick: chartOnClick,
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  x: {
                    ticks: {
                      color: theme.palette.text.primary
                    }
                  },
                  y :{
                    stacked: chartOptions.stacked,
                    min: 0,
                    max: max,
                    ticks: {
                      stepSize: stepSize,
                      color: theme.palette.text.primary
                    }
                  }
                },
                plugins: {
                  title: {
                    display: true,
                    text: chartData.data!.title,
                    color: theme.palette.text.primary
                  },
                  legend: {
                      labels: {
                          color: theme.palette.text.primary
                      }
                  }
                }
              }}
            />
            }

            
          </Stack>
        : <p>No chart data available.</p>
    )
};

export { DciChart }