import {
    Typography
} from '@mui/material';
import { Link } from 'react-router-dom';
import dciPaths from '../../../utils/dciPaths';
import ScreenCapture from './Images/CreateUserScreen.png';

const WNCreateUser = () => {
    return (
        <>
            <Typography variant='h5'>Create Users</Typography>
            <Typography variant='body1'>You can now create users through the user screen.</Typography>
            <img style={{ display:'block', margin:'20px auto' }} src={ScreenCapture} alt="Add User screen capture" />
            <Typography variant='body1'>Head on over to the <Link to={dciPaths.users.buildLink()}>Users page</Link> to take advantage of this feature.</Typography>
        </>
    )
}

export { WNCreateUser }