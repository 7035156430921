const TheTable = () => {
    return (
        <p>Test Page</p>
    )
}

const TestPage = () => {
    return <TheTable />
}

export { TestPage }