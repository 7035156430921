import { useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Select,
    TextField,
} from '@mui/material';
import callDciApi, { getDciApiResponse, postDciApi } from '../utils/callDciApi';
import { useWorkQueues } from './Api/WorkQueues';

type AssignToQueueDialogProps = {
    workItemIds: number[],
    isOpen: boolean,
    close: (refresh: boolean) => void
};

const AssignToQueueDialog = ({ workItemIds, isOpen, close }: AssignToQueueDialogProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const { data: workQueues, isFetching: workQueuesLoading } = useWorkQueues();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [ noteContent, setNoteContent ] = useState('');
    const [ workQueueId, setWorkQueueId ] = useState('');

    const onSave = () => {
        const assignWorkItems = async () => {
            const token = await getAccessTokenSilently();

            const query = `mutation{assignWorkItemsToQueue(workItemIds:[${workItemIds.join()}],workQueueId:${workQueueId},noteContent:"""${noteContent}""")}`;
            callDciApi(query, token)
            .then(body => {
                if (!body.errors) {
                    close(false);
                }

                close(true);
            });
        };

        assignWorkItems();
    }

    return (
        <Dialog open={isOpen} fullScreen={fullScreen} maxWidth={'sm'} fullWidth aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Assign Work Items</DialogTitle>
            <DialogContent>
            <DialogContentText>Select a queue to assign the work items to and optionally a note explaining the assignment</DialogContentText>
            { workQueuesLoading
                ? <Typography>Loading Work Item statuses...</Typography>
                : <Select
                    style={{ marginBottom: '10px' }}
                    native
                    fullWidth
                    value={workQueueId}
                    onChange={(event) => setWorkQueueId(event.target.value as string)}
                    inputProps={{ name: 'workQueueId', id: 'new-work-queue-id' }}
                    variant={'outlined'}
                >
                    <option aria-label="None" value='' />
                    {(workQueues ?? []).map(m => <option key={m.workQueueId} value={m.workQueueId}>{m.name}</option>)}
                </Select>
            }
            
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Type your note here"
                type="text"
                fullWidth
                multiline
                rows={8}
                variant={'outlined'}
                value={noteContent}
                onChange={(event) => setNoteContent(event.target.value)}
            />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close(false)}>Cancel</Button>
                <Button disabled={workQueueId === ''} onClick={onSave}>Assign</Button>
            </DialogActions>
        </Dialog>
    );
};

export { AssignToQueueDialog }