import { Accordion, AccordionDetails, AccordionSummary, Box, FormControl, InputLabel, MenuItem, Paper, Select, Stack, TableCell, Typography } from "@mui/material"
import { Grouping, PeriodType, ReportType, ReportingEventTypeSpec, WorkItemReportingState, WorkItemReportingStoreContext, createWorkItemReportingStore, useWorkItemReportingState } from './Store/WorkItemReportingStore';
import { FilterNode, TableFilter } from "../Filtering/TableFilter";
import { ArrowDropDownIcon, DatePicker } from "@mui/x-date-pickers";
import { Table } from "../Table/Table";
import { TableColumn } from "../Filtering/types";
import DciLink from "../DciLink";
import dciPaths from "../../utils/dciPaths";
import { Flag } from "@mui/icons-material";
import { differenceInCalendarDays, format, parse } from "date-fns";
import { AsyncData } from "../Table/Shared";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { TableStoreContext, useTableState } from "../Table/Stores/TableStore";
import { NewChart } from "./NewChart";
import { ChartStoreContext } from "./Store/ChartStore";
import { TableContainer } from "../Table/TableContainer";
import { FilterStoreContext } from "../Filtering/StoreFunctions";
import { WorkItemActionsMenu } from "../WorkItems/WorkItemActionsMenu";
import { useSnackbar } from "notistack";
import { LineItemIdentifier } from "@mui/x-charts";
import { ChartData } from "../../types";
import { RegulatoryImpactApi } from "../Api/RegulatoryImpactApi";
import { fetchWorkQueues } from "../Api/WorkQueues";
import authClient from "../Auth/auth-client";
import { UsersClient } from "../Api/UserApi";
import { StoreApi, useStore } from "zustand";

interface WorkItem {
    workItemId: number,
    noteCount: number,
    creationDatetime: Date,
    dueDate: Date,
    reportMessage: string,
    reportTestId: number,
    status: string,
    priority: number,
    regulatoryImpact: string,
    regulatoryImpactValue: number,
    regulatoryImpactDisplayIcon: string,
    [property: string]: any
}

const NewReporting = () => {
    const [ store ] = useState(() => {
        return createWorkItemReportingStore((row: WorkItem) => row.workItemId);
    })

    return <WorkItemReportingStoreContext.Provider value={store}>
        <FilterStoreContext.Provider value={store}>
            <TableStoreContext.Provider value={store as any}>
                <ChartStoreContext.Provider value={store}>
                    <Inner store={store} />
                </ChartStoreContext.Provider>
            </TableStoreContext.Provider>
        </FilterStoreContext.Provider>
    </WorkItemReportingStoreContext.Provider>
}

const dateOnly = (date: Date | null) => {
    if (date === null) {
        const today = new Date();
        return new Date(today.getFullYear(), today.getMonth(), today.getDate());
    }

    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

const Inner = ({ store }: { store: StoreApi<WorkItemReportingState> }) => {
    const { enqueueSnackbar } = useSnackbar();

    const eventTypes = useWorkItemReportingState(s => s.eventTypes);
    const reportTypes = useWorkItemReportingState(s => s.reportTypes);
    const periodTypes = useWorkItemReportingState(s => s.periodTypes);

    const groupBy = useWorkItemReportingState(s => s.groupBy);
    const setGroupBy = useWorkItemReportingState(s => s.setGroupBy);
    
    const startDate = useWorkItemReportingState(s => s.startDate);
    const setStartDate = useWorkItemReportingState(s => s.setStartDate);

    const endDate = useWorkItemReportingState(s => s.endDate);
    const setEndDate = useWorkItemReportingState(s => s.setEndDate);

    const eventType = useWorkItemReportingState(s => s.eventType);
    const setEventType = useWorkItemReportingState(s => s.setEventType);

    const reportType = useWorkItemReportingState(s => s.reportType);
    const setReportType = useWorkItemReportingState(s => s.setReportType);

    const periodType = useWorkItemReportingState(s => s.periodType);
    const setPeriodType = useWorkItemReportingState(s => s.setPeriodType);

    const hasHydrated = useWorkItemReportingState(s => s.hasHydrated);

    const filter = useWorkItemReportingState(s => s.filter);
    const filterColumns = useWorkItemReportingState(s => s.filterColumns);

    const chartData = useWorkItemReportingState(s => s.chartData);

    const [ reportDetailsExpanded, setReportDetailsExpanded ] = useState(false);
    const [ filterExpanded, setFilterExpanded ] = useState(false);

    const today = new Date();

    const onMarkClick = async (event: React.MouseEvent<SVGElement, MouseEvent>, lineItemIdentifier: LineItemIdentifier) => {
        if (eventType.metricType !== 'EVENT_COUNT') {
            enqueueSnackbar('Click-through for Active and Overdue items coming soon.', { variant: 'info' });
            return;
        }

        if (!chartData.data || !lineItemIdentifier.dataIndex) {
            return;
        }
    
        const on = new Date(Date.parse(chartData.data.identifiers[lineItemIdentifier.dataIndex]));

        let newFilterNode: FilterNode | null = null;
        if (groupBy !== null) {
            let filterValue = null;
            switch(groupBy.groupingColumn) {
                case 'regulatoryImpactId':
                    const ris = await RegulatoryImpactApi.get();
                    filterValue = ris.find(ri => ri.regulatoryImpactId === lineItemIdentifier.seriesId);
                    break;
                case 'workQueueId':
                    const token = await authClient.getTokenSilently();
                    const wqs = await fetchWorkQueues(token);
                    filterValue = wqs.find(wq => wq.workQueueId === lineItemIdentifier.seriesId);
                    break;
                case 'workItemPriority':
                    filterValue = {
                        description: (lineItemIdentifier.seriesId as number) > 0 ? `Priority ${lineItemIdentifier.seriesId}` : 'Not defined',
                        value: lineItemIdentifier.seriesId
                    };
                    break;
                case 'reportingEventUserId':
                    const users = await UsersClient.get();
                    const user = users.find(u => u.userId === lineItemIdentifier.seriesId);
                    if (user) {
                        filterValue = {
                            userId: user.userId,
                            displayText: [ user.firstName, user.surname ].join(' ')
                        }
                    }
                    break;
            }

            if (filterValue) {
                newFilterNode = {
                    column: filterColumns.find(fc => fc.name === `filter.${groupBy.groupingColumn}`)!,
                    id: crypto.randomUUID(),
                    valueIsValid: true,
                    value: [ filterValue ]
                } as FilterNode;
            }
        }

        store.setState(existing => ({
            chartData: {
                data: null,
                isCancelled: false,
                isError: false,
                isFetching: false
            },
            startDate: on,
            endDate: on,
            periodType: 'On',
            reportType: 'Item List',
            reportTypes: [ 'Item List' ],
            groupBy: null,
            groupingColumn: null, // Do we need this in addition to the groupBy above?
            filter: newFilterNode ? [ ...existing.filter, newFilterNode ] : existing.filter
        }));
        store.getState().updateRowData(false);
    }

    if (!hasHydrated) {
        return <Typography>Loading...</Typography>
    }

    return (
        <Stack direction='column' gap={2} sx={{ height:'100%' }}>
            <Box>
                <Accordion expanded={reportDetailsExpanded} onChange={_ => setReportDetailsExpanded(!reportDetailsExpanded)}>
                    <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                        <Typography style={{ fontWeight:reportDetailsExpanded ? 'initial' : 'bold' }}>
                            { reportDetailsExpanded 
                                ? 'Report Details'
                                : `Report Details: ${eventType.displayName} ${periodType.toLowerCase()} ${format(startDate, 'dd/MM/yyyy')}${(periodType === 'Between' && endDate !== null) ? ` and ${format(endDate, 'dd/MM/yyyy')}` : ''}${(reportType === 'Line Chart' && groupBy !== null) ? ` grouped by ${groupBy.displayName}` : ''}`}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack gap={2}>
                            <Stack direction='row' gap={2}>
                                <FormControl style={{ width: 350 }} variant='standard'>
                                    <InputLabel id="event-type-select-label">Event</InputLabel>
                                    <Select
                                        labelId="event-type-select-label"
                                        id="event-type-select"
                                        value={eventType}
                                        onChange={e => setEventType(e.target.value as ReportingEventTypeSpec)}
                                    >
                                        { eventTypes.map(m => (
                                            //@ts-ignore - necessary to load object into value
                                            <MenuItem key={m.name} value={m}><span style={{ marginLeft:m.level ? m.level * 5 : 0 }}>{m.displayName}</span></MenuItem>
                                        )) }
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack direction='row' gap={2}>
                                <FormControl style={{ width: 110 }} variant='standard'>
                                    <InputLabel />
                                    <Select
                                        id='period-type'
                                        value={periodType}
                                        onChange={event => setPeriodType(event.target.value as PeriodType)}
                                    >
                                        { periodTypes.map(pt => <MenuItem key={pt} value={pt}>{pt}</MenuItem>) }
                                    </Select>
                                </FormControl>

                                <DatePicker
                                    format='dd/MM/yyyy'
                                    label={periodType === 'On' ? ' ' : 'Start Date'}
                                    views={['year', 'month', 'day']}
                                    value={startDate}
                                    disableFuture
                                    onChange={date => setStartDate(dateOnly(date))}
                                    slotProps={{
                                        textField: {
                                            variant:'standard'
                                        }
                                    }}
                                />

                                { periodType === 'Between' && <DatePicker
                                    format='dd/MM/yyyy'
                                    label='End Date'
                                    views={['year', 'month', 'day']}
                                    value={endDate}
                                    maxDate={today}
                                    onChange={date => setEndDate(dateOnly(date))}
                                    slotProps={{
                                        textField: {
                                            variant:'standard'
                                        }
                                    }}
                                />}
                            </Stack>
                            <Stack direction='row' gap={2}>
                                <FormControl style={{ width: 110 }} variant='standard'>
                                    <InputLabel id='report-type-label'>Report Type</InputLabel>
                                    <Select
                                        id='report-type'
                                        labelId='report-type-label'
                                        value={reportType}
                                        onChange={event => setReportType(event.target.value as ReportType)}
                                    >
                                        { reportTypes.map(rt => <MenuItem key={rt} value={rt}>{rt}</MenuItem>) }


                                        {/* { ![ 'Active', 'Overdue' ].includes(eventType.name) && <MenuItem value='Item List'>Item List</MenuItem> }
                                        { periodType === 'Between' && <MenuItem value='Line Chart'>Line Chart</MenuItem>} */}
                                        {/* <MenuItem value='PieChart'>Pie Chart</MenuItem> */}
                                    </Select>
                                </FormControl>
                                { reportType === "Line Chart" &&
                                <FormControl sx={{ minWidth:'220px' }} variant='standard'>
                                    <InputLabel id="grouping-select-label">{ 'Group by' }</InputLabel>
                                    <Select
                                        labelId="grouping-select-label"
                                        id="grouping-select"
                                        value={(groupBy === null ? '' : groupBy)}
                                        onChange={e => setGroupBy(e.target.value === '' ? null : (e.target.value as Grouping))}
                                    >
                                        <MenuItem value=''>No Grouping</MenuItem>
                                        
                                        { eventType && eventType.groupings.map(m => 
                                            //@ts-ignore - necessary to load object into value
                                            (<MenuItem key={m.name} value={m}>{m.displayName}</MenuItem>)) 
                                        }
                                    </Select>
                                </FormControl>}
                            </Stack>
                        </Stack>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={filterExpanded} onChange={_ => setFilterExpanded(!filterExpanded)}>
                    <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                        <Typography style={{ fontWeight:filterExpanded ? 'initial' : 'bold' }}>
                            { filterExpanded ? 'Filter' : filter.length === 0 ? 'No filter applied' : `Filter: ${filter.length} applied` }
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableFilter dontAddInitial />
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box flexGrow={1} overflow='auto' padding='2px'>
                { reportType === 'Item List' ? <ItemList /> : null }
                { reportType === 'Line Chart' ? <NewChart onMarkClick={onMarkClick} allowExport={true} /> : null }
            </Box>
        </Stack>
    )
}

interface RuleCellProps {
    row: WorkItem
}

const RuleCell = ({ row }: RuleCellProps) => {
    return (
        <TableCell 
            style={{ textAlign:'center' }} 
            key='ruleNo'
        >
            <DciLink href={dciPaths.rule.buildLink(row.reportTestId)} onClick={e => e.stopPropagation()}>
                {row.reportTestId}
            </DciLink>
        </TableCell>
    )
}

interface ItemListProps {
    data: AsyncData
}

const itemListColumns: TableColumn[] = [
    {
        displayName: "ID",
        selector: "workItemId",
        style: { width:'78px' }
    },
    {
        displayName: 'Notes',
        selector: 'noteCount',
        justify: 'center',
        sortable: false,
        style: { width:'58px' }
    },
    {
        displayName: "Found",
        selector: 'creationDatetime',
        render: (row: WorkItem) => <TableCell style={{ padding:'10px' }} key='creationDatetime'>{row.creationDatetime.toLocaleDateString()}</TableCell>,
        style: { width:'96px' }
    },
    { 
        displayName: "Due",
        selector: 'dueDate',
        render: (row: WorkItem) => <TableCell style={{ padding:'10px', color: differenceInCalendarDays(new Date(), row.dueDate) > 0 ? 'red' : 'inherit' }} key='dueDate'>{row.dueDate.toLocaleDateString()}</TableCell>,
        style: { width:'96px' }
    },
    {
        displayName: "Message",
        selector: "reportMessage"
    },
    {
        displayName: "Rule",
        selector: 'reportTestId',
        justify: 'center',
        render: (row: WorkItem) => <RuleCell row={row} />,
        style: { width:'78px', maxWidth:'78px' }
    },
    {
        displayName: "Status",
        sortable: 'workItemStatusId',
        selector: 'status',
        style: { width:'158px' }
    },
    { 
        displayName: "Priority",
        sortable: 'workItemPriority',
        justify: 'center',
        selector: "priority",
        style: { width:'92px' }
    },
    { 
        displayName: "RI",
        sortable: 'regulatoryImpact.regulatoryImpactValue',
        justify: 'center',
        render: (row: WorkItem) => 
            <TableCell 
                style={{ textAlign:'center' }} 
                key='regImpact' 
                padding='checkbox'
            >
                { row.regulatoryImpactDisplayIcon && 
                    <Flag
                        style={{ color:row.regulatoryImpactDisplayIcon }} 
                        // onMouseEnter={event => handlePopoverOpen(event, row.regulatoryImpactDescription)}
                        // onMouseLeave={handleRegImpactPopoverClose} 
                    />
                }
            </TableCell>,
        selector: 'regulatoryImpactValue',
        style: { width:'58px' }
    },
];

const ToolbarButtons = () => {
    const selectedData = useTableState(s => s.selectedData);
    const updateData = useTableState(s => s.updateRowData);

    return <Stack direction='row'>
        <WorkItemActionsMenu workItemIds={selectedData.map(m => m.workItemId)} refreshParent={() => updateData(true)} />
    </Stack>
}

const ItemList = () => {
    const navigate = useNavigate();
    const onRowClick = (row: WorkItem) => navigate(dciPaths.workItem.buildLink(row.workItemId));
    
    return <TableContainer
        title='Work Items'
        paged
        enableGlobalSelectAll
        toolbarButtons={<ToolbarButtons />}
        style={{ height:'100%', width:'100%' }}
        enableExport
    >
        <Table
            selectable={true}
            uniqueSortColumn='workItemId'
            columns={itemListColumns}
            idFromValue={row => row.workItemId}
            onRowClick={onRowClick}
        />
    </TableContainer>
}


export { NewReporting }