import React, { useState } from 'react';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import callDciApi from '../../utils/callDciApi';

interface AddWorkQueueDialogProps {
    isOpen: boolean
    close: (refresh: boolean) => void
}

const AddWorkQueueDialog = ({ isOpen, close }: AddWorkQueueDialogProps) => {
    const [ workQueueName, setWorkQueueName ] = useState('');
    const { getAccessTokenSilently } = useAuth0();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const onSave = async () => {
        const token = await getAccessTokenSilently();
        callDciApi(`mutation{createWorkQueue(name:"${workQueueName}"){workQueueId}}`, token)
        .then(body => {
            if (!body.errors) {
                setWorkQueueName('');
                close(true);
            }
        })
        .catch(error => {
            console.error(`[AddWorkQueueDialog] createWorkQueue: ${error}`);
        });
    };

    const cancel = () => {
        setWorkQueueName('');
        close(false);
    }

    return (
        <Dialog open={isOpen} fullScreen={fullScreen} maxWidth={'sm'} fullWidth aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Create Work Queue</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Type in a name for the new Work Queue"
                    type="text"
                    fullWidth
                    variant={'outlined'}
                    value={workQueueName}
                    onChange={(event) => setWorkQueueName(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel}>Cancel</Button>
                <Button disabled={workQueueName === ''} onClick={onSave}>Create</Button>
            </DialogActions>
        </Dialog>
    );
};

export { AddWorkQueueDialog }