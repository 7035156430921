import { useEffect, useState } from 'react';
import OrgContainer from './OrgContainer';
import {
    Box,
    Button,
    Card, 
    CardContent,
    Grid,
    Input,
    Typography
} from '@mui/material';
import { 
    Edit,
    Save
} from '@mui/icons-material';
import { ControlButton, FieldCaption } from '../DciControls';
import { useAuth0 } from '@auth0/auth0-react';
import callDciApi from '../../utils/callDciApi';
import userHasPermission from '../../utils/userHasPermission';
import { permissions } from '../../utils/dciConstants';
import { AuditItemsByEntity } from '../AuditItemsByEntity';
import { useStore } from 'zustand';
import { appGlobalStore } from '../../AppGlobalStore';
import { Indexed } from '../Filtering/types';

interface OrganisationConfig {
    configId: number,
    value: string | null,
    description: string | null
}

export default function OrgConfig() {
    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const [ data, setData ] = useState<OrganisationConfig[]>([]);
    const [ loadData, setLoadData ] = useState(0);
    const [ editMode, setEditMode ] = useState(false);
    const [ amendedItem, setAmendedItem ] = useState<Indexed>({});
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        let active = true;
        async function fetchData() {
            const token = await getAccessTokenSilently();
            callDciApi(`{organisationConfigs{configId,value,description}}`, token)
            .then(body => {
                if (!active) return;
                if (body.errors) {
                    alert(body.errors[0].message);
                } else {
                    setData(body.data.organisationConfigs);
                    setAmendedItem({});
                };
            });
        }

        fetchData();
        return () => { active = false };
    }, [ loadData ]);

    const hasEditPermission = userHasPermission(currentUser, permissions.EDIT_SYSTEM_SETTINGS);

    const getValue = (configId: number) => {
        if (amendedItem.hasOwnProperty(configId)) {
            return ;
        }

        const unmodified = data.find(x => x.configId === configId);
        return unmodified!.value;
    }

    const cancelChanges = () => {
        setAmendedItem({});
        setEditMode(false);
    };

    const changesMade = () => {
        const keys = Object.keys(amendedItem);
        return keys.length > 0 && keys.some(key => {
            return amendedItem[key] !== data.find(y => y.configId.toString() === key)!.value;
        });
    };

    const saveChanges = async () => {
        const changesToApply: Omit<OrganisationConfig, 'description'>[] = [];
        Object.keys(amendedItem).forEach(element => {
            if (amendedItem[element] !== data.find(x => x.configId.toString() === element)!.value) {
                changesToApply.push({
                    configId: parseInt(element),
                    value: amendedItem[element]
                });
            }
        });

        if (changesToApply.length > 0) {
            const token = await getAccessTokenSilently();
            callDciApi(`mutation{editOrganisationConfig(newSettings:[${changesToApply.map(m => `{configId:${m.configId},value:"${m.value}"}`)}])}`, token)
            .then(body => {
                if (!body.errors) {
                    setLoadData(x => x + 1);
                    setEditMode(false);
                } else {
                    alert(body.errors[0].message);
                }
            });
        }
    };

    return (
        <OrgContainer>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Box style={{ float:'right' }}>
                                {hasEditPermission && !editMode && <Button startIcon={<Edit />} onClick={() => setEditMode(true)}>Edit</Button>}
                            </Box>
                            <Typography variant='h5' paragraph>Organisation Settings</Typography>
                            { data.map((m, i) => 
                                <div key={i} style={{ marginBottom:10 }}>
                                    <FieldCaption caption={m.description ?? 'No Configuration Item Description Available'} />
                                    { editMode
                                        ? <Input
                                            style={{ width:'300px' }}
                                            id={`configId-${m.configId}`}
                                            value={getValue(m.configId)}
                                            onChange={ event => { const newValue = event.target.value; setAmendedItem(item => ({ ...item, [m.configId]: newValue })) }}
                                        />
                                        : <Typography variant="body2">{getValue(m.configId)}</Typography>}
                                </div>) 
                            }
                            { editMode &&
                                <>
                                    <ControlButton onClick={saveChanges} disabled={!changesMade()} startIcon={<Save />} variant='contained'>Save</ControlButton>
                                    <ControlButton onClick={cancelChanges} variant='contained'>Cancel</ControlButton>
                                </>
                            }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <AuditItemsByEntity type='OrganisationConfig' />
                </Grid>
            </Grid>
        </OrgContainer>
    )
}