import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddBox, Check, Clear } from '@mui/icons-material';
import { AddWorkQueueDialog } from '../Rules/AddWorkQueueDialog';
import { 
    Button,
    TableCell, 
    ToggleButton,
    alpha
} from '@mui/material';

import OrgContainer from './OrgContainer';
import dciPaths from '../../utils/dciPaths';
import userHasPermission from '../../utils/userHasPermission';
import { permissions } from '../../utils/dciConstants';
import { StoreApi, useStore } from 'zustand';
import { appGlobalStore } from '../../AppGlobalStore';
import { TableState, TableStoreContext, createTableStore, useTableState } from '../Table/Stores/TableStore';
import { TableContainer } from '../Table/TableContainer';
import { Table } from '../Table/Table';
import { TableColumn } from '../Filtering/types';
import { PARAMETER_TYPE } from '../../GraphQLShared';

type WorkQueue = {
    workQueueId: number,
    name: string,
    enabled: boolean
}

const columns: TableColumn[] = [
    {
        displayName: 'Name',
        selector: 'name'
    },
    {
        displayName: 'Enabled',
        selector: 'enabled',
        justify: 'center',
        render: row => (<TableCell key='enabled' style={{ textAlign: 'center' }} padding={'checkbox'}>{row.enabled ? <Check /> : <Clear />}</TableCell>)
    }
];

const createStore = () => createTableStore({
        graphQLQueryName: 'allWorkQueues',
        graphQLQueryColumns: '{workQueueId,name,enabled}',
        uniqueSortColumn: 'workQueueId',
        idFromRow: row => row.workQueueId
    },
    {
        fixedParameters: [{ name:'where.enabled.eq', type:PARAMETER_TYPE.WHERE_BOOLEAN, value: true }]
    });

const Controls = () => {
    const [ addWorkQueueDialogIsOpen, setAddWorkQueueDialogIsOpen ] = useState(false);
    const [ showDisabled, setShowDisabled ] = useState(false);
    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const canAddWorkQueue = userHasPermission(currentUser, permissions.ADD_TEAM);

    const updateData = useTableState(s => s.updateRowData);
    const setFixedParameters = useTableState(s => s.setFixedParameters);
    
    const toggleShowDisabled = () => {
        const newValue = !showDisabled;
        setShowDisabled(newValue);

        setFixedParameters(newValue ? [] : [{ name:'where.enabled.eq', type:PARAMETER_TYPE.WHERE_BOOLEAN, value: true }]);
        updateData(false);
    }

    const close = (refresh: boolean) => {
        setAddWorkQueueDialogIsOpen(false);
        if (refresh === true) {
            updateData(false);
        }
    };
    
    return <>
        <ToggleButton
            value='showDisabled'
            selected={showDisabled}
            size='small'
            style={{ border:'none', marginRight: '5px' }}
            onClick={() => toggleShowDisabled()}
        >
            Show Disabled
        </ToggleButton>
        { canAddWorkQueue && 
            <>
                <Button
                    sx={theme => ({ 
                        color:theme.palette.text.primary,
                        borderColor:theme.palette.divider,
                        '&:hover': {
                            borderColor: theme.palette.action.disabledBackground,
                            backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity)
                        }
                    })}
                    style={{ margin:'0px 4px' }}
                    endIcon={<AddBox />}
                    variant='outlined'
                    onClick={() => setAddWorkQueueDialogIsOpen(true)}
                    aria-label='add work queue'>
                        Add
                </Button>
                <AddWorkQueueDialog isOpen={addWorkQueueDialogIsOpen} close={close} />
            </>
        }
    </>
}

export default function WorkQueues() {
    const navigate = useNavigate();
    const [ store, setStore ] = useState<StoreApi<TableState>>(() => createStore());

    return (
        <OrgContainer>
            <TableStoreContext.Provider value={store}>
                <TableContainer
                    title='Work Queues'
                    paged
                    style={{ height:'100%', width:'100%' }}
                    enableExport
                    toolbarButtons={<Controls />}
                >
                    <Table
                        uniqueSortColumn='workQueueId'
                        columns={columns}
                        idFromValue={row => row.workQueueId}
                        onRowClick={row => navigate(dciPaths.workQueue.buildLink(row.workQueueId))}
                    />
                </TableContainer>
            </TableStoreContext.Provider>
        </OrgContainer>
    )
};