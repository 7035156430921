import {
    Typography
} from '@mui/material';
import ScreenCapture from './Images/UserActivity.png';

const WNUserActivity = () => {
    return (
        <>
            <Typography variant='h5'>User Activity</Typography>
            <Typography variant='body1'>We have introduced a new view to reflect on the work done by users and teams to resolve issues in DCI.</Typography>
            <img style={{ display:'block', margin:'20px auto' }} src={ScreenCapture} alt="User Activity View screen capture" />
            <Typography variant='body1'>Users with the "View User Activity" permission will see the new option in the navigation menu.</Typography>
        </>
    )
}

export { WNUserActivity }