import {
    Typography
} from '@mui/material';
import ScreenCapture from './Images/multifilters.png';

const WNMultipleFilters = () => {
    return (
        <>
            <Typography variant='h5'>Apply Multiple Filters</Typography>
            <Typography variant='body1'>In addition to the new filter types, you have the ability to apply multiple filters.</Typography>
            <img style={{ display:'block', width:'850px', margin:'20px auto' }} src={ScreenCapture} alt="Multiple Filters" />
        </>
    )
}

export { WNMultipleFilters }