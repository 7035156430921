import { fromBase64, isValid } from 'js-base64';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { createTableStore, TableStoreContext } from '../Table/Stores/TableStore';
import { defaultMappingFunction, defaultQueryColumns, WorkItemsList } from '../WorkItems/WorkItemsList';
import { QueryParameter } from '../../GraphQLShared';
import { filterColumns } from '../WorkItems/Shared';
import { FilterStoreContext } from '../Filtering/StoreFunctions';

const getReportParametersFromQueryString = (search: string): { paramsPresent: boolean, parameters: WorkItemsReportParameters | null } => {
    const queryStringParams = new URLSearchParams(search);
    if (!queryStringParams.has('q')) {
        return {
            paramsPresent: false,
            parameters: null
        };
    }

    const base64 = queryStringParams.get('q')!;
    if (!isValid(base64)) {
        return {
            paramsPresent: true,
            parameters: null
        }
    }

    var json = fromBase64(base64);
    var reportParameters = JSON.parse(json) as WorkItemsReportParameters;
    
    if (typeof reportParameters.parameters === 'undefined' || !Array.isArray(reportParameters.parameters)) {
        console.error('Query from URL not valid.');
        return {
            paramsPresent: true,
            parameters: null
        }
    }
    
    return {
        paramsPresent: true,
        parameters: reportParameters
    };
}

type WorkItemsReportParameters = {
    title?: string,
    parameters?: QueryParameter[]
};

const WorkItemsReport = () => {
    const location = useLocation();
    const [ props ] = useState(() => {
        const { paramsPresent, parameters } = getReportParametersFromQueryString(location.search);
        
        const title = parameters?.title ?? 'Work Items';
        const store = createTableStore({
            graphQLQueryName: 'allWorkItems',
            graphQLQueryColumns: defaultQueryColumns,
            uniqueSortColumn: 'workItemId',
            idFromRow: row => row.workItemId
        },
        {
            paged: true,
            mappingFunction: defaultMappingFunction,
            fixedParameters: paramsPresent && parameters?.parameters ? parameters.parameters : [],
            filterColumns: filterColumns
        });

        return {
            title,
            store
        }
    });
        

    return <TableStoreContext.Provider value={props.store}>
        <FilterStoreContext.Provider value={props.store}>
            <WorkItemsList title={props.title} />
        </FilterStoreContext.Provider>
    </TableStoreContext.Provider>
}

export { WorkItemsReport };