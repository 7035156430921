import { Box, CircularProgress, MenuItem, Select, Stack, Typography } from "@mui/material"
import { useEffect, useMemo, useState } from "react";
import { ChartData } from "../../types";
import { DciChart } from "../Reporting/DciChartNonContext";
import { CancellablePromise } from "../../utils/callDciApi";
import { useAuth0 } from "@auth0/auth0-react";
import { addDays, format, parse } from "date-fns";

interface ConfigurableChartProps {
    metric: string,
    grouping: string,
    start: Date,
    end: Date,
    workQueueIds: number[],
    teamIds: number[],

    dataProvider: (metric: string, grouping: string, start: Date, end: Date, workQueueIds: number[], teamIds: number[], token: string) => CancellablePromise
}

const ConfigurableChart = ({ metric, grouping, start, end, workQueueIds, teamIds, dataProvider }: ConfigurableChartProps) => {
    // TODO: Have error state for retry
    const [ chartData, setChartData ] = useState<ChartData | null>(null);
    const [ loading, setLoading ] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        let cancellablePromise: CancellablePromise | null = null;
        const fetchData = async () => {
            setChartData(null);
            try {
                const token = await getAccessTokenSilently();
                setLoading(true);
                cancellablePromise = dataProvider(metric, grouping, start, end, workQueueIds, teamIds, token);
                cancellablePromise.promise.then(body => {
                    setLoading(false);
                    if (body.errors) {
                        console.error(body.errors[0].message);
                    } else {
                        setChartData(body.data.cumulativeWorkItemQuery);
                    };
                });
            } catch {
                // TODO: Toast?
                setLoading(false);
                console.error('CCC: Loading chart data failed.');
            }
        }

        fetchData();
        return () => cancellablePromise?.abortController.abort();

    }, [ metric, grouping, start, end, workQueueIds, teamIds, dataProvider ])

    // const chartProperties = useMemo(() => {
    //     try {
    //         const datasets = demoData[metric][grouping];
    //         if (!datasets) {
    //             return null;
    //         }

    //         let title = `${metric} Work Items`;
    //         if ([ 'Active', 'Overdue' ].includes(metric)) {
    //             title += ' (cumulative)'
    //         }

    //         if (grouping !== 'None') {
    //             title += ` by ${grouping}`
    //         }

    //         title += ` for the Last ${numDays} Days`;

    //         return {
    //             chartData: {
    //                 title: title,
    //                 dataSets: datasets.map(ds => ({ ...ds, data: ds.data.slice(-numDays) })),
    //                 labels: last30DaysLabels.slice(-numDays),
    //                 identifiers: last30DaysIdentifiers.slice(-numDays)
    //             } as ChartData
    //         }
    //     } catch (error) {
    //         return null;
    //     }
    // }, [ metric, grouping, numDays ]);

    return <Box style={{ height:'0px', minHeight:'350px', flexGrow:1, alignItems:'center', justifyContent:'center', flexDirection:'row', display:'flex'  }}>
        { loading ? <CircularProgress /> :
            chartData === null ? <Typography>No data to display</Typography> :
                <DciChart
                    chartData={chartData}
                    chartOptions={{
                        type:'line'
                    }}
                />
        }
    </Box>
}

interface ConfigurableChartCardProps {
    dataProvider: (metric: string, grouping: string, start: Date, end: Date, workQueueIds: number[], teamIds: number[], token: string) => CancellablePromise,
    workQueueIds: number[],
    teamIds: number[],
    disableOverdue: boolean
}

const ConfigurableChartCard = ({ dataProvider, workQueueIds, teamIds, disableOverdue = false }: ConfigurableChartCardProps) => {
    const [ metric, setMetric ] = useState<string>('Active');
    const [ grouping, setGrouping ] = useState<string>('None');
    const [ numDays, setNumDays ] = useState(30);

    const metricSelectOnClick = (value: string) => {
        setMetric(value);
        if (value !== 'Active' && grouping === 'Overdue Status') {
            setGrouping('None');
        }
    }

    const now = new Date();
    const end = useMemo(() => {
        return parse(format(now, 'yyyy-MM-dd'), 'yyyy-MM-dd', now);
    }, [ now.getDate() ]);

    const start = useMemo(() => {
        return addDays(end, -numDays + 1); // Inclusive of today
    }, [ end, numDays ]);

    return (
        <Stack>
            <Stack direction='row' justifyContent='center'>
                <Select
                    value={metric}
                    onChange={e => metricSelectOnClick(e.target.value)}
                    sx={{
                        width:'175px',
                        marginRight: '5px',
                        '& > .MuiSelect-outlined': { 
                            padding:'2px 10px',
                            fontSize:'14px'
                        }
                    }}>
                    <MenuItem value='Active'>Active</MenuItem>
                    { !disableOverdue ? <MenuItem value='Overdue'>Overdue</MenuItem> : null }
                    <MenuItem value='Added'>Created</MenuItem>
                    <MenuItem value='Resolved'>Resolved</MenuItem>
                </Select>

                <Select
                    value={grouping}
                    onChange={e => setGrouping(e.target.value)}
                    sx={{
                        width:'175px',
                        marginRight: '5px',
                        '& > .MuiSelect-outlined': { 
                            padding:'2px 10px',
                            fontSize:'14px'
                        }
                    }}>
                    <MenuItem value='None'>No Grouping</MenuItem>
                    <MenuItem value='RegulatoryImpactId'>Regulatory Impact</MenuItem>
                    <MenuItem value='RulePriority'>Priority</MenuItem>
                    <MenuItem value='WorkQueue'>Work Queue</MenuItem>
                </Select>

                <Select
                    value={numDays}
                    onChange={e => setNumDays(e.target.value as number)}
                    sx={{
                        width:'175px',
                        marginRight: '5px',
                        '& > .MuiSelect-outlined': { 
                            padding:'2px 10px',
                            fontSize:'14px'
                        }
                    }}>
                    <MenuItem value={5}>5 Days</MenuItem>
                    <MenuItem value={10}>10 Days</MenuItem>
                    <MenuItem value={15}>15 Days</MenuItem>
                    <MenuItem value={20}>20 Days</MenuItem>
                    <MenuItem value={25}>25 Days</MenuItem>
                    <MenuItem value={30}>30 Days</MenuItem>
                </Select>
            </Stack>
            <ConfigurableChart metric={metric} grouping={grouping} start={start} end={end} workQueueIds={workQueueIds} teamIds={teamIds} dataProvider={dataProvider} />
        </Stack>
    )
}

export { ConfigurableChartCard }