import { 
    Typography
} from "@mui/material";
import { FilterControlProps } from "./types";
import { FilterAutocomplete } from "./FilterAutocomplete";
import { FilterUrlValueDeserializer, FilterUrlValueSerializer } from "../Table/UrlRestorer";
import { FILTER_TYPE, FilterToParameterConverter, FilterType, FilterUrlSerializer, registerFilterUrlSerializer, registerParameterConverter } from "./FilterRepository";
import { PARAMETER_TYPE, ParameterType } from "../../GraphQLShared";

interface WorkItemPriority {
    description: string,
    value: number
}

const priorities = [ 1, 2, 3, 4, 5, 0 ].map(value => ({
    description: value > 0 ? `Priority ${value}` : 'Not defined',
    value: value
}))

const getTextValue = (value: WorkItemPriority[]) => value.length === 1 
    ? value[0].description
    : `${value.length} selected`

const PriorityFilter = ({ editMode, value, setValue }: FilterControlProps) => {
    const selectedValues = value as WorkItemPriority[];

    const setAutoCompleteValue = (newValue: WorkItemPriority[]) => {
        setValue(newValue, newValue.length > 0, false);
    }

    if (editMode) {
        return <FilterAutocomplete
            data={priorities}
            getOptionLabel={o => o.description}
            getTextValue={getTextValue}
            isLoading={false}
            isOptionEqualToValue={(o, v) => o.value === v.value}
            selectedOptions={selectedValues}
            setValue={setAutoCompleteValue}
        />
    }

    return (
        <Typography style={{
            padding:'0px 5px',
            fontSize:'14px',
            maxWidth:'150px',
            overflow:'hidden',
            textOverflow:'ellipsis',
            whiteSpace:'nowrap',
            fontStyle:!value || value === '' ? 'italic' : 'inherit'
        }}>
            {`= ${getTextValue(selectedValues)}`}
        </Typography>
    )
}

const PriorityFilterDefaultValue = [] as WorkItemPriority[];

const PriorityFilterUrlValueSerializer: FilterUrlValueSerializer = filterNode => {
    if (!filterNode.valueIsValid) {
        return null;
    }

    return (filterNode.value as WorkItemPriority[]).map(p => p.value).join();
}

const PriorityFilterUrlValueDeserializer: FilterUrlValueDeserializer = async value => {
    const newValues: WorkItemPriority[] = [];
    value.split(',').forEach(v => {
        const nv = parseInt(v);
        if (!isNaN(nv)) {
            const priority = priorities.find(p => p.value === nv);
            if (priority) {
                newValues.push(priority);
            }
        }
    });

    return newValues.length > 0 ? newValues : null;
}

const converter: FilterToParameterConverter = (name: string, value: WorkItemPriority[]) => ({
    name: name,
    type: PARAMETER_TYPE.NUMBER,
    value: value.map(p => p.value)
});

registerParameterConverter(FILTER_TYPE.PRIORITY, converter);

const serializer: FilterUrlSerializer = {
    serialize: (f: WorkItemPriority[]) => f.map(p => p.value).join(),
    deserialize: async s => {
        return s
            .split(',')
            .map(v => parseInt(v))
            .filter(v => !isNaN(v))
            .map<WorkItemPriority>(v => ({
                value: v,
                description: v > 0 ? `Priority ${v}` : 'Not defined'
            }));
    }
}

registerFilterUrlSerializer(FILTER_TYPE.PRIORITY, serializer);

export {
    PriorityFilter,
    PriorityFilterDefaultValue,
    PriorityFilterUrlValueSerializer,
    PriorityFilterUrlValueDeserializer
}