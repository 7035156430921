import { postDciApi } from "../../utils/callDciApi";
import authClient from "../Auth/auth-client";
import { ApiClient } from "./ApiClient";

interface WorkItemStatus {
    workItemStatusId: number,
    description: string,
    currentStatus: boolean
}

const WorkItemStatusApi: ApiClient<WorkItemStatus> = {

    get: async (predicate?: (wis: WorkItemStatus) => boolean) => {
        const token = await authClient.getTokenSilently();
        const apiResponse = await postDciApi('{workItemStatuses{workItemStatusId,description,currentStatus}}', token);

        let result = apiResponse.data.workItemStatuses as WorkItemStatus[];
        if (predicate) {
            result = result.filter(predicate);
        }

        return result;
    }
    
}

export { WorkItemStatusApi }