import { Button, Popover, Card, CardContent, Stack } from "@mui/material";
import { useRef, useState } from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";

interface ColourPickerProps {
    colour: string
    setColour: (colour: string) => void
}

const ColourPicker = ({ colour, setColour }: ColourPickerProps) => {
    const colourButtonRef = useRef<HTMLButtonElement>(null);
    const [ open, setOpen ] = useState(false);
    const [ tempColour, setTempColour ] = useState(colour);

    const onCancel = () => {
        setOpen(false);
    }

    const onOk = () => {
        setOpen(false);
        setColour(tempColour);
    }

    const onOpen = () => {
        setOpen(true);
        setTempColour(colour);
    }

    return <>
        <Button 
            ref={colourButtonRef}
            style={{ 
                backgroundColor:colour,
                height:'40px',
                width:'40px',
                minWidth:'40px',
                padding:'0px',
                marginLeft:'10px'
            }}
            onClick={onOpen} />
        <Popover open={open} anchorEl={colourButtonRef.current}>
            <Card>
                <CardContent>
                    <HexColorPicker color={tempColour} onChange={setTempColour} />
                    <HexColorInput
                         color={tempColour}
                         onChange={setTempColour}
                         prefixed
                         style={{ 
                            display:'block',
                            margin:'10px 0px',
                            width:'100%'
                        }} />

                    <Stack direction='row-reverse'>
                        <Button color='primary' onClick={onOk}>OK</Button>
                        <Button onClick={onCancel}>Cancel</Button>
                    </Stack>
                </CardContent>
            </Card>
        </Popover>
    </>
}

const getRandomColourChannel = () => {
    const c = Math.floor(Math.random() * 256).toString(16);
    if (c.length === 2) {
        return c;
    }

    return '0' + c;
}

const getRandomHexColour = () => {
    return `#${getRandomColourChannel()}${getRandomColourChannel()}${getRandomColourChannel()}`;
}

export { ColourPicker, getRandomColourChannel, getRandomHexColour }