import { TableCell } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import dciPaths from '../../utils/dciPaths';
import { TableContainer } from '../Table/TableContainer';
import { Table } from '../Table/Table';
import { TableColumn } from '../Filtering/types';
import { useState } from 'react';
import { TableStoreContext, createTableStore } from '../Table/Stores/TableStore';

type ReportStatisticsAggregation = {
    reportingRunHistoryId: number,
    runDatetime: Date,
    totalWorkItemsCreated: number
}

const columns: TableColumn[] = [
    {
        displayName: 'ID',
        selector: 'reportingRunHistoryId'
    },
    {
        displayName: 'Run Date',
        sortable: 'runDatetime',
        selector: 'NOT USED',
        render: row => <TableCell key='runDatetime'>{format(row.runDatetime, 'yyyy-MM-dd HH:mm:ss')}</TableCell>,
    },
    {
        displayName: 'Work Items Created',
        selector: 'totalWorkItemsCreated'
    }
];

const mappingFunction = (row: any): ReportStatisticsAggregation => ({ ...row, runDatetime: parseISO(row.runDatetime) });

const createStore = () => createTableStore({
        graphQLQueryName: 'reportStatisticsAggregations',
        graphQLQueryColumns: '{reportingRunHistoryId,runDatetime,totalWorkItemsCreated}',
        uniqueSortColumn: 'reportingRunHistoryId',
        idFromRow: row => row.reportingRunHistoryId
    },
    {
        paged: true,
        mappingFunction: mappingFunction
    });

const ReportStatisticsAggregationList = () => {
    const navigate = useNavigate();
    const [ store ] = useState(() => createStore());

    const onRowClick = (row: ReportStatisticsAggregation) => navigate(dciPaths.reportingRun.buildLink(row.reportingRunHistoryId));

    return (
        <TableStoreContext.Provider value={store}>
            <TableContainer
                title='Reporting Run History'
                paged
                style={{ height:'100%', width:'100%' }}
                enableExport
            >
                <Table
                    uniqueSortColumn='reportingRunHistoryId'
                    columns={columns}
                    idFromValue={row => row.reportingRunHistoryId}
                    onRowClick={onRowClick}
                />
            </TableContainer>
        </TableStoreContext.Provider>
    )
};

export { ReportStatisticsAggregationList }