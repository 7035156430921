import { useState } from 'react';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import callDciApi from '../../utils/callDciApi';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@mui/material';
import { format, isValid, startOfToday } from 'date-fns';
import { CheckboxField } from '../DciControls';
import { DatePicker } from '@mui/x-date-pickers';

const buildQuery = (workItemIds: number[], noteContent: string, userOverrideUntil: boolean, overrideUntil: Date | null) => {
    const noteParameter = noteContent === '' ? '' : `,noteContent:"""${noteContent}"""`;
    const dateParameter = userOverrideUntil && overrideUntil ? `,overrideUntil:"${format(overrideUntil, 'yyyy-MM-dd')}"` : '';

    return `mutation{requestOverride(workItemIds:[${workItemIds.join()}]${noteParameter}${dateParameter}){result,messages}}`;
};

type ActionResponse = {
    result: 'SUCCESS' | 'FAILURE' | 'WARNINGS',
    messages: string[]
};

type OverrideRequestWorkItemActionDialogProps = {
    workItemIds: number[],
    isOpen: boolean,
    close: (refresh: boolean) => void
};

const OverrideRequestWorkItemActionDialog = ({ workItemIds, isOpen, close }: OverrideRequestWorkItemActionDialogProps) => {
    const minDate = startOfToday();
    const { getAccessTokenSilently } = useAuth0();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [ noteContent, setNoteContent ] = useState('');
    const [ overrideUntilDateCheck, setOverrideUntilDateCheck ] = useState(false);
    const [ overrideUntilDate, setOverrideUntilDate ] = useState<Date | null>(minDate);
    const [ datePickerIsOpen, setDatePickerIsOpen ] = useState(false)
    
    const onSave = async () => {
        const token = await getAccessTokenSilently();
        const query = buildQuery(workItemIds, noteContent, overrideUntilDateCheck, overrideUntilDate);
        
        callDciApi(query, token)
        .then(body => {
            if (!body.errors) {
                var successBody = body.data.requestOverride as ActionResponse;

                if (successBody.messages.length > 0) {
                    alert(successBody.messages.join('\n'));
                }

                // Close the dialog - refresh if there were any successes
                close(successBody.result === 'SUCCESS' || successBody.result === 'WARNINGS');
            }
        });
    }

    const cancel = () => {
        setNoteContent('');
        setOverrideUntilDateCheck(false);
        setOverrideUntilDate(minDate);
        close(false);
    }

    return (
        <Dialog open={isOpen} fullScreen={fullScreen} maxWidth={'sm'} fullWidth aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Request Override</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Optionally enter an explanatory note
                </DialogContentText>

                <CheckboxField
                    displayName='Override until specific date'
                    value={overrideUntilDateCheck}
                    id='override-until-check'
                    onChange={(_, checked) => setOverrideUntilDateCheck(checked)}
                />

                { overrideUntilDateCheck &&
                    <DatePicker
                        format='dd/MM/yyyy'
                        minDate={minDate}
                        views={['year', 'month', 'day']}
                        value={overrideUntilDate}
                        disablePast
                        onChange={date => { setOverrideUntilDate(date) }}
                        open={datePickerIsOpen}
                        onOpen={() => setDatePickerIsOpen(true)}
                        onClose={() => setDatePickerIsOpen(false)}
                        slotProps={{ 
                            textField: {
                                sx: {
                                    paddingTop: '6px',
                                    '& input': {
                                        padding: '3px 10px'
                                    }
                                } 
                            } 
                        }}
                    />
                }
                
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Type your note here"
                    type="text"
                    fullWidth
                    multiline
                    rows={8}
                    variant={'outlined'}
                    value={noteContent}
                    onChange={(event) => setNoteContent(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => cancel()}>Cancel</Button>
                <Button disabled={ overrideUntilDateCheck && (overrideUntilDate === null || !isValid(overrideUntilDate) || overrideUntilDate < minDate) } onClick={onSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export { OverrideRequestWorkItemActionDialog };