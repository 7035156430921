import { useEffect, useState } from 'react';
import OrgContainer from './OrgContainer';
import {
    Box,
    Button,
    Card, 
    CardContent,
    Grid,
    Input,
    Typography
} from '@mui/material';
import { 
    Edit,
    Save
} from '@mui/icons-material';
import { ControlButton, FieldCaption } from '../DciControls';
import { useAuth0 } from '@auth0/auth0-react';
import callDciApi from '../../utils/callDciApi';
import userHasPermission from '../../utils/userHasPermission';
import { permissions } from '../../utils/dciConstants';
import { AuditItemsByEntity } from '../AuditItemsByEntity';
import { useStore } from 'zustand';
import { appGlobalStore } from '../../AppGlobalStore';

type OrganisationConstant = {
    systemConstantId : number,
    value: string,
    description: string | null
}

interface IndexedObject {
    [key: string]: string
}

export default function OrgConstants() {
    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const [ data, setData ] = useState<OrganisationConstant[]>([]);
    const [ loadData, setLoadData ] = useState(0);
    const [ editMode, setEditMode ] = useState(false);
    const [ amendedItem, setAmendedItem ] = useState<IndexedObject>({});
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        async function fetchData() {
            const token = await getAccessTokenSilently();
            callDciApi(`{organisationConstants{systemConstantId,value,description}}`, token)
            .then(body => {
                if (body.errors) {
                    alert(body.errors[0].message);
                } else {
                    setData(body.data.organisationConstants);
                    setAmendedItem({});
                };
            });
        }

        fetchData();
    }, [ loadData ]);

    const hasEditPermission = userHasPermission(currentUser, permissions.EDIT_SYSTEM_SETTINGS);

    const getValue = (systemConstantId: number) => amendedItem.hasOwnProperty(systemConstantId) ? amendedItem[systemConstantId] : data.find(x => x.systemConstantId === systemConstantId)?.value;

    const cancelChanges = () => {
        setAmendedItem({});
        setEditMode(false);
    };

    const changesMade = () => {
        const keys = Object.keys(amendedItem);
        return keys.length > 0 && keys.some(key => {
            return amendedItem[key] !== data.find(y => y.systemConstantId.toString() === key)?.value;
        });
    };

    const saveChanges = async () => {
        const changesToApply: OrganisationConstant[] = [];
        Object.keys(amendedItem).forEach(element => {
            const existing = data.find(x => x.systemConstantId.toString() === element);
            if (existing && amendedItem[element] !== existing.value) {
                changesToApply.push({
                    ...existing,
                    value: amendedItem[element]
                });
            }
        });

        if (changesToApply.length > 0) {
            const token = await getAccessTokenSilently();
            callDciApi(`mutation{editOrganisationConstants(newSettings:[${changesToApply.map(m => `{systemConstantId:${m.systemConstantId},value:"${m.value}"}`)}])}`, token)
            .then(body => {
                if (!body.errors) {
                    setLoadData(x => x + 1);
                    setEditMode(false);
                } else {
                    alert(body.errors[0].message);
                }
            });
        }
    };

    return (
        <OrgContainer>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Box style={{ float:'right' }}>
                                {hasEditPermission && !editMode && <Button startIcon={<Edit />} onClick={() => setEditMode(true)}>Edit</Button>}
                            </Box>
                            <Typography variant='h5' paragraph>Organisation Constants</Typography>
                            { data.map((m, i) => 
                                <div key={i} style={{ marginBottom:10 }}>
                                    <FieldCaption caption={m.description ?? 'No description'} />
                                    { editMode
                                        ? <Input
                                            style={{ width:'300px' }}
                                            id={`systemConstantId-${m.systemConstantId}`}
                                            value={getValue(m.systemConstantId)}
                                            onChange={ event => { const newValue = event.target.value; setAmendedItem(item => ({ ...item, [m.systemConstantId]: newValue })) }}
                                        />
                                        : <Typography variant="body2">{getValue(m.systemConstantId)}</Typography>}
                                </div>) 
                            }
                            { editMode &&
                                <>
                                    <ControlButton onClick={saveChanges} disabled={!changesMade()} startIcon={<Save />} variant='contained'>Save</ControlButton>
                                    <ControlButton onClick={cancelChanges} variant='contained'>Cancel</ControlButton>
                                </>
                            }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <AuditItemsByEntity type='OrganisationConstant' />
                </Grid>
            </Grid>
        </OrgContainer>
    )
}