import React, { useState } from 'react';
import { ListItemLink } from './ListItemLink';
import {
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import {
    ExpandLess,
    ExpandMore
} from '@mui/icons-material';

import { useLocation } from 'react-router-dom';
import userHasPermission from '../utils/userHasPermission';
import { MenuItem } from './Layout/MenuLayout';
import { useStore } from 'zustand';
import { appGlobalStore } from '../AppGlobalStore';

type DciMenuItemWithSubItemsProps = {
    item: MenuItem,
    nestingLevel: number
}

const DciMenuItemWithSubItems = ({ item, nestingLevel }: DciMenuItemWithSubItemsProps) => {
    const [ open, setOpen ] = useState(true);
    const location = useLocation();
    
    const handleClick = () => {
        setOpen(!open);
    };
    
    return (
        <>
            <ListItem style={ nestingLevel > 0 ? { paddingLeft: nestingLevel * 16 + 16 } : undefined } button onClick={handleClick}>
                {item.icon && <ListItemIcon>{subItemSelected(item, location.pathname) ? React.cloneElement(item.icon, { color: 'secondary' }) : item.icon}</ListItemIcon>}
                <ListItemText primary={item.label} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            { item.subItems &&
            <Collapse in={open} timeout="auto" unmountOnExit>
                <DciMenu items={item.subItems} nestingLevel={nestingLevel + 1} />
            </Collapse> }
        </>
    );
};

const subItemSelected = (item: MenuItem, path: string): boolean => {
    return item.highlightPath ? path.startsWith(item.highlightPath) : false;
};

type DciMenuProps = {
    items: MenuItem[],
    nestingLevel: number
}

const DciMenu = ({ items, nestingLevel = 0 }: DciMenuProps) => {
    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const location = useLocation();
    const currentLevel = nestingLevel;

    const component = currentLevel === 0 ? 'nav' : 'div';
    const disablePadding = currentLevel === 0 ? false : true;

    const DciMenuItems = ({ items, nestingLevel }: DciMenuProps) => {
        return <>
            { items.map(n => (
                (!n.permissions || n.permissions.findIndex(x => userHasPermission(currentUser, x)) !== -1) &&

                <React.Fragment key={n.label}>
                    {(n.subItems && n.subItems.length > 0)
                    ? <DciMenuItemWithSubItems item={n} nestingLevel={nestingLevel} />
                    // TODO: Understand why subItemSelected={n.icon !== undefined && subItemSelected(n)} which was subItemSelected={n.icon && subItemSelected(n)} ???
                    : <ListItemLink preNavigateAction={n.preNavigateAction} nestingLevel={nestingLevel} to={n.linkTo!} primary={n.label} icon={n.icon} selected={n.selected} subItemSelected={n.icon !== undefined && subItemSelected(n, location.pathname)} />}
                </React.Fragment>
            )) }
        </>;
    };

    return (
        <List style={{ overflowY:'auto' }} component={component} disablePadding={disablePadding}>
            <DciMenuItems items={items} nestingLevel={currentLevel} />
        </List>
    );
};

export { DciMenu };