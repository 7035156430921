import { SingleItem } from './SingleItem';
import { ItemDisplay, Field } from './ItemDisplay';
import { parseJSON } from 'date-fns';
import {
    Chip,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import DciLink from './DciLink';
import { useNavigate } from 'react-router-dom';
import dciPaths from '../utils/dciPaths';

const valueDisplay = (value: any) => {
    if (value === null) {
        return '[Empty]';
    } else if (typeof(value) === 'boolean') {
        return value === true ? 'True' : 'False';
    }

    return value.toString();
};
    
type FieldsProps = {
    fields: AuditField[]
}
const Fields = ({ fields }: FieldsProps) => {
    return (
        fields.length > 0 ?
        <>
            <Typography variant='caption' display='block'>Changed Fields</Typography>
            <div style={{ marginTop:3, marginBottom:10, border:'2px black solid' }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Field</TableCell>
                            <TableCell>Old Value</TableCell>
                            <TableCell>New Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((m, i) =>
                            <TableRow key={i}>
                                <TableCell>{m.FieldName}</TableCell>
                                <TableCell>{valueDisplay(m.OldValue)}</TableCell>
                                <TableCell>{valueDisplay(m.NewValue)}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
        </> : null
    )
};

type ChipListProps = {
    items: ChipListItem[] | null,
    fieldName: string,
    linkGenerator?: (...args: any[]) => string
}

type ChipListItem = {
    id: number,
    name: string
}

const ChipList = ({ items, fieldName, linkGenerator }: ChipListProps) => {
    const navigate = useNavigate();

    return (
        items && items.length > 0 
            ?   <>
                    <Typography variant='caption' display='block'>{fieldName}</Typography>
                    <div style={{ marginBottom:10 }}>
                        { items.map((m, i) => <Chip key={i} size='small' style={{ margin:2 }} onClick={ linkGenerator ? () => navigate(linkGenerator(m.id)) : undefined } label={m.name} />) }
                    </div>
                </>
            : null
    );
}

type AuditItemProps = {
    id: number
}

type RelatedItem = {
    id: number,
    name: string
}

type AuditItem = {
    auditId: number,
    RolesAdded: RelatedItem[] | null,
    RolesRemoved: RelatedItem[] | null,
    PermissionsAdded: RelatedItem[] | null,
    PermissionsRemoved: RelatedItem[] | null,
    ModifiedAt: Date,
    ModifiedById: number,
    ModifiedByName: string
    Message: string,
    Fields: AuditField[]
}

type AuditField = {
    FieldName: string,
    OldValue: any,
    NewValue: any
}

const AuditItemView = ({ id }: AuditItemProps) => {
    const displayFields: Field<AuditItem>[] = [
        {
            render: item => <div style={{ marginBottom:10 }}><Typography display='inline' variant='h5' paragraph>{item.Message}</Typography></div>
        },
        { selector:'ModifiedAt', valueResolver:item => `${item.ModifiedAt.toLocaleDateString()} ${item.ModifiedAt.toLocaleTimeString()}` },
        { selector:'ModifiedBy', valueResolver:item => <DciLink href={dciPaths.user.buildLink(item.ModifiedById)}>{item.ModifiedByName}</DciLink> },
        { selector:'OperationName' },
        { render:item => <Fields fields={item.Fields} /> },
        { render:item => <ChipList items={item.RolesAdded} fieldName='Roles Added' linkGenerator={dciPaths.role.buildLink} /> },
        { render:item => <ChipList items={item.RolesRemoved} fieldName='Roles Removed' linkGenerator={dciPaths.role.buildLink} /> },

        // TODO: Maybe add permission links later if we have any
        { render:item => <ChipList items={item.PermissionsAdded} fieldName='Permissions Added' /> },
        { render:item => <ChipList items={item.PermissionsRemoved} fieldName='Permissions Removed' /> }
    ];

    const mappingFunction = (item: any): AuditItem => {
        var mappedItem = {
            auditId: id,
            ...JSON.parse(item.auditContent)
        };
        
        mappedItem.ModifiedAt = parseJSON(mappedItem.ModifiedAt);
        return mappedItem;
    };

    return (
        <SingleItem<AuditItem>
            queryName='auditItemById'
            queryColumns='{auditContent}'
            queryParameters={`id:${id}`}
            mappingFunction={mappingFunction}
            ItemComponent={({ item }) => <ItemDisplay item={item} fields={displayFields} />}
        />
    )
}

export { AuditItemView }