import {
    Typography
} from '@mui/material';
import ScreenCapture from './Images/improvedfilters.png';

const WNImprovedFilters = () => {
    return (
        <>
            <Typography variant='h5'>Improved Filters</Typography>
            <Typography variant='body1'>The available filters for the Work Items views have been improved.</Typography>
            <img style={{ display:'block', height:'300px', margin:'20px auto' }} src={ScreenCapture} alt="Improved Filters" />
            <Typography variant='body1'>
                The new filters make it much easier to find the values you are looking for by presenting more information
                on the selected values, such as the rule description or the regulatory impact RAG colour. In addition, the
                filters allow you to select multiple values. New filter types have also been included such as Status and
                Processing User, with more to be added shortly.
            </Typography>
        </>
    )
}

export { WNImprovedFilters }