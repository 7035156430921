import {
    styled,
    Typography
} from '@mui/material';

type RowId = string | number;

interface ColumnSort {
    column: string,
    direction?: 'ASC' | 'DESC'
}

type GlobalSelectAllState = 'UNAVAILABLE' | 'AVAILABLE' | 'LOADING' | 'ERROR' | 'ACTIVE';

const FilterTypography = styled(Typography)`
    font-size: 14px;
    padding: 2px 6px;
`;

interface AsyncData<T = any> {
    isFetching: boolean,
    isCancelled: boolean,
    isError: boolean,
    data: T | null
}

export { FilterTypography }
export type { AsyncData, ColumnSort, GlobalSelectAllState, RowId }