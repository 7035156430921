import React, { useEffect, useState } from 'react';
import { callDciApiCancellable, CancellablePromise } from '../../utils/callDciApi';
import { useAuth0 } from '@auth0/auth0-react';
import { WithChildren } from '../../types/WithChildren';
import { CoreKpiDataContext, CoreKpiValues } from './CoreKpiDataContext';
import { format } from 'date-fns/esm';

type CoreKpiDataProviderProps = WithChildren<{
    start: Date,
    end: Date,
    workQueueIds: number[],
    teamIds: number[],
}>;

const CoreKpisDataProvider = ({ start, end, children, workQueueIds, teamIds }: CoreKpiDataProviderProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const [ data, setData ] = useState<CoreKpiValues | null>(null);
    const [ loading, setLoading ] = useState(true);
    
    useEffect(() => {
        let mounted = true;
        let cancellablePromise: CancellablePromise | null = null;
        async function fetchData() {
            const token = await getAccessTokenSilently();
            
            if (mounted) {
                setLoading(true);
                cancellablePromise = callDciApiCancellable(`{coreKpis(workQueueIds:[${workQueueIds.join(',')}],teamIds:[${teamIds.join(',')}],start:"${format(start, 'yyyy-MM-dd')}",end:"${format(end, 'yyyy-MM-dd')}"){numberOfRules{value,unit},numberOfDataChecks{value,unit},averageCreated{value,unit},averageCreatedRedRegImpact{value,unit},averageCreatedPriority1{value,unit},averageClosed{value,unit},averageClosedRedRegImpact{value,unit},averageClosedPriority1{value,unit},numOverrides{value,unit}}}`, token);
                cancellablePromise.promise
                .then(body => {
                    setLoading(false);
                    if (body.errors) {
                        console.error(body.errors[0].message);
                    } else {
                        setData(body.data.coreKpis);
                    };
                });
            }
        }

        fetchData();
        return () => {
            mounted = false;
            cancellablePromise?.abortController.abort();
        }
    }, [ start, end, workQueueIds.length, teamIds.length ]);

    return loading
      ? <div style={{ height:'100%', display:'flex', justifyContent:'center', alignItems:'center' }}>
            <p>Loading...</p>
        </div>

      : <CoreKpiDataContext.Provider value={data}>
            { children }
        </CoreKpiDataContext.Provider>
};

export { CoreKpisDataProvider };