import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import OrgContainer from './OrgContainer';
import { SingleItem } from '../SingleItem';
import ItemEdit from '../ItemEdit';
import callDciApi from '../../utils/callDciApi';
import dciPaths from '../../utils/dciPaths';
import { useStore } from 'zustand';
import { appGlobalStore } from '../../AppGlobalStore';

type Organisation = {
    name: string,
    description: string,
    country: string | null
    countryIsoCode: string,
}

const EditOrganisation = () => {
    const [ saveErrors, setSaveErrors ] = useState([]);
    const { getAccessTokenSilently } = useAuth0();
    const updateUser = useStore(appGlobalStore, s => s.updateUser);
    const navigate = useNavigate();

    const mappingFunction = (item: any): Organisation => ({
        name: item.organisationName,
        description: item.description,
        country: item.country.description,
        countryIsoCode: item.country.countryIsoCode
    });

    const fields = [
        { displayName:'Name', selector:'name', type:'text' },
        { displayName:'Description', selector:'description', type:'text' },
    ]

    const onSave = async (item: Organisation) => {
        const token = await getAccessTokenSilently();
        callDciApi(`mutation{editOrganisation(name:"${item.name}",description:"${item.description}",countryIsoCode:"${item.countryIsoCode}"){organisationId}}`, token)
        .then(body => {
            if (!body.errors) {
                updateUser({ organisationName: item.name });
                navigate(dciPaths.organisation.buildLink());
            }
            else {
                setSaveErrors(body.errors);
            }
        })
        .catch(error => {
            console.error(`[EditOrganisation] editOrganisation: ${error}`);
        });
    };

    return (
        <OrgContainer>
            <SingleItem<Organisation>
                queryName='currentOrganisation'
                queryColumns='{country{countryIsoCode,description}description,organisationName}'
                mappingFunction={mappingFunction}
                ItemComponent={({ item }) => <ItemEdit item={item} fields={fields} onSave={onSave} saveErrors={saveErrors} />}
            />
        </OrgContainer>
    );
}

export { EditOrganisation }