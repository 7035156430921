import React, { useState, useEffect, useMemo } from 'react';

import { AuditItemsByEntity } from '../AuditItemsByEntity';
import OrgContainer from './OrgContainer';
import callDciApi, { callDciApiCancellable } from '../../utils/callDciApi';
import { useAuth0 } from '@auth0/auth0-react';

import type { CancellablePromise } from '../../utils/callDciApi';
import { 
    Autocomplete, 
    Box, 
    Button, 
    Card, 
    CardContent, 
    Chip, 
    Paper, 
    Toolbar, 
    Typography, 
    TableCell, TableSortLabel, TableRow, TableHead, TableBody, TableContainer, Table, TextField } from '@mui/material';
import { PageTitle, TextProperty, FieldCaption, CheckboxField, ControlButton } from '../DciControls';
import AddEditUserDialog from '../AddEditUserDialog';
import type { Team as ApiTeam } from '../../utils/DciApiTypes';
import { Edit, Save } from '@mui/icons-material';
import userHasPermission from '../../utils/userHasPermission';
import { permissions } from '../../utils/dciConstants';
import { SegmentMap } from '../Layout/DciBreadcrumb';
import DciLink from '../DciLink';
import dciPaths from '../../utils/dciPaths';
import { ReportTypes } from '../../utils/ReportTypes';
import { encodeURI } from 'js-base64';
import { useStore } from 'zustand';
import { appGlobalStore } from '../../AppGlobalStore';
import { PARAMETER_TYPE, QueryParameter } from '../../GraphQLShared';

type Team = {
    name: string,
    description: string,
    enabled: boolean,
    users: User[],
    workQueues: WorkQueue[]
};

type ModifiedTeam = {
    name: string | undefined,
    desciption: string | undefined,
    enabled: boolean | undefined
};

type User = {
    emailAddress: string,
    firstName: string, 
    mobileTelephone: string,
    roles: Role[],
    surname: string, 
    userId: number, 
    workTelephone: string
};

type Role = {
    description: string,
    roleId: number
};

type WorkQueue = {
    description: string,
    enabled: boolean,
    name: string,
    workQueueId: number
};

type TeamViewProps = {
    id: number,
    setBreadcrumbs: React.Dispatch<React.SetStateAction<SegmentMap[]>>
};

const TeamView: React.FC<TeamViewProps> = ({ id, setBreadcrumbs }) => {
    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const { getAccessTokenSilently } = useAuth0();
    const [ team, setTeam ] = useState<Team | null>(null);

    const [ addUserIsOpen, setAddUserIsOpen ] = useState(false);
    const [ editUserId, setEditUserId ] = useState<number | null>(null);
    const [ existingRoleIds, setExistingRoleIds ] = useState<number[]>([]);
    const [ editMode, setEditMode ] = useState<boolean>(false);
    const [ newItem, setNewItem ] = useState<any>({});

    const [ workQueueListIsOpen, setWorkQueueListIsOpen ] = useState(false);
    const [ workQueuesLoading, setWorkQueuesLoading ] = useState(true);
    const [ workQueues, setWorkQueues ] = useState<WorkQueue[]>([])

    const [ dummyRefresh, setDummyRefresh ] = useState<boolean>(false);

    const hasEditPermission = userHasPermission(currentUser, permissions.EDIT_TEAM);

    const close = (refresh: boolean) => {
        setAddUserIsOpen(false);
        setDummyRefresh(!dummyRefresh);
    };

    const openAddEditUserDialog = (editUserId: number | null, existingRoleIds: number[]) => {
        setEditUserId(editUserId);
        setExistingRoleIds(existingRoleIds);
        setAddUserIsOpen(true);
    };

    useEffect(() => {
        let cancelled = false;
        let cancellablePromise: CancellablePromise | null = null;
        const getData = async () => {
            const token = await getAccessTokenSilently();
            if (cancelled) {
                return;
            }

            cancellablePromise = callDciApiCancellable(`{teamById(id:${id}){name,description,enabled,teamWorkQueue{workQueue{enabled,name,description,workQueueId}},userRoleTeam{appUser{userId,firstName,surname,mobileTelephone,workExtension,workTelephone,emailAddress}role{roleId,description}}}}`, token);

            cancellablePromise.promise
            .then(body => {
                if (body.errors) {
                    alert(body.errors[0].message);
                } else {
                    const newTeam = mappingFunction(body.data.teamById);
                    setTeam(newTeam);
                    setBreadcrumbs(oldValue => [ ...oldValue ].map((m, i) => i === oldValue.length - 1 ? { text: newTeam.name } : m));
                }
            });
        };
        
        getData();
        return () => {
            cancelled = true;
            cancellablePromise?.abortController.abort();
        }
    }, [ id, dummyRefresh ]);

    useEffect(() => {
        let cancelled = false;
        if (!workQueuesLoading) {
          return undefined;
        }
    
        let cancellablePromise: CancellablePromise | null = null;

        (async () => {
            const token = await getAccessTokenSilently();
            if (cancelled) {
                return;
            }

            cancellablePromise = callDciApiCancellable('{allWorkQueues{description,enabled,name,workQueueId}}', token);
            cancellablePromise.promise
            .then(body => {
                if (!body.errors) {
                    setWorkQueues(body.data.allWorkQueues);
                    setWorkQueuesLoading(false);
                }
            });
        })();
    
        return () => {
            cancelled = true;
            cancellablePromise?.abortController.abort();
        }
        
    }, [ workQueuesLoading ]);

    const mappingFunction = (item: ApiTeam) => {
        const workTelephone = (item: any) => {
            let result = '';
            if (item.workTelephone) {
                result = item.workTelephone;
                if (item.workExtension) {
                    result += ` (ext. ${item.workExtension})`
                }
            }
            else if (item.workExtension) {
                result = `Ext. ${item.workExtension}`;
            }

            return result;
        };

        const newItem: Team = {
            name: item.name,
            description: item.description,
            enabled: item.enabled,
            users: [],
            workQueues: item.teamWorkQueue.map(m => ({
                description: m.workQueue!.description!,
                name: m.workQueue!.name!,
                enabled: m.workQueue!.enabled!,
                workQueueId: m.workQueue!.workQueueId!
            }))
        };

        item.userRoleTeam.forEach(urt => {
            const user = newItem.users.find(obj => obj.userId === urt!.appUser!.userId);
            if (user !== undefined) {
                // There is an existing user, simply add permission
                user.roles.push({ roleId: urt!.role!.roleId!, description: urt!.role!.description! });
            }
            else {
                // No user exists yet, add it
                newItem.users.push({ 
                    userId: urt!.appUser!.userId!, 
                    firstName: urt!.appUser!.firstName!, 
                    surname: urt!.appUser!.surname!, 
                    roles: [{ roleId: urt!.role!.roleId!, description: urt!.role!.description! }] ,
                    emailAddress: urt!.appUser!.emailAddress!,
                    mobileTelephone: urt!.appUser!.mobileTelephone!,
                    workTelephone: workTelephone(urt.appUser)
                });
            }
        });

        return newItem;
    };

    const beginEdit = () => {
        setNewItem({
            description: team!!.description,
            enabled: team!!.enabled,
            name: team!!.name,
            workQueues: [ ...team!!.workQueues ]
        });

        setEditMode(true);
    };

    const refreshPage = () => {
        setDummyRefresh(!dummyRefresh);
    };

    const changesMade = () => {
        if (newItem.name !== team!!.name) {
            return true;
        }

        if (newItem.description !== team!!.description) {
            return true;
        }

        if (newItem.enabled !== team!!.enabled) {
            return true;
        }

        if (newItem.workQueues.length !== team!!.workQueues.length) {
            return true;
        }

        const ni = newItem.workQueues.map((m: WorkQueue) => m.workQueueId).sort();
        const wq = team!!.workQueues.map(m => m.workQueueId).sort();

        for (let index = 0; index < ni.length; index++) {
            if (ni[index] !== wq[index]) {
                return true;
            }
        }

        return false;
    };

    const cancelChanges = () => {
        setEditMode(false);
    };

    const saveChanges = async () => {
        const token = await getAccessTokenSilently();
        callDciApi(`mutation{editTeam(modifiedEntity:{description:"${newItem.description}",enabled:${newItem.enabled},name:"${newItem.name}",teamId: ${id},workQueueIds:[${newItem.workQueues.map((m: WorkQueue) => m.workQueueId).join(',')}]})}`, token)
        .then(body => {
            if (body.errors) {

            } else {
                setTeam(body.data.team);
                if (body.data.length > 0) {
                    let message = 'Messages\n\n';
                    for (let index = 0; index < body.data.messages.length; index++) {
                        message += `${index}: ${body.data[index]}\n`;
                    }

                    alert(message);
                }

                setEditMode(false);
                refreshPage();
            }
        });
    };

    const activeWorkItemsForTeamUrl = useMemo(() => {
        var reportParameters = {
            title: `Active Work Items for Team: ${team?.name}`,
            parameters: [
                {
                    name: "fixedFilter.workItemStatusId",
                    type: PARAMETER_TYPE.NUMBER,
                    value: [ 1, 2, 3, 4, 6, 8 ]
                },
                {
                    name: "fixedFilter.teamId",
                    type: PARAMETER_TYPE.NUMBER,
                    value: [ id ]
                }
            ] as QueryParameter[]
        };

        const base64 = encodeURI(JSON.stringify(reportParameters));
        return `/workitems?q=${base64}`;
    }, [ id, team?.name ]);

    return (
        <OrgContainer>
            <Card>
                { team &&
                <CardContent>
                    <Box style={{ float:'right' }}>
                        {hasEditPermission && !editMode && <Button startIcon={<Edit />} onClick={beginEdit}>Edit</Button>}
                    </Box>
                    { editMode ?
                        <TextField
                            variant='standard'
                            style={{ marginBottom:'12px' }}
                            margin="dense"
                            label='Name'
                            id='name'
                            type="text"
                            fullWidth
                            value={newItem.name}
                            onChange={(event: any) => setNewItem({ ...newItem, name: event.target.value })}
                        />
                        : <>
                            <Box component="span" style={{ marginRight:'10px' }}>
                                <PageTitle style={{ display:'inline' }} title={`Team: ${team.name}`} />
                            </Box>
                            <Box component="span">
                                <DciLink href={activeWorkItemsForTeamUrl}>View active Work Items for this Team</DciLink>
                            </Box>
                        </> 
                    }
                    { editMode ?
                        <TextField
                            variant='standard'
                            style={{ marginBottom:'12px' }}
                            margin="dense"
                            label='Description'
                            id='description'
                            type="text"
                            fullWidth
                            value={newItem.description}
                            onChange={(event: any) => setNewItem({ ...newItem, description: event.target.value })}
                        />
                        : <TextProperty caption='Description' value={team.description} />
                    }
                    { editMode ?
                        <CheckboxField
                            displayName='Enabled'
                            value={newItem.enabled}
                            id='enabled'
                            disabled={false}
                            onChange={e => setNewItem({ ...newItem, enabled: e.target.checked })}
                        />
                        : <TextProperty caption='Enabled' value={team.enabled ? 'Yes' : 'No'} />
                    }
                    { editMode ?
                        <Autocomplete
                            multiple
                            id='enabled'
                            size='small'

                            open={workQueueListIsOpen}
                            onOpen={() => setWorkQueueListIsOpen(true)}
                            onClose={() => setWorkQueueListIsOpen(false)}

                            value={newItem.workQueues}
                            isOptionEqualToValue={(option, value) => option.workQueueId === value.workQueueId}
                            onChange={(_event, value, _reason) => setNewItem({ ...newItem, workQueues: value })}

                            options={workQueues}
                            getOptionLabel={option => option.name}
                            renderInput={(params) => (
                                <TextField {...params} variant="standard" label="Work Queues" />
                            )}
                        /> : 
                        <>
                            <FieldCaption caption='Work Queues' />
                            <Box>
                                { team.workQueues.map(m => <Chip style={{ marginRight:'2px', marginTop:'2px' }} key={m.workQueueId} size='small' label={m.name} />) }
                            </Box>
                        </>
                    }
                    { editMode &&
                        <>
                            <ControlButton  onClick={saveChanges} disabled={!changesMade()} startIcon={<Save />} variant='contained'>Save</ControlButton>
                            <ControlButton onClick={cancelChanges} variant='contained'>Cancel</ControlButton>
                        </>
                    }
                    
                </CardContent>}
            </Card>
            { team &&
            <Paper style={{ marginTop:'10px' }}>
                <Toolbar>
                    <Typography sx={{ flexGrow:1 }} variant='h6'>Members</Typography>
                    { hasEditPermission && <Button variant='contained' onClick={() => openAddEditUserDialog(null, [])}>Add User</Button> }
                    <AddEditUserDialog teamId={id} editUserId={editUserId} existingRoleIds={existingRoleIds} isOpen={addUserIsOpen} close={close} readOnly={!hasEditPermission} />
                </Toolbar>
                <TableContainer>
                    <Table style={{tableLayout: 'auto'}}>
                        <colgroup>
                            <col style={{ width:'200px' }} />
                            <col style={{ width:'450px' }} />
                            <col style={{ width:'250px' }} />
                            <col style={{ width:'200px' }} />
                            <col style={{ width:'200px' }} />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel 
                                        active={true} 
                                        direction={'asc'}>
                                        {/* onClick={e => onSortClick(typeof(n.sort) === 'string' ? n.sort : n.selector, e)}> */}
                                            Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>Roles</TableCell>
                                <TableCell>E-mail</TableCell>
                                <TableCell>Work Telephone</TableCell>
                                <TableCell>Mobile Telephone</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {team.users.map(m => 
                                <TableRow onClick={() => openAddEditUserDialog(m.userId, m.roles.map(n => n.roleId))} key={m.userId} hover style={{ cursor: "pointer" }}>
                                    <TableCell>{m.firstName} {m.surname}</TableCell>
                                    <TableCell padding={'checkbox'}>
                                        <div style={{ padding:'3px' }}>{m.roles.map(n => <Chip style={{ margin:'2px' }} key={n.roleId} label={n.description} size={'small'} />)}</div>
                                    </TableCell>
                                    <TableCell>{m.emailAddress}</TableCell>
                                    <TableCell>{m.workTelephone}</TableCell>
                                    <TableCell>{m.mobileTelephone}</TableCell>
                                </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>}
            <AuditItemsByEntity style={{ marginTop:'10px' }} type='Team' entityKey={[{ fieldName:'TeamId', fieldValue:id }]} dummyRefresh={dummyRefresh} />
        </OrgContainer>
    );
};

export default TeamView;