import React from 'react';
import { 
    Card, 
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import { Flag } from '@mui/icons-material';
import { CoreKpiDataContext } from '../DataProviders/CoreKpiDataContext';

const layout = [
    {
        kpi: "Current number of data checks used",
        level: 0,
        dataSelector:'numberOfRules'
    },
    {
        kpi: "Average data points checked",
        level: 0,
        dataSelector:'numberOfDataChecks'
    },
    {
        kpi: "Average daily work items",
        level: 0,
        dataSelector:'averageCreated'
    },
    {
        kpi: <span>Regulatory Impact<Flag style={{ color:'red', marginBottom:-8 }} /></span>,
        level: 1,
        dataSelector:'averageCreatedRedRegImpact'
    },
    {
        kpi: "Priority 1",
        level: 1,
        dataSelector:'averageCreatedPriority1'
    },
    {
        kpi: "Average time to resolve work items",
        level: 0,
        dataSelector:'averageClosed'
    },
    {
        kpi: <span>Regulatory Impact<Flag style={{ color:'red', marginBottom:-8 }} /></span>,
        level: 1,
        dataSelector:'averageClosedRedRegImpact'
    },
    {
        kpi: "Priority 1",
        level: 1,
        dataSelector:'averageClosedPriority1'
    },
    {
        kpi: "Number of Overrides",
        level: 0,
        dataSelector:'numOverrides'
    },
]

const CoreKpisCard = () => {
    const data = React.useContext(CoreKpiDataContext);

    function hasKey<O>(obj: O, key: keyof any): key is keyof O {
        return key in obj
    }

    if (data === null) {
        return (
            <div style={{ height:'100%', display:'flex', justifyContent:'center', alignItems:'center' }}>
                    <p>No data available</p>
            </div>
        );
    } else {
        return (
            <Card>
                <CardContent>
                    <Typography variant="h6">Core KPIs over the last 30 days</Typography>
                    <Table size="small">
                        <TableBody>
                            {layout.map((n, i) => hasKey(data, n.dataSelector) && data[n.dataSelector] !== null && (
                                <TableRow key={i}>
                                    <TableCell style={{ textIndent: n.level * 20 }}>{n.kpi}</TableCell>
                                    <TableCell style={{ textAlign: "right" }}>{data[n.dataSelector]!.value}</TableCell>
                                    <TableCell>{data[n.dataSelector]!.unit}</TableCell>
                                </TableRow>))}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        );
    }
}

export { CoreKpisCard }