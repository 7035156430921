import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Autocomplete, AutocompleteRenderOptionState, Checkbox, InputBase, Popper, PopperProps } from "@mui/material"
import { FilterTypography } from "../Table/Shared"

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const AutocompletePopper = function (props: PopperProps) {
    return <Popper {...props} style={{ width:'fit-content', minWidth:'250px', maxWidth:'400px' }} placement='bottom-start' />;
};

interface FilterAutocompleteProps<TOption> {
    data?: TOption[],
    getOptionLabel: (option: TOption) => string,
    selectedOptions: TOption[],
    setValue: (value: TOption[]) => void,
    isOptionEqualToValue: (o: TOption, v: TOption) => boolean,
    isLoading: boolean,
    getTextValue: (value: TOption[]) => string,
    renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: TOption, state: AutocompleteRenderOptionState) => React.ReactNode
}

const FilterAutocomplete = <TOption,>({
    data,
    getOptionLabel,
    selectedOptions,
    setValue,
    isOptionEqualToValue,
    isLoading,
    getTextValue,
    renderOption
}: FilterAutocompleteProps<TOption>) => {
    return (
        <Autocomplete
            PopperComponent={AutocompletePopper}
            style={{ 
                minWidth:'200px',
                marginRight:'5px',
                height:'26px'
            }}
            options={data ?? []}
            value={selectedOptions}
            onChange={(_event, value, _reason) => setValue(value)}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            multiple
            disableCloseOnSelect
            loading={isLoading}
            renderInput={({ InputProps, InputLabelProps, ...rest }) => 
                <InputBase
                    { ...InputProps }
                    {...rest}
                    sx={theme => ({ 
                        border:`1px solid ${theme.palette.text.primary}`,
                        marginRight:'5px',
                        height:'26px',
                        '& > input': {
                            padding:'2px 6px',
                            fontSize:'14px'
                        }
                    })}
                />}
            renderOption={renderOption 
                ? renderOption
                : (props, option, state) =>
                    <li style={{ padding:'0px 6px', overflow:'hidden' }} {...props}>
                        <Checkbox
                            size='small'
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={state.selected}
                            color='secondary'
                        />
                        <FilterTypography style={{ overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis' }}>
                            {getOptionLabel(option)}
                        </FilterTypography>
                    </li>
                }
            renderTags={value => <FilterTypography>{getTextValue(value)}</FilterTypography>}
        />
    )
}

export { FilterAutocomplete }