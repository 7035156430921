import React from 'react';
import {
    Box,
    Card,
    CardContent,
    CircularProgress,
    Paper,
    Stack,
    styled,
    Typography
} from '@mui/material';
import { ConditionalLink } from '../ConditionalLink';
import { HeadlineCardDataContext } from '../DataProviders/HeadlineCardDataContext';

const MessageContainer = styled(Box)`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    height:100%;
`;

const MessagePaper = styled(Paper)`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    height:100%;
`;

const HeadlineCard = () => {
    const headlineCardDataContext = React.useContext(HeadlineCardDataContext);

    if (headlineCardDataContext === null) {
        return <MessagePaper>Preparing...</MessagePaper>
    }

    return (
        <Card>
            <CardContent style={{ minHeight:'176px' }}>
                <Stack height='100%'>
                    <Box>
                        <Typography gutterBottom variant="h6" component="h2">
                            {headlineCardDataContext.title}
                        </Typography>
                    </Box>
                    { headlineCardDataContext.fetching ? <MessageContainer flexGrow={1}>Loading...</MessageContainer> :
                        <Box display='flex' alignItems='end' flexGrow={1}>
                            <ConditionalLink link={headlineCardDataContext.link}>
                                <Typography style={{ fontSize: "3rem" }} gutterBottom variant="h6" component="h1" color="secondary">
                                    { headlineCardDataContext.value}
                                </Typography>
                            </ConditionalLink>
                        </Box>
                    }
                </Stack>
            </CardContent>
        </Card>
    );
};

export { HeadlineCard }