import { addDays, format, subDays } from 'date-fns';
import { range } from 'lodash';
import { ChartData, PieChartData } from '../../types';
import { ActiveWorkItemCountByPriorityDataContext } from '../DataProviders/ActiveWorkItemCountByPriorityDataContext';
import { ActiveWorkItemCountByRegulatoryImpactDataContext, WorkItemCountByRegulatoryImpact } from '../DataProviders/ActiveWorkItemCountByRegulatoryImpactDataContext';
import { CoreKpiDataContext, CoreKpiValues } from '../DataProviders/CoreKpiDataContext';
import { DciChartDataContext } from '../DataProviders/DciChartDataContext';
import { HeadlineCardData, HeadlineCardDataContext } from '../DataProviders/HeadlineCardDataContext';
import { PieChartDataContext } from '../DataProviders/PieChartDataContext';

type ComponentWithChildren = {
    children: JSX.Element
};

const AFActiveWorkItemsCardProvider = ({ children }: ComponentWithChildren) => {
    const alternativeFact: HeadlineCardData = {
        title: 'Active Work Items',
        fetching: false,
        value: '356'
    };

    return (
        <HeadlineCardDataContext.Provider value={alternativeFact}>
            { children }
        </HeadlineCardDataContext.Provider>
    )
};

const AFOverdueWorkItemsCardProvider = ({ children }: ComponentWithChildren) => {
    const alternativeFact: HeadlineCardData = {
        title: 'Overdue Work Items',
        fetching: false,
        value: '23'
    };

    return (
        <HeadlineCardDataContext.Provider value={alternativeFact}>
            { children }
        </HeadlineCardDataContext.Provider>
    )
};

const AFActiveWorkItemsByPriorityProvider = ({ children }: ComponentWithChildren) => {
    const fakeData = [ 3, 28, 66, 118, 141 ].map((m, i) => ({
        count: m,
        priority: i + 1,
        label: `Priority ${i + 1}`
    }));

    return (
        <ActiveWorkItemCountByPriorityDataContext.Provider value={{ rows: fakeData, fetching: false }}>
            { children }
        </ActiveWorkItemCountByPriorityDataContext.Provider>
    )
};

const AFActiveWorkItemsByRegulatoryImpactProvider = ({ children }: ComponentWithChildren) => {
    const fakeData = [
        {
            colour: 'red',
            count: 5,
            label: 'Potential reportable breach',
            regulatoryImpactValue: 2
        },
        {
            colour: '#fa9000',
            count: 68,
            label: 'Potential non-reportable breach',
            regulatoryImpactValue: 1
        },
        {
            colour: 'green',
            count: 283,
            label: 'No regulatory impact',
            regulatoryImpactValue: 0
        }
    ] as WorkItemCountByRegulatoryImpact[];

    return (
        <ActiveWorkItemCountByRegulatoryImpactDataContext.Provider value={{ rows: fakeData, fetching: false }}>
            { children }
        </ActiveWorkItemCountByRegulatoryImpactDataContext.Provider>
    )
};

const AFDailyWorkItemsRegulatoryImpactProvider = ({ children }: ComponentWithChildren) => {
    const thirtyDaysAgo = subDays(new Date(), 30);

    const fakeData = {
        title: 'Work Items Created per Day by Regulatory Impact',
        dataSets: [
            {
                colour: 'green',
                groupId: 0,
                label: 'No regulatory impact',
                data: [ 24,5,3,0,55,0,6,44,0,12,2,8,62,0,0,25,25,22,0,0,12,0,3,0,5,18,0,22,5,1 ]
            },
            {
                colour: '#fa9000',
                groupId: 1,
                label: 'Potential non-reportable breach',
                data: [ 0,0,5,0,0,0,0,0,15,0,0,2,3,0,0,17,0,0,0,0,22,0,0,6,0,2,1,6,8,0 ]
            },
            {
                colour: 'red',
                groupId: 2,
                label: 'Potential reportable breach',
                data: [ 0,1,0,0,0,3,0,0,0,0,1,0,0,0,4,0,0,0,0,2,0,0,5,0,0,1,0,1,0,0 ]
            }
        ],
        identifiers: range(1, 31).map(m => format(addDays(thirtyDaysAgo, m), 'yyyy-MM-dd')),
        labels: range(1, 31).map(m => format(addDays(thirtyDaysAgo, m), 'dd/MM/yyyy'))
    } as ChartData;

    return (
        <DciChartDataContext.Provider value={{ data: fakeData, fetching: false }}>
            { children }
        </DciChartDataContext.Provider>
    )
};

const AFDailyWorkItemsPriorityProvider = ({ children }: ComponentWithChildren) => {
    const thirtyDaysAgo = subDays(new Date(), 30);

    const fakeData = {
        title: 'Work Items Created per Day by Priority',
        dataSets: [
            {
                colour: '#2D9BF0',
                groupId: 1,
                label: 'Priority 1',
                data: [ 0,1,0,0,0,2,0,1,0,0,1,0,0,0,4,5,0,1,0,2,2,0,4,0,0,1,0,2,0,0 ]
            },
            {
                colour: '#93C01F',
                groupId: 2,
                label: 'Priority 2',
                data: [ 1,0,4,0,1,1,0,4,4,0,0,2,1,0,0,12,0,1,0,0,0,0,1,0,0,2,0,8,2,0 ]
            },
            {
                colour: '#DA0063',
                groupId: 3,
                label: 'Priority 3',
                data: [ 0,2,0,0,9,0,0,3,1,12,1,0,12,0,0,0,13,3,0,0,17,0,0,3,2,0,0,12,0,0 ]
            },
            {
                colour: '#12CDD4',
                groupId: 4,
                label: 'Priority 4',
                data: [ 8,3,0,0,10,0,6,5,6,0,1,4,0,0,0,18,0,17,0,0,13,0,2,1,0,4,1,0,11,0 ]
            },
            {
                colour: '#652CB3',
                groupId: 5,
                label: 'Priority 5',
                data: [ 15,0,4,0,35,0,0,31,4,0,0,4,52,0,0,7,12,0,0,0,2,0,1,2,3,14,0,7,0,1 ]
            }
        ],
        identifiers: range(1, 31).map(m => format(addDays(thirtyDaysAgo, m), 'yyyy-MM-dd')),
        labels: range(1, 31).map(m => format(addDays(thirtyDaysAgo, m), 'dd/MM/yyyy'))
    } as ChartData;

    return (
        <DciChartDataContext.Provider value={{ data: fakeData, fetching: false }}>
            { children }
        </DciChartDataContext.Provider>
    )
};

const AFCoreKpisProvider  = ({ children }: ComponentWithChildren) => {
    const fakeData = {
        numberOfRules: {
            value: '428',
            unit: 'Rules',
        },
        numberOfDataChecks: {
            value: '63,459',
            unit: 'Checks'
        },
        averageCreated: {
            value: '15',
            unit: 'Work Items'
        },
        averageCreatedRedRegImpact: {
            value: '1',
            unit: 'Work Items'
        },
        averageCreatedPriority1: {
            value: '1',
            unit: 'Work Items'
        },
        averageClosed: {
            value: '4.8',
            unit: 'Days'
        },
        averageClosedRedRegImpact: {
            value: '1.2',
            unit: 'Days'
        },
        averageClosedPriority1: {
            value: '1.7',
            unit: 'Days'
        },
        numOverrides: {
            value: '7',
            unit: 'Overrides'
        }
    } as CoreKpiValues;

    return (
        <CoreKpiDataContext.Provider value={fakeData}>
            { children }
        </CoreKpiDataContext.Provider>
    )
};

const AFTopRulesByVolumeDataProvider = ({ children }: ComponentWithChildren) => {
    const fakeData = {
        title: 'Top 5 Rules by Volume',
        series: [
            {
                colour: '#2D9BF0',
                identifier: 23,
                data: 176,
                label: 'Rule 23'
            },
            {
                colour: '#93C01F',
                identifier: 348,
                data: 111,
                label: 'Rule 348'
            },
            {
                colour: '#DA0063',
                identifier: 16,
                data: 79,
                label: 'Rule 16'
            },
            {
                colour: '#12CDD4',
                identifier: 122,
                data: 56,
                label: 'Rule 122'
            },
            {
                colour: '#652CB3',
                identifier: 402,
                data: 42,
                label: 'Rule 402'
            },
        ]
    } as PieChartData;

    return <PieChartDataContext.Provider value={fakeData}>
        { children }
    </PieChartDataContext.Provider>
}

export {
    AFActiveWorkItemsCardProvider,
    AFOverdueWorkItemsCardProvider,
    AFActiveWorkItemsByPriorityProvider,
    AFActiveWorkItemsByRegulatoryImpactProvider,
    AFDailyWorkItemsPriorityProvider,
    AFDailyWorkItemsRegulatoryImpactProvider,
    AFCoreKpisProvider,
    AFTopRulesByVolumeDataProvider
};