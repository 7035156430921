import {
    Typography
} from '@mui/material';
import ScreenCapture from './Images/darklight.png';

const WNDarkMode = () => {
    return (
        <>
            <Typography variant='h5'>Dark Mode Option</Typography>
            <Typography variant='body1'>
                Like many other applications such as Microsoft Office or Chrome, we now have a dark mode option which can be helpful
                in certain lighting conditions or preferable for some. This can be accessed through the user menu at the top right
                of the screen.
            </Typography>
            <img style={{ display:'block', height:'375px', margin:'20px auto' }} src={ScreenCapture} alt="Dark Mode Option" />
        </>
    )
}

export { WNDarkMode }