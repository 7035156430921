import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { postDciApi } from "../../utils/callDciApi";

interface Team {
    teamId: number,
    name: string
}

const fetchTeams = async (token: string): Promise<Team[]> => {
    const response = await postDciApi('{allTeams{nodes{teamId,name}}}', token);
    if (response.errors) {
        throw new Error(response.errors[0]);
    } else {
        return response.data.allTeams.nodes as Team[];
    }
    // TODO: Error handling
}

const useTeams = () => {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery({
        queryKey: ["teams"],
        queryFn: async () => fetchTeams(await getAccessTokenSilently())
    })
}

export { fetchTeams, useTeams }
export type { Team }