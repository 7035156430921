import {
    Typography
} from '@mui/material';
import ScreenCapture from './Images/WorkQueueView.png';

const WNWorkQueueView = () => {
    return (
        <>
            <Typography variant='h5'>Work Queue View Changes</Typography>
            <Typography variant='body1'>The Work Queue view has been updated to complement this functionality.</Typography>
            <img style={{ display:'block', margin:'20px auto' }} src={ScreenCapture} alt="Add User screen capture" />
            <Typography variant='body1'>Disabled Work Queues are now hidden by default. They can be accessed using the new toggle button at the top of the view.</Typography>
        </>
    )
}

export { WNWorkQueueView }