import { createStore, StoreApi } from 'zustand/vanilla';
import { FilterNode } from '../../Filtering/TableFilter';
import { FilterState, removeFilterNode, setFilterNode } from '../../Filtering/StoreFunctions';
import { AsyncData, ColumnSort, GlobalSelectAllState, RowId } from '../Shared';
import React, { useContext } from 'react';
import { useStore } from 'zustand';
import { persist, PersistStorage } from 'zustand/middleware'
import { clearSelection, getExportCsv, globalSelectAllClick, setGraphQLQueryName, updateRowData } from '../StoreFunctions';
import { QueryParameter } from '../../../GraphQLShared';
import { CancellablePromise } from '../../../utils/callDciApi';

const MAX_ROWS = 5000;

const TableStoreContext = React.createContext<StoreApi<TableState>>(null as any as StoreApi<TableState>);

const useTableState = <TProp,>(selector: (state: TableState) => TProp) => {
    const context = useContext(TableStoreContext);
    return useStore(context, selector);
}

interface RequiredTableState {
    idFromRow: (row: any) => RowId
    uniqueSortColumn: string
    graphQLQueryName: string
    graphQLQueryColumns: string
}

interface TableState extends RequiredTableState, FilterState {
    id: string
    initialised: boolean
    
    sortOrder: ColumnSort[]
    selectionEnabled: boolean
    selectedData: any[]
    rowData: AsyncData
    rowDataCancellablePromise: CancellablePromise | null
    
    globalSelectAllClick: () => void
    globalSelectAllState: GlobalSelectAllState

    toggleSortColumn: (columnName: string, uniqueSortColumn: string) => void
    selectAllClick: () => void
    rowSelectClick: (row: any) => void
    updateRowData: (retainSelection: boolean) => void

    currentPage: number
    pageSize: number
    totalRows: number

    getExportCsv: () => Promise<string>
    clearSelection: () => void
    setCurrentPage: (page: number) => void
    setPageSize: (pageSize: number) => void
    clearFilter: () => void

    fixedParameters: QueryParameter[]
    setFixedParameters: (parameters: QueryParameter[]) => void
    paged: boolean
    mappingFunction: (apiRow: any) => any
    setGraphQLQueryName: (name: string) => void
}

const createTableStore = (
    requiredState: RequiredTableState,
    initialState: Partial<TableState> = {},
    tableStoreName = 'table-store'
) => 
    createStore<TableState>()(
        persist(
            (set, get) => ({
                ...getDefaultTableStateBase(requiredState, set, get),
                ...initialState
            }),
            {
                name: tableStoreName,
                storage: storage,
                partialize: (state) => ({
                    currentPage : state.currentPage,
                    filter: state.filter,
                    globalSelectAllState: state.globalSelectAllState,
                    initialised: state.initialised,
                    pageSize: state.pageSize,
                    rowData: state.rowData,
                    selectedData: state.selectedData,
                    selectionEnabled: state.selectionEnabled,
                    showFilter: state.showFilter,
                    sortOrder: state.sortOrder,
                    totalRows: state.totalRows
                }) as TableState
            }
        )
    );

const applySortImpl = (current: ColumnSort[], column: string, uniqueSortColumn: string): ColumnSort[] => {
    const existing = current.find(s => s.column === column);
    if (existing) {
        return [ { column: column, direction: existing.direction === 'DESC' ? 'ASC' : 'DESC' } ]
    }

    return [ { column: column, direction: 'ASC' } ];
}

type SetterFunction<TState> = (partial: TState | Partial<TState> | ((state: TState) => TState | Partial<TState>), replace?: boolean | undefined) => void

const getDefaultDataElements = () => ({
    initialised: false,
    id: crypto.randomUUID().substring(0, 5),
    rowData: {
        data: [],
        isCancelled: false,
        isError: false,
        isFetching: false
    },
    rowDataCancellablePromise: null,
    selectedData: [],
    globalSelectAllState: 'UNAVAILABLE' as GlobalSelectAllState,
    selectionEnabled: false,
    currentPage: 0,
    pageSize: 100,
    sortOrder: [],
    totalRows: 0,
    filter: [],
    filterColumns: [],
    showFilter: false,
    paged: false,
    mappingFunction: (row: any) => row
});

const getDefaultTableStateBase = (
    requiredTableState: RequiredTableState,
    set: SetterFunction<TableState>,
    get: () => TableState): TableState => 
({
    ...getDefaultDataElements(),
    
    updateRowData: (retainSelection: boolean) => updateRowData(set, get, retainSelection),

    getExportCsv: () => getExportCsv(get),

    selectAllClick: () => {
        const state = get();

        if (state.rowData.isFetching || state.rowData.isError || state.rowData.isCancelled || state.globalSelectAllState === 'LOADING' ) {
            throw new Error(`[selectAllClick] Invalid state, data.isError: ${state.rowData.isError}, data.isFetching:${state.rowData.isFetching}, data.isCancelled:${state.rowData.isCancelled}, globalSelectAllState:${state.globalSelectAllState}`);
        }

        if (state.selectedData.length === state.rowData.data.length || state.globalSelectAllState === 'ACTIVE') {
            set({
                selectedData: [],
                globalSelectAllState: 'UNAVAILABLE'
            });
        } else {
            set({
                selectedData: [ ...state.rowData.data ],
                globalSelectAllState: state.totalRows > state.rowData.data.length ? 'AVAILABLE' : 'UNAVAILABLE'
            });
        }
    },

    globalSelectAllClick: () => globalSelectAllClick(set, get),

    clearSelection: () => clearSelection(set),

    rowSelectClick: (row: any) => {
        const state = get();
        if (state.globalSelectAllState === 'ACTIVE' || state.globalSelectAllState === 'LOADING') {
            throw new Error('[rowSelectClick] Not valid to call this function while globalSelectAllState is ACTIVE or LOADING.');
        }

        const existingIndex = state.selectedData.findIndex(r => r === row);
        if (existingIndex === -1) {
            set({
                selectedData: [ ...state.selectedData, row ],
                globalSelectAllState: state.selectedData.length + 1 === state.pageSize && state.totalRows > state.rowData.data.length ? 'AVAILABLE' : 'UNAVAILABLE'
            });
        } else {
            set({
                selectedData: state.selectedData.filter(r => r !== row),
                globalSelectAllState: 'UNAVAILABLE'
            });
        }
    },

    setCurrentPage: (page: number) => { 
        set({ currentPage: page });
        const state = get();
        state.updateRowData(false);
    },

    setGraphQLQueryName: (name: string) => setGraphQLQueryName(set, name),

    setPageSize: (pageSize: number) => {
        set({
            currentPage: 0,
            pageSize: pageSize
        });
        const state = get();
        state.updateRowData(false);
    },

    toggleSortColumn: (column: string, uniqueSortColumn: string) => {
        set(state => ({
            currentPage: 0,
            sortOrder: applySortImpl(state.sortOrder, column, uniqueSortColumn)
        }));
        const state = get();
        state.updateRowData(false);
    },

    setFilterNode: (node: FilterNode) => {
        setFilterNode(set, node);
        
        const state = get();
        state.updateRowData(false);
    },

    removeFilterNode: (nodeId: string) => {
        removeFilterNode(set, nodeId);
        const state = get();
        state.updateRowData(false);
    },

    clearFilter: () => {
        set({
            currentPage: 0,
            filter: []
        });
        const state = get();
        state.updateRowData(false);
    },

    setShowFilter: show => set({ showFilter: show }),

    fixedParameters: [],
    setFixedParameters: (parameters: QueryParameter[]) => set({ fixedParameters: parameters}),

    ...requiredTableState
});

const pageParamName = "page";
const pageSizeParamName = "pageSize";
const sortParamName = "sort";

const getPage = (paramName: string, queryStringParams: URLSearchParams): number => {
    const pageParam = queryStringParams.get(paramName);
    let newPage = 0;
    if (pageParam !== null) {
        const n = parseInt(pageParam);
        if (!isNaN(n) && n > 1) {
            newPage = n - 1;
        } else {
            // TODO: Also handle multiple of same param
            queryStringParams.delete(paramName);
        }
    }

    return newPage;
}

const getPageSize = (paramName: string, queryStringParams: URLSearchParams): number => {
    const pageSizeParam = queryStringParams.get(paramName);
    let newPageSize = 100;
    if (pageSizeParam !== null) {
        const n = parseInt(pageSizeParam);
        if (!isNaN(n) && [10, 25, 50, 100].includes(n)) {
            newPageSize = n;
        } else {
            // TODO: Also handle multiple of same param
            queryStringParams.delete(paramName);
        }
    }

    return newPageSize;
}

const saveTableStateToURLSearchParams = (state: TableState, searchParams: URLSearchParams) => {
    if (state.currentPage === 0) {
        if (searchParams.has(pageParamName)) {
            searchParams.delete(pageParamName);
        }
    } else {
        searchParams.set(pageParamName, (state.currentPage + 1).toString());
    }

    searchParams.set(pageSizeParamName, state.pageSize.toString());

    const sortValue = state.sortOrder.map(c => `${c.column}${(c.direction ?? 'ASC') === 'DESC' ? ':DESC' : ''}`).join();
    if (sortValue !== '') {
        searchParams.set(sortParamName, sortValue);
    } else if (searchParams.has(sortParamName)) {
        searchParams.delete(sortParamName);
    }
}

const getSavedStateUrl = (state: TableState) => {
    const queryStringParams = new URLSearchParams(window.location.search);

    saveTableStateToURLSearchParams(state, queryStringParams);

    const newQueryString = queryStringParams.toString();
    if (newQueryString !== '') {
        //window.history.replaceState(null, '', window.location.pathname + '?' + queryStringParams.toString());
        return window.location.pathname + '?' + queryStringParams.toString();
    } else {
        //window.history.replaceState(null, '', window.location.pathname);
        return window.location.pathname;
    }
}

const getTableStateFromUrl = (): Partial<TableState> => {
    const queryStringParams = new URLSearchParams(window.location.search);
    
    return {
        currentPage: getPage(pageParamName, queryStringParams),
        pageSize: getPageSize(pageSizeParamName, queryStringParams)
    }
}

const storage: PersistStorage<TableState> = {
    getItem: name => {
        if (Object.hasOwn(window.history.state, name)) {
            return window.history.state[name];
        }
        
        return { state: getTableStateFromUrl() }
    },
    setItem: (name, value) => {
        const urlToSave = getSavedStateUrl(value.state);
        const stateToSave = {
            ...window.history.state,
            [name]: value
        };

        window.history.replaceState(stateToSave, '', urlToSave);
    },
    removeItem: name => {
        window.history.replaceState({
            ...window.history.state,
            [name]: undefined
        }, '');
    }
}

export {
    createTableStore,
    getTableStateFromUrl,
    saveTableStateToURLSearchParams,
    TableStoreContext,
    useTableState
}
export type { TableState }