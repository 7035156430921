import { createStore, StoreApi } from "zustand/vanilla";
import { AsyncData } from "../../Table/Shared";
import React, { useContext } from "react";
import { FilterState, removeFilterNode, setFilterNode } from "../../Filtering/StoreFunctions";
import { ChartData } from "../../../types";
import { QueryParameter } from "../../../GraphQLShared";
import { CancellablePromise } from "../../../utils/callDciApi";
import { updateChartData } from "./ChartStoreFunctions";
import { FilterNode } from "../../Filtering/TableFilter";
import { useStore } from "zustand";

const ChartStoreContext = React.createContext<StoreApi<ChartState>>(null as any as StoreApi<ChartState>);

const useChartState = <TProp,>(selector: (state: ChartState) => TProp) => {
    const context = useContext(ChartStoreContext);
    return useStore(context, selector);
}

interface ChartState extends FilterState {
    initialised: boolean
    graphQLQueryName: string
    groupingColumn: string | null
    chartData: AsyncData<ChartData>
    chartDataCancellablePromise: CancellablePromise | null
    fixedParameters: QueryParameter[]
    setFixedParameters: (parameters: QueryParameter[]) => void
    updateChartData: () => void
}

interface RequiredChartState {
    graphQLQueryName: string
}

const createChartStore = (requiredState: RequiredChartState, initialState?: Partial<ChartState>) => createStore<ChartState>()((set, get) => ({
    initialised: false,
    groupingColumn: null,
    chartData: {
        data: null,
        isCancelled: false,
        isError: false,
        isFetching: false
    },
    chartDataCancellablePromise: null,
    fixedParameters: [],
    setFixedParameters: (parameters: QueryParameter[]) => {
        set({ fixedParameters: parameters});
        get().updateChartData();
    },
    updateChartData: () => updateChartData(set, get),

    filter: [],
    filterColumns: [],
    removeFilterNode: (nodeId: string) => removeFilterNode(set, nodeId),
    setFilterNode: (node: FilterNode) => setFilterNode(set, node),
    setShowFilter: (show: boolean) => set({ showFilter: show }),
    showFilter: false,

    ...requiredState,
    ...(initialState ?? {})
}));

export { createChartStore, ChartStoreContext, useChartState }
export type { ChartState }