import { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import * as dciPaths from '../../utils/dciPaths';
import { DciRoute } from '../../utils/dciPaths';

export default () => {
    const baseHelpPath = 'https://support.wealth-dci.com/dci-data-analyst';
    const location = useLocation();
    const [ helpPath, setHelpPath ] = useState(baseHelpPath);

    useEffect(() => {
        for (const key in dciPaths.default) {
            if (dciPaths.default.hasOwnProperty(key)) {
                const element = (dciPaths.default as any)[key] as DciRoute;
                if (element.helpLink && (element.regex ? element.regex : new RegExp(`^${element.pattern}$`)).test(location.pathname)) {
                    setHelpPath(`${baseHelpPath}${element.helpLink}`);
                    return;
                }
            }
        }

        // If we are here there is no specific help page
        setHelpPath(baseHelpPath);
    }, [ location.pathname ])

    return (
        <IconButton color="inherit" aria-label="notifications" href={helpPath} rel='noopener noreferrer' target="_blank">
            <HelpOutline />
        </IconButton>
    )
};