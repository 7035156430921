import { useEffect, useState } from 'react';
import { callDciApiCancellable, CancellablePromise } from '../../utils/callDciApi';
import { useAuth0 } from '@auth0/auth0-react';
import { ActiveWorkItemCountByPriorityDataContext, WorkItemCountByPriorityDataSet } from './ActiveWorkItemCountByPriorityDataContext';

type ActiveWorkItemCountByPriorityDataProviderProps = {
    workQueueIds: number[],
    teamIds: number[],
    children: JSX.Element,
};

type Groups<T> = Group<T>[];

interface Group<T> {
    key: T
    count: number
}

interface GroupKey {
    workItemPriority: number
}

const ActiveWorkItemCountByPriorityDataProvider = ({ children, workQueueIds, teamIds }: ActiveWorkItemCountByPriorityDataProviderProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const [ data, setData ] = useState<WorkItemCountByPriorityDataSet | null>(null);
    
    useEffect(() => {
        let mounted = true;
        let cancellablePromise: CancellablePromise | null = null;
        async function fetchData() {
            
            if (mounted) {
                const token = await getAccessTokenSilently();
                setData({
                    rows: [],
                    fetching: true
                });

                const workQueuesClause = workQueueIds.length > 0 ? `workQueueId:{in:[${workQueueIds.join(',')}]}` : '';
                const teamsClause = teamIds.length > 0 ? `workQueue:{teamWorkQueue:{some:{teamId:{in:[${teamIds.join(',')}]}}}}` : '';

                cancellablePromise = callDciApiCancellable(`{WorkItemGrouping(where:{workItemStatusId:{in:[1,2,3,4,6,8]}${workQueuesClause}${teamsClause}}order:{key:{workItemPriority:ASC}}){key{workItemPriority}count}}`, token);
                cancellablePromise.promise
                .then(body => {
                    if (body.errors) {
                        console.error(body.errors[0].message);
                    } else {
                        const responseData = body.data.WorkItemGrouping as Groups<GroupKey>;

                        setData({
                            rows: responseData.map(r => ({ 
                                priority: r.key.workItemPriority,
                                count: r.count,
                                label: r.key.workItemPriority === 0 ? 'Not Set' : `Priority ${r.key.workItemPriority}`
                            })),
                            fetching: false
                        });
                    };
                })
            }
        }

        fetchData();
        return () => {
            mounted = false;
            cancellablePromise?.abortController.abort();
        }
    }, [ workQueueIds.length, teamIds.length ]);

    

    return (
        <ActiveWorkItemCountByPriorityDataContext.Provider value={data}>
            { children }
        </ActiveWorkItemCountByPriorityDataContext.Provider>
    )
};

export { ActiveWorkItemCountByPriorityDataProvider };