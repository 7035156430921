import {
    Typography
} from '@mui/material';

const WNNovember24OtherChanges = () => {
    return (
        <>
            <Typography variant='h5'>Other Changes</Typography>
            <Typography variant='body1'>A number of improvements have been applied across the system:</Typography>
            <ul>
                <li>A new reporting screen option to filter by the user triggering an event (i.e. Referred, Mark as Resolved etc.)</li>
                <li>A fix has been applied to some of the statistics in the Core KPIs on the dashboard</li>
                <li>A fix has been applied to ensure the per-rule settings around override availablity</li>
            </ul>
        </>
    )
}

export { WNNovember24OtherChanges }