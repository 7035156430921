import { Location, useLocation } from "react-router-dom"

const useReferrer = (loc?: Location) => {
    const location = useLocation();
    const state = (loc ?? location).state as any;
    if (state && typeof state.referrer === 'string') {
        return state.referrer as string;
    }

    return undefined;
}

export default useReferrer;