import { format, parseISO } from 'date-fns';
import { Field, ItemDisplay } from '../ItemDisplay';
import { ChildItemProps, SingleItem } from '../SingleItem';
import { ReportStatisticsList } from './ReportStatisticsList';
import { Box, Stack } from '@mui/material';

type ReportingRunHistory = {
    history: History,
    statistics: Statistics
}

type History = {
    reportingRunId: number,
    runStartDatetime: Date,
    runEndDatetime: Date,
    durationSeconds : number
}

type Statistics = {
    totalDataPointsInspected: number,
    totalWorkItemsCreated: number,
    totalReportableEvents: number
}

const mappingFunction = (input: any): ReportingRunHistory => ({
    history: {
        ...input.history,
        runStartDatetime: parseISO(input.history.runStartDatetime),
        runEndDatetime: parseISO(input.history.runEndDatetime),
    },
    statistics: input.statistics
})

const displayFields: Field<ReportingRunHistory>[] = [
    {
        displayName: 'Run Start',
        valueResolver: item => format(item.history.runStartDatetime, 'yyyy-MM-dd HH:mm:ss')
    },
    {
        displayName: 'Run End',
        valueResolver: item => format(item.history.runEndDatetime, 'yyyy-MM-dd HH:mm:ss')
    },
    {
        displayName: 'Reporting Run Definition',
        valueResolver: item => item.history.reportingRunId.toString()
    },
]

type ReportingRunHistoryViewParams = {
    id: number
}

const ReportingRunHistoryView = ({ id }: ReportingRunHistoryViewParams) => {
    const ItemComponent = ({ item, refresh }: ChildItemProps<ReportingRunHistory>) => {
        return (
            <Stack style={{ height:'100%' }}>
                <Box>
                    <ItemDisplay<ReportingRunHistory> item={item} fields={displayFields} />
                </Box>
                <Box flexGrow={1} overflow='auto' padding='2px'>
                    <ReportStatisticsList reportingRunHistoryId={id} />
                </Box>
            </Stack>
        )
    }

    return <SingleItem<ReportingRunHistory>
        queryName='reportingRunHistoryById'
        queryParameters={`reportingRunHistoryId:${id}`}
        queryColumns='{history{reportingRunId,runStartDatetime,runEndDatetime,durationSeconds}statistics{totalDataPointsInspected,totalWorkItemsCreated,totalReportableEvents}}'
        mappingFunction={mappingFunction}
        ItemComponent={({item, refresh}) => <ItemComponent item={item} refresh={refresh} />}
    />
}

export { ReportingRunHistoryView }