import React from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import  {
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import PropTypes from 'prop-types';

type ListItemLinkProps = {
    nestingLevel: number,
    icon?: React.ReactElement,
    primary?: React.ReactNode,
    to: string,
    subItemSelected: boolean,
    selected?: (path: string) => boolean,
    preNavigateAction?: () => void
};

const ListItemLink = ({ nestingLevel, icon, primary, to, subItemSelected, selected, preNavigateAction }: ListItemLinkProps) => {
    const location = useLocation();
    const navigate = useNavigate();

    // const renderLink = React.useMemo(
    //     () => React.forwardRef<HTMLAnchorElement>((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    //     [to],
    // );

    const itemSelected = to === location.pathname || (typeof(selected) === 'function' && selected(location.pathname));

    // TODO: When we overhaul this, make sure that anything that can be a straightforward link is a link on the ListItemButton
    const itemOnClick = () => {
        if (preNavigateAction) {
            preNavigateAction();
        }

        navigate(to);
    }

    // The to has been added to the listitem below and the component was referring to the renderlink that is commented above
    return (
        <ListItem
            sx={theme => ({
                cursor:'pointer',
                '&:hover': {
                    backgroundColor:theme.palette.action.hover
                }
            })}
            onClick={itemOnClick}
            selected={itemSelected}
            style={ nestingLevel > 0 ? { paddingLeft: nestingLevel * 16 + 16 } : undefined }
        >
            {icon ? <ListItemIcon>{ subItemSelected || itemSelected ? React.cloneElement(icon, { color: 'secondary' }) : icon }</ListItemIcon> : null}
            <ListItemText primary={primary} />
        </ListItem>
    );
}
  
ListItemLink.propTypes = {
    icon: PropTypes.element,
    primary: PropTypes.string.isRequired,
    to: PropTypes.string,
};

export { ListItemLink };