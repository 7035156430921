import userHasPermission from '../utils/userHasPermission';
import { Navigate } from 'react-router-dom';
import NotFound from './NotFound';
import { useStore } from 'zustand';
import { appGlobalStore } from '../AppGlobalStore';

type PermissionedRouteProps = {
    requiredPermission: number,
    alternativePath?: string,
    children: JSX.Element
}

const PermissionedRoute = ({ requiredPermission, alternativePath, children }: PermissionedRouteProps) => {
    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    return userHasPermission(currentUser, requiredPermission)
        ? children
        : alternativePath ? <Navigate to={alternativePath!} /> : <NotFound message='This location does not exist or you do not have permission to access it' />
}

export { PermissionedRoute }