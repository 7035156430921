import { useState } from 'react';
import { AddBox, Check, Clear } from '@mui/icons-material';
import { Button, TableCell, alpha } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import OrgContainer from './OrgContainer';
import dciPaths from '../../utils/dciPaths';
import { AddUserDialog } from './AddUserDialog';
import userHasPermission from '../../utils/userHasPermission';
import { permissions } from '../../utils/dciConstants';
import { useStore } from 'zustand';
import { appGlobalStore } from '../../AppGlobalStore';
import { TableColumn } from '../Filtering/types';
import { TableStoreContext, createTableStore, useTableState } from '../Table/Stores/TableStore';
import { TableContainer } from '../Table/TableContainer';
import { Table } from '../Table/Table';

type UserRow = {
    userId: number,
    firstName: string,
    surname: string,
    emailAddress: string,
    enabled: boolean
}

const columns: TableColumn[] = [
    {
        displayName: "First Name",
        selector: "firstName"
    },
    {
        displayName: "Surname",
        selector: "surname"
    },
    {
        displayName: "E-mail",
        selector: "emailAddress"
    },
    {
        displayName: "Enabled",
        selector: "enabled",
        render: value => (<TableCell key={'enabled'} style={{ textAlign: 'center' }} padding={'checkbox'}>{value.enabled ? <Check /> : <Clear />}</TableCell>)
    },
];

const createStore = () => createTableStore({
        graphQLQueryName: 'allUsers',
        graphQLQueryColumns: '{userId,firstName,surname,emailAddress,enabled}',
        idFromRow: row => row.userId,
        uniqueSortColumn: 'userId'
    },
    {
        paged: true,
        sortOrder: [{ column:'surname' }]
    });

const Controls = () => {
    const [ addUserDialogIsOpen, setAddUserDialogIsOpen ] = useState(false);
    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const canAddUsers = userHasPermission(currentUser, permissions.ADD_USER);

    const updateData = useTableState(s => s.updateRowData);

    const closeAddUserDialog = (refresh: boolean) => {
        setAddUserDialogIsOpen(false);
        if (refresh) {
            updateData(false);
        }
    }

    return <>
        { canAddUsers &&
            <>
                <Button
                    sx={theme => ({ 
                        color:theme.palette.text.primary,
                        borderColor:theme.palette.divider,
                        '&:hover': {
                            borderColor: theme.palette.action.disabledBackground,
                            backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity)
                        }
                    })}
                    style={{ margin:'0px 4px' }}
                    endIcon={<AddBox />}
                    variant='outlined'
                    onClick={() => setAddUserDialogIsOpen(true)}
                    aria-label='add user'>
                        Add
                </Button>
                <AddUserDialog isOpen={addUserDialogIsOpen} close={closeAddUserDialog} />
            </>
        }
    </>
}

const UsersList = () => {
    const navigate = useNavigate();
    const [ store ] = useState(() => createStore());

    return (
        <OrgContainer>
            <TableStoreContext.Provider value={store}>
                <TableContainer
                    title='Users'
                    paged
                    style={{ height:'100%', width:'100%' }}
                    enableExport
                    toolbarButtons={<Controls />}
                >
                    <Table
                        uniqueSortColumn='userId'
                        columns={columns}
                        idFromValue={row => row.userId}
                        onRowClick={row => navigate(dciPaths.user.buildLink(row.userId))}
                    />
                </TableContainer>
            </TableStoreContext.Provider>
        </OrgContainer>
      )
}

export { UsersList }