import { addMultipleParameters, addParameterToObject, addToUniqueParameters, finaliseParameters, getParameterApplicator, PARAMETER_TYPE, ParameterType } from "../../../GraphQLShared";
import { callDciApiCancellableNew } from "../../../utils/callDciApi";
import authClient from "../../Auth/auth-client";
import { getParameterConverter } from "../../Filtering/FilterRepository";
import { SetterFunction } from "../../Zustand/Shared";
import { ChartState } from "./ChartStore";

const updateChartData = async (set: SetterFunction<ChartState>, get: () => ChartState) => {
    const state = get();

    const token = await authClient.getTokenSilently();
    const validFilters = state.filter.filter(f => f.valueIsValid && f.column !== null);
    const parameters = {};
    const uniqueParameterNameTypes: { name: string, type: ParameterType }[] = [];

    validFilters.forEach(f => {
        const converter = getParameterConverter(f.column!.type);
        if (converter === null) {
            throw Error('Unable to convert filter value to a parameter.');
        }

        const parameter = converter(f.column!.name, f.value);
        addToUniqueParameters(uniqueParameterNameTypes, parameter);

        const applicator = getParameterApplicator(parameter.type);
        if (applicator === null) {
            throw Error('Unable to apply value to the parameters.');
        }

        addParameterToObject(parameter.name, parameter.value, applicator, parameters);
    });

    addMultipleParameters(state.fixedParameters, parameters, uniqueParameterNameTypes);

    const finalParameters = finaliseParameters(parameters, uniqueParameterNameTypes);

    const query = `{${state.graphQLQueryName}(${finalParameters.map(qp => `${qp.name}:${qp.value}`).join()}){title,labels,identifiers,dataSets{colour,data,groupId,label}}}`;
    const cancellablePromise = callDciApiCancellableNew(query, token);

    set({
        initialised: true,
        chartData: {
            data: null,
            isCancelled: false,
            isError: false,
            isFetching: true
        },
        chartDataCancellablePromise: cancellablePromise
    });

    try {
        const result = await cancellablePromise.promise;
        set({
            chartData: {
                data: result.data[state.graphQLQueryName],
                isCancelled: false,
                isError: false,
                isFetching: false
            },
            chartDataCancellablePromise: null
        });
    } catch (error) {
        console.error(error);
        set({
            chartData: {
                data: null,
                isCancelled: false,
                isError: true,
                isFetching: false
            },
            chartDataCancellablePromise: null
        });
    }
}

export { updateChartData }