const settings = window.env ?
    {
        DCI_API_SERVER: window.env.DCI_API_SERVER,
        LOGOUT_REDIRECT: window.env.LOGOUT_REDIRECT,

        AUTH_DOMAIN: window.env.AUTH_DOMAIN,
        AUTH_CLIENTID: window.env.AUTH_CLIENTID
    } :
    {
        DCI_API_SERVER: process.env.REACT_APP_DCI_API_SERVER,
        LOGOUT_REDIRECT: process.env.REACT_APP_LOGOUT_REDIRECT,

        AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN,
        AUTH_CLIENTID: process.env.REACT_APP_AUTH_CLIENTID
    }
  
export { settings };