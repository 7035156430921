import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
    Check, 
    Clear 
} from '@mui/icons-material';
import { 
    TableCell 
} from '@mui/material';
import dciPaths from '../../utils/dciPaths';
import { TableStoreContext, createTableStore } from '../Table/Stores/TableStore';
import { TableColumn } from '../Filtering/types';
import { TableContainer } from '../Table/TableContainer';
import { Table } from '../Table/Table';
import OrgContainer from '../Organisation/OrgContainer';

type TableRow = {
    extractId: number,
    extractTableId: number,
    tableName: string,
    extractSequence: number,
    enabled: boolean
}

const columns: TableColumn[] = [
    {
        displayName: "Extract ID",
        selector: "extractId"
    },
    {
        displayName: "Name",
        selector: "tableName"
    },
    {
        displayName: "Extract Sequence",
        selector: "extractSequence"
    },
    {
        displayName: "Enabled",
        selector: "enabled",
        render: row => (<TableCell key={'enabled'} style={{ textAlign: 'center' }} padding={'checkbox'}>{row.enabled ? <Check /> : <Clear />}</TableCell>)
    }
];

const idFromRow = (row: TableRow) => `${row.extractId}-${row.extractTableId}`;

const createStore = () => createTableStore({
        graphQLQueryName: 'coreExtractTables',
        graphQLQueryColumns: '{extractId,extractTableId,tableName,extractSequence,enabled}',
        uniqueSortColumn: 'extractTableId',
        idFromRow: idFromRow
    },
    {
        paged: true,
        sortOrder: [
            { column: 'extractId' },
            { column: 'tableName' }
        ]
    });

const Tables = () => {
    const navigate = useNavigate();
    const [ store ] = useState(() => createStore());

    return (
        <OrgContainer>
            <TableStoreContext.Provider value={store}>
                <TableContainer
                    title='Extract Tables'
                    paged
                    style={{ height:'100%', width:'100%' }}
                    enableExport
                >
                    <Table
                        uniqueSortColumn='extractTableId'
                        columns={columns}
                        idFromValue={idFromRow}
                        onRowClick={row => navigate(dciPaths.coreTable.buildLink(row.extractTableId))}
                    />
                </TableContainer>
            </TableStoreContext.Provider>
        </OrgContainer>
    );
};

export { Tables };