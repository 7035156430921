import { useState, useLayoutEffect } from 'react';
import { displayConfig as Constants } from '../../utils/dciConstants';
import { Dashboard } from './Dashboard';
import { AlternativeFactsDashboard } from './AlternativeFactsDashboard';
import { useStore } from 'zustand';
import { appGlobalStore } from '../../AppGlobalStore';

export default function DashboardChooser() {
    const displayConfig = useStore(appGlobalStore, s => s.displayConfig);

    const [ dashboard, setDashboard ] = useState<number | null>(null);

    useLayoutEffect(() => {
        const dashboardSetting = displayConfig.find(m => m.displayConfigId === Constants.USE_DEMO_DASHBOARD);
        if (!dashboardSetting) {
            return;
        }

        if (typeof(dashboardSetting.config) === 'boolean') {
            setDashboard(dashboardSetting.config ? 1 : 0);
        }

        if (typeof(dashboardSetting.config) === 'number') {
            setDashboard(dashboardSetting.config);
        }
    }, [])

    switch (dashboard) {
        case 2:
            return <AlternativeFactsDashboard />;
        case 0:
            return <Dashboard />;
        case null:
        default:
            return <p>Loading dashboard preferences...</p>
    }
};