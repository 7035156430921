import {
    Typography
} from '@mui/material';
import ScreenCapture from './Images/ClickThroughBySeries.png';

const WNChartClickThrough = () => {
    return (
        <>
            <Typography variant='h5'>Improved Chart Click-Through</Typography>
            <Typography variant='body1'>Previously clicking on a date-based chart would take you to all items for that date. Now, you can click on an individual mark, and be taken to a list filtered by that particular data series.</Typography>
            <img style={{ display:'block', margin:'20px auto' }} src={ScreenCapture} alt="Chart click-through screen capture" />
        </>
    )
}

export { WNChartClickThrough }