import { useState, useEffect } from 'react';
import OrgContainer from './OrgContainer';
import {
    Box,
    Button,
    Card, 
    CardContent, 
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    IconButton,
} from '@mui/material';
import { 
    AddBox,
    Delete,
    Edit,
    Save
} from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';
import callDciApi, { postDciApi } from '../../utils/callDciApi';
import userHasPermission from '../../utils/userHasPermission';
import { permissions } from '../../utils/dciConstants';
import { AuditItemsByEntity } from '../AuditItemsByEntity';
import { ControlButton } from '../DciControls';
import { useStore } from 'zustand';
import { appGlobalStore } from '../../AppGlobalStore';

type ValueList = {
    valueListId: number,
    description: string,
    organisationValueListItem: ValueListItem[]
};

type ValueListItem = {
    valueListItemId: number | null,
    value: string
}

export const OrgValueLists = () => {
    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const [ valueList, setValueList ] = useState<number | null>(null);
    const [ valueLists, setValueLists ] = useState<ValueList[]>([])
    const [ editMode, setEditMode ] = useState(false);
    const [ dummyRefresh, setDummyRefresh ] = useState(0);
    const [ newList, setNewList ] = useState<ValueListItem[]>([]);
    const { getAccessTokenSilently } = useAuth0();

    const hasEditPermission = userHasPermission(currentUser, permissions.EDIT_SYSTEM_SETTINGS);

    useEffect(() => {
        let isSubscribed = true;
        const getValueLists = async () => {

            const token = await getAccessTokenSilently();
            callDciApi('{allOrganisationValueLists{valueListId,description,organisationValueListItem{valueListItemId,value}}}', token)
            .then(body => {
                if (!isSubscribed) {
                    return;
                }

                if (body.errors) {
                    alert(body.errors[0].message);
                } else {
                    setValueLists(body.data.allOrganisationValueLists);
                }
            });
        };

        getValueLists();
        return () => { isSubscribed = false };
    }, [ dummyRefresh ]);

    const editOnClick = () => {
        const list = valueLists.find(x => x.valueListId === valueList)?.organisationValueListItem;
        if (list === undefined) {
            return;
        }

        setNewList(list.map(m => ({ 
            valueListItemId: m.valueListItemId,
            value: m.value
        } as ValueListItem)));

        setEditMode(true);
    };

    const removeItem = (index: number) => setNewList(val => val.filter((_, i) => i !== index));

    const addItem = () => setNewList(val => [ ...val, { value:'New Item', valueListItemId:null } as ValueListItem ]);

    const save = async () => {
        const token = await getAccessTokenSilently();
        postDciApi(`mutation{editOrganisationValueListItems(valueListId:${valueList},newItems:[${newList.map(m => `{valueListItemId:${m.valueListItemId},value:"${m.value.replaceAll('\\', '\\\\').replaceAll('\n', '\\n')}"}`)}])}`, token)
        .then(body => {
            if (body.errors) {
                alert(body.errors[0].message);
            } else {
                setEditMode(false);
                setDummyRefresh(val => val + 1);
            }
        })
    };

    return (
        <OrgContainer>
            <Card>
                <CardContent 
                    sx={{
                        '& .MuiFormControl-root': {
                            '& .MuiInputBase-root': {
                                minWidth: 500,
                                marginTop: 2,
                            }
                        }
                    }}
                >
                    <Box style={{ float:'right' }}>
                        {hasEditPermission && !editMode && <Button disabled={valueList === null} style={{ zIndex:1000 }} startIcon={<Edit />} onClick={editOnClick}>Edit</Button>}
                    </Box>
                    <FormControl disabled={editMode} variant='standard'>
                        <InputLabel shrink id="list-label">Value List</InputLabel>
                        <Select
                            labelId="list-label"
                            id=""
                            value={valueList === null ? '' : valueList}
                            onChange={e => setValueList(e.target.value === '' ? null : e.target.value as number)}
                            displayEmpty
                            sx={{ marginTop:2 }}
                        >
                            <MenuItem value=''><em>None</em></MenuItem>
                            { valueLists.map((m, i) => <MenuItem key={i} value={m.valueListId}>{m.description}</MenuItem>) }
                        </Select>
                    </FormControl>
                    { !editMode && valueList !== null && valueLists.find(x => x.valueListId === valueList)?.organisationValueListItem.map((m, i) => 
                        <Typography mt={'12px'} key={i}>{m.value}</Typography>
                    )}
                    { editMode &&
                        newList.map((m, i) =>
                            <div key={i}>
                                <TextField variant='standard' value={m.value} onChange={e => { const newValue = e.target.value; setNewList(val => val.map((n, j) => j === i ? { valueListItemId:n.valueListItemId, value:newValue } as ValueListItem : n)) }} />
                                <IconButton onClick={() => removeItem(i)} style={{ color:'red', marginTop:8 }} aria-label="remove item" component="span">
                                    <Delete />
                                </IconButton>
                            </div>
                        )
                    }
                    { editMode &&  
                        <div>
                            <ControlButton onClick={addItem} startIcon={<AddBox />} variant='contained' size='small'>Add Item</ControlButton>
                        </div>
                    }
                    { editMode &&
                        <div>
                            <ControlButton onClick={save} startIcon={<Save />} variant='contained'>Save</ControlButton>
                            <ControlButton onClick={() => setEditMode(false)} variant='contained'>Cancel</ControlButton>
                        </div>
                    }
                </CardContent>
            </Card>
            { valueList !== null && <AuditItemsByEntity style={{ marginTop:'10px' }} type='OrganisationValueList' entityKey={[{ fieldName:'ValueListId', fieldValue:valueList }]} dummyRefresh={dummyRefresh} /> }
        </OrgContainer>
    );
};