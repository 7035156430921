import { postDciApi } from "../../utils/callDciApi";
import authClient from "../Auth/auth-client";
import { ApiClient } from "./ApiClient";

type RegulatoryImpact = {
    regulatoryImpactId: number,
    description: string,
    displayIcon: string,
    regulatoryImpactValue: number
}

const RegulatoryImpactApi: ApiClient<RegulatoryImpact> = {

    get: async (predicate?: (ri: RegulatoryImpact) => boolean) => {
        const token = await authClient.getTokenSilently();
        const apiResponse = await postDciApi('{allRegulatoryImpacts{regulatoryImpactId,description,displayIcon,regulatoryImpactValue}}', token);

        let result = apiResponse.data.allRegulatoryImpacts as RegulatoryImpact[];
        if (predicate) {
            result = result.filter(predicate);
        }

        return result;
    }
    
}

export { RegulatoryImpactApi }