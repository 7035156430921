import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OrgContainer from './OrgContainer';
import dciPaths from '../../utils/dciPaths';
import { AddTeamDialog } from './AddTeamDialog';
import { 
    AddBox, 
    Check, 
    Clear 
} from '@mui/icons-material';
import { 
    Button,
    TableCell, 
    alpha
} from '@mui/material';
import userHasPermission from '../../utils/userHasPermission';
import { permissions } from '../../utils/dciConstants';
import { appGlobalStore } from '../../AppGlobalStore';
import { useStore } from 'zustand';
import { TableStoreContext, createTableStore, useTableState } from '../Table/Stores/TableStore';
import { TableContainer } from '../Table/TableContainer';
import { Table } from '../Table/Table';
import { TableColumn } from '../Filtering/types';

type TeamRow = {
    teamId: number,
    name: string,
    description: string,
    enabled: boolean
}

const createStore = () => createTableStore({
        graphQLQueryName: 'allTeams',
        graphQLQueryColumns: '{teamId,name,description,enabled}',
        uniqueSortColumn: 'teamId',
        idFromRow: (row: TeamRow) => row.teamId
    },
    {
        paged: true,
        sortOrder: [{ column:'name' }]
    });

const columns: TableColumn[] = [
    {
        displayName: "Name",
        selector: "name"
    },
    {
        displayName: "Description",
        selector: "description"
    },
    {
        displayName: "Enabled",
        selector: "enabled",
        render: row => (<TableCell key={'enabled'} style={{ textAlign: 'center' }} padding={'checkbox'}>{row.enabled ? <Check /> : <Clear />}</TableCell>)
    }
];

const AdditionalControls = () => {
    const [ addTeamDialogIsOpen, setAddTeamDialogIsOpen ] = useState(false);
    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const canAddTeam = userHasPermission(currentUser, permissions.ADD_TEAM);

    const updateData = useTableState(s => s.updateRowData);

    const close = (refreshTable: boolean) => {
        setAddTeamDialogIsOpen(false);
        if (refreshTable) {
            updateData(false);
        }
    };

    return (
        <>
            { canAddTeam &&
            <>
                <Button
                    sx={theme => ({ 
                        color:theme.palette.text.primary,
                        borderColor:theme.palette.divider,
                        '&:hover': {
                            borderColor: theme.palette.action.disabledBackground,
                            backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity)
                        }
                    })}
                    style={{ margin:'0px 4px' }}
                    endIcon={<AddBox />}
                    variant='outlined'
                    onClick={() => setAddTeamDialogIsOpen(true)}
                    aria-label='add team'>
                        Add
                </Button>
                <AddTeamDialog isOpen={addTeamDialogIsOpen} close={close} />
            </>}
        </>
    )
};

const Teams = () => {
    const navigate = useNavigate();
    const [ store ] = useState(() => createStore())
    
    return (
        <OrgContainer>
            <TableStoreContext.Provider value={store}>
                <TableContainer
                    title='Teams'
                    paged
                    style={{ height:'100%', width:'100%' }}
                    enableExport
                    toolbarButtons={<AdditionalControls />}
                >
                    <Table
                        uniqueSortColumn='teamId'
                        columns={columns}
                        idFromValue={row => row.teamId}
                        onRowClick={row => navigate(dciPaths.team.buildLink(row.teamId))}
                    />
                </TableContainer>
            </TableStoreContext.Provider>
        </OrgContainer>
    );
};

export { Teams };