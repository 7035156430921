import { defaultMappingFunction, defaultQueryColumns, WorkItem, WorkItemsList } from './WorkItemsList';
import { workItemStatus } from '../../utils/dciConstants';
import { createTableStore, TableStoreContext } from '../Table/Stores/TableStore';
import { useState } from 'react';
import { PARAMETER_TYPE } from '../../GraphQLShared';
import { FilterStoreContext } from '../Filtering/StoreFunctions';
import { filterColumns } from './Shared';

const createStore = (workItemStatusIds: number[]) => createTableStore(
    {
        graphQLQueryName: 'allWorkItems',
        graphQLQueryColumns: defaultQueryColumns,
        idFromRow: (row: WorkItem) => row.workItemId,
        uniqueSortColumn: 'workItemId'
    }, 
    {
        paged: true,
        mappingFunction: defaultMappingFunction,
        filterColumns: filterColumns,
        fixedParameters: [ { name: 'fixedFilter.workItemStatusId', type: PARAMETER_TYPE.NUMBER, value: workItemStatusIds.join() } ]
    });

const PredefinedView = ({ displayName, workItemStatuses }: PredefinedViewProps) => {
    const [ store ] = useState(() => createStore(workItemStatuses));

    return <TableStoreContext.Provider value={store}>
        <FilterStoreContext.Provider value={store}>
            <WorkItemsList title={displayName} />
        </FilterStoreContext.Provider>
    </TableStoreContext.Provider>
}

interface PredefinedViewProps {
    displayName: string
    workItemStatuses: number[]
}

const Distribution = () => <PredefinedView displayName='Distribution' workItemStatuses={[workItemStatus.RECEIVED]} />
const Queued = () => <PredefinedView displayName='Queued' workItemStatuses={[workItemStatus.QUEUED]} />
const InProgress = () => <PredefinedView displayName='In Progress' workItemStatuses={[workItemStatus.PROCESSING]} />
const AwaitingInformation = () => <PredefinedView displayName='Awaiting Information' workItemStatuses={[workItemStatus.AWAITING_INFORMATION]} />
const OverrideRequest = () => <PredefinedView displayName='Override Request' workItemStatuses={[workItemStatus.OVERRIDE_REQUEST]} />
const Referred = () => <PredefinedView displayName='Referred' workItemStatuses={[workItemStatus.REFERRED]} />
const Corrected = () => <PredefinedView displayName='Corrected' workItemStatuses={[workItemStatus.CORRECTED, workItemStatus.CORRECTED_AT_SOURCE]} />
const Override = () => <PredefinedView displayName='Override' workItemStatuses={[workItemStatus.OVERRIDE_APPROVED, workItemStatus.OVERRIDE_APPROVED_SAME_DATA_ONLY]} />

export {
  Distribution,
  Queued,
  InProgress,
  AwaitingInformation,
  OverrideRequest,
  Referred,
  Corrected,
  Override
}