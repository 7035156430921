import React, { CSSProperties, useState } from 'react'
import { 
    Box, 
    IconButton, 
    InputBase, 
    Paper, 
    Stack, 
    styled, 
    TableCell, 
} from '@mui/material'
import { 
    Clear, 
    Search 
} from '@mui/icons-material'
import { CheckCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import dciPaths from '../../utils/dciPaths';
import { PARAMETER_TYPE, QueryParameter } from '../../GraphQLShared';
import { createTableStore, TableStoreContext, useTableState } from '../Table/Stores/TableStore';
import { TableColumn } from '../Filtering/types';
import { TableContainer } from '../Table/TableContainer';
import { Table } from '../Table/Table';

const SearchInput = styled(InputBase)(({ theme }) => `
    margin-left: ${theme.spacing(1)};
    flex: 1;
`);

const SearchBar = styled(Paper)`
    padding:2px 4px;
    display:flex;
    align-items:center;
    margin-bottom:10px;
`;

type RuleRow = {
    reportTestId: number,
    ruleDescription: string,
    rulePriority: number,
    enabled: boolean
}

type SearchableRuleListProps = {
    title?: string,
    queryFilter?: QueryParameter[]
    style?: CSSProperties
}

const SearchComponent = ({ queryFilter }: { queryFilter: QueryParameter[] }) => {
    const [ searchTerm, setSearchTerm ] = useState('');

    const setGraphQLQueryName = useTableState(s => s.setGraphQLQueryName);
    const updateData = useTableState(s => s.updateRowData);
    const setFixedParameters = useTableState(s => s.setFixedParameters);

    const handleKeyPress = (event: React.KeyboardEvent): any => {
        if (event.key === 'Enter') {
            applySearchTerm(searchTerm)
        }
    };

    const applySearchTerm = (searchTerm: string) => {
        setGraphQLQueryName(searchTerm === '' ? 'allRules' : 'ruleSearch')
        setFixedParameters(searchTerm === '' ? [ ...queryFilter ] : [ ...queryFilter, { name:'searchTerm', type: PARAMETER_TYPE.RAW_STRING, value: searchTerm }]);
        updateData(false);
    }

    const clearSearch = () => {
        setSearchTerm('');
        applySearchTerm('');
    };

    return (
        <SearchBar>
            <IconButton style={{ padding:'10px' }} aria-label="search" onClick={() => applySearchTerm(searchTerm)}>
                <Search />
            </IconButton>
            <SearchInput
                placeholder="Search Rules"
                inputProps={{ 'aria-label': 'search rules' }}
                onChange={ e => setSearchTerm(e.target.value) }
                value={searchTerm}
                onKeyDown={handleKeyPress}
            />
            { searchTerm !== '' && 
            <IconButton style={{ padding:'10px' }} aria-label="search" onClick={clearSearch}>
                <Clear />
            </IconButton>
            }
        </SearchBar>
    )
}

const columns: TableColumn[] = [
    {
        displayName: 'Reference',
        selector: 'reportTestId'
    },
    {
        displayName: 'Description',
        selector: 'ruleDescription'
    },
    {
        displayName: 'Priority',
        justify: 'center',
        selector: 'rulePriority'
    },
    {
        displayName: 'Enabled',
        selector: 'enabled',
        justify: 'center',
        render: value => <TableCell padding='checkbox' style={{ textAlign:'center' }} key='enabled'>{value.enabled ? <CheckCircle /> : null}</TableCell>
    },
];

const createStore = (queryFilter: QueryParameter[]) => createTableStore(
    {
        graphQLQueryName: 'allRules',
        graphQLQueryColumns: '{reportTestId,ruleDescription,rulePriority,enabled}',
        uniqueSortColumn: 'reportTestId',
        idFromRow: (row: RuleRow) => row.reportTestId
    }, 
    {
        paged: true,
        sortOrder: [{ column:'reportTestId' }],
        fixedParameters: queryFilter
    });

const SearchableRuleList = ({
    title = 'Rules',
    queryFilter = [],
    style = {}
}: SearchableRuleListProps) => {
    const navigate = useNavigate();
    const [ store ] = useState(() => createStore(queryFilter));

    return (
        <Stack style={{ height:'100%', ...style }}>
            <TableStoreContext.Provider value={store}>
                <SearchComponent queryFilter={queryFilter} />
                <Box flexGrow={1} overflow='auto' padding='2px'>
                    <TableContainer
                        title={title}
                        paged
                        style={{ height:'100%', width:'100%' }}
                        enableExport
                    >
                        <Table
                            uniqueSortColumn='userId'
                            columns={columns}
                            idFromValue={row => row.reportTestId}
                            onRowClick={row => navigate(dciPaths.rule.buildLink(row.reportTestId))}
                        />
                    </TableContainer>
                </Box>
            </TableStoreContext.Provider>
        </Stack>
    )
}

export { SearchableRuleList }