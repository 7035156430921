import React, { useEffect, useState } from 'react';

import { AuditItemsByEntity } from '../AuditItemsByEntity';
import { 
    Autocomplete,
    AutocompleteChangeReason,
    Chip,
    Card,
    Box, 
    Button,
    TextField,
    CircularProgress,
    SxProps,
    Theme,
    MenuItem,
    Select,
    Stack
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DciLink from '../DciLink';
import OrgContainer from './OrgContainer';
import dciPaths from '../../utils/dciPaths';
import { SegmentMap } from '../Layout/DciBreadcrumb';
import callDciApi, { CancellablePromise, callDciApiCancellable } from '../../utils/callDciApi';
import { useAuth0 } from '@auth0/auth0-react';

import {
    User as ApiUser
} from '../../utils/DciApiTypes';
import { PageTitle, TextProperty, FieldCaption, LightTypography, ControlButton, FormCardContent } from '../DciControls';
import userHasPermission from '../../utils/userHasPermission';
import { permissions } from '../../utils/dciConstants';
import { Block, Edit, Save } from '@mui/icons-material';
import { DisableUserDialog } from './DisableUserDialog';
import { appGlobalStore } from '../../AppGlobalStore';
import { useStore } from 'zustand';
import { ColourPicker, getRandomHexColour } from '../ColourPicker';

type Manager = {
    firstName: string,
    surname: string,
    userId: number
};

type Team = {
    teamId: number,
    name: string
};

type User = {
    userId: number,
    enabled: boolean,
    firstName: string | null,
    surname: string | null,
    emailAddress: string | null,
    branch: string | null,
    jobTitle: string | null,
    location: string | null,
    manager: Manager | null,
    mobileTelephone: string,
    workExtension: string | null,
    workTelephone: string | null,
    chartColour: string | null,
    teams: Team[]
};

const classes = {
    disableButton: {
        color:'red',
        '&:hover': {
            backgroundColor:'#ff000010'
        }
    } as SxProps<Theme>,
    enableButton: {
        color:'green',
        '&:hover': {
            backgroundColor:'#00ff0010'
        }
    } as SxProps<Theme>
}

type EditAndDisplayProps = {
    user: User,
    refresh: () => void
}

type EditTextFieldProps = {
    label: string,
    value: any,
    onValueChange: (value: any) => void
}

const EditTextField = ({ label, value, onValueChange }: EditTextFieldProps) =>
    <TextField
        variant='standard'
        margin="dense"
        label={label}
        id={label}
        type="text"
        value={value === null ? '' : value}
        onChange={(event: any) => onValueChange(event.target.value === '' ? null : event.target.value)}
    />

const workTelephone = (user: User): string | null => {
    if (user === null) {
        return null;
    }
    
    let result = null;
    if (user.workTelephone) {
        result = user.workTelephone;
        if (user.workExtension) {
            result += ` (ext. ${user.workExtension})`
        }
    }
    else if (user.workExtension) {
        result = `Ext. ${user.workExtension}`;
    }

    return result;
};

type UserStub = {
    userId: number,
    firstName: string,
    surname: string
}

const EditAndDisplay = ({ user, refresh }: EditAndDisplayProps) => {
    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const [ newItem, setNewItem ] = useState<User>({ ...user });
    const [ editMode, setEditMode ] = useState(false);
    const [ usersLoading, setUsersLoading ] = useState(true);
    const [ users, setUsers ] = useState<UserStub[]>([]);
    const [ disableDialogIsOpen, setDisableDialogIsOpen ] = useState(false);

    const hasEditPermission = userHasPermission(currentUser, permissions.EDIT_USER);

    useEffect(() => {
        const loadUsers = async () => {
            const token = await getAccessTokenSilently();
            callDciApi('{allUsers{nodes{userId,firstName,surname}}}', token)
            .then(body => {
                setUsersLoading(false);
                if (!body.errors) {
                    setUsers(body.data.allUsers.nodes);
                }
            })
            .catch(error => {
                setUsersLoading(false);
                console.error(`Error loading users`);
            });
        }

        loadUsers();
    }, [])

    const beginEdit = () => {
        setNewItem({ ...user });
        setEditMode(true);
    };

    const cancelChanges = () => {
        setEditMode(false);
    };

    const getQueryProperty = (name: string, value: string | null) =>
        `${name}:${value === null ? 'null' : `"${value.replace('"', '""')}"`},`;

    const saveChanges = async () => {
        let query = 'mutation{editUser(edited:{';
        
        query += `userId:${newItem.userId},`;
        query += getQueryProperty('firstName', newItem.firstName);
        query += getQueryProperty('surname', newItem.surname);
        query += getQueryProperty('workTelephone', newItem.workTelephone);
        query += getQueryProperty('workExtension', newItem.workExtension);
        query += getQueryProperty('mobileTelephone', newItem.mobileTelephone);
        query += getQueryProperty('jobTitle', newItem.jobTitle);
        query += getQueryProperty('location', newItem.location);
        query += getQueryProperty('branch', newItem.branch);
        query += getQueryProperty('chartColour', newItem.chartColour);
        query += `managerUserId:${newItem.manager?.userId ?? null}`;

        query += '})}';

        const token = await getAccessTokenSilently();
        callDciApi(query, token)
        .then(body => {
            if (body.errors) {
                
            } else {
                setEditMode(false);
                refresh();
            }
        });
    };

    const changesMade = () => {
        if (user.firstName !== newItem.firstName) {
            return true;
        }

        if (user.surname !== newItem.surname) {
            return true;
        }

        if (user.workTelephone !== newItem.workTelephone) {
            return true;
        }

        if (user.workExtension !== newItem.workExtension) {
            return true;
        }

        if (user.mobileTelephone !== newItem.mobileTelephone) {
            return true;
        }

        if (user.jobTitle !== newItem.jobTitle) {
            return true;
        }

        if (user.branch !== newItem.branch) {
            return true;
        }

        if (user.location !== newItem.location) {
            return true;
        }

        if (user.manager?.userId !== newItem.manager?.userId) {
            return true;
        }

        if (user.chartColour !== newItem.chartColour) {
            return true;
        }
        
        return false;
    }

    const onColourTypeDropDownChange = (value: string) => {
        if (value === 'system') {
            setNewItem({ ...newItem, chartColour: null })
        }

        if (value === 'user' && newItem.chartColour === null) {
            setNewItem({ ...newItem, chartColour: getRandomHexColour() })
        }
    }

    return (
        <Card style={{ marginBottom:'10px' }}>
            <FormCardContent>
                { hasEditPermission && !editMode &&
                    <Box style={{ float:'right' }}>
                        <Button onClick={() => setDisableDialogIsOpen(true)} sx={user.enabled ? classes.disableButton : classes.enableButton} startIcon={<Block />}>{user.enabled ? 'Disable' : 'Enable'}</Button> 
                        <DisableUserDialog 
                            close={r => {
                                setDisableDialogIsOpen(false);
                                if (r) {
                                    refresh();
                                }
                            }}
                            isOpen={disableDialogIsOpen}
                            dialogAction={user.enabled ? 'disable' : 'enable'}
                            userId={user.userId}
                            userName={[user.firstName, user.surname].join(' ')}
                        />
                        <Button startIcon={<Edit />} onClick={beginEdit}>Edit</Button>
                    </Box>
                }
                { editMode
                    ? <>
                        <EditTextField label='First Name' value={newItem.firstName} onValueChange={(value: any) => setNewItem({ ...newItem, firstName: value })} />
                        <EditTextField label='Surname' value={newItem.surname} onValueChange={(value: any) => setNewItem({ ...newItem, surname: value })} />
                      </>
                    : <PageTitle title={`User: ${[user.firstName, user.surname].join(' ')}`} />
                }
                { !editMode && <TextProperty caption='Email Address' value={user.emailAddress} /> }
                { editMode
                    ? <>
                        <EditTextField label='Work Telephone' value={newItem.workTelephone} onValueChange={(value: any) => setNewItem({ ...newItem, workTelephone: value })} />
                        <EditTextField label='Work Extension' value={newItem.workExtension} onValueChange={(value: any) => setNewItem({ ...newItem, workExtension: value })} />
                      </>
                    : <TextProperty caption='Work Telephone' value={workTelephone(user)} />
                }
                { editMode
                    ? <EditTextField label='Mobile Telephone' value={newItem.mobileTelephone} onValueChange={(value: any) => setNewItem({ ...newItem, mobileTelephone: value })} />
                    : <TextProperty caption='Mobile Telephone' value={newItem.mobileTelephone} />
                }
                { editMode
                    ? <EditTextField label='Job Title' value={newItem.jobTitle} onValueChange={(value: any) => setNewItem({ ...newItem, jobTitle: value })} />
                    : <TextProperty caption='Job Title' value={newItem.jobTitle} />
                }
                { editMode
                    ? <EditTextField label='Branch' value={newItem.branch} onValueChange={(value: any) => setNewItem({ ...newItem, branch: value })} />
                    : <TextProperty caption='Branch' value={newItem.branch} />
                }
                { editMode
                    ? <EditTextField label='Location' value={newItem.location} onValueChange={(value: any) => setNewItem({ ...newItem, location: value })} />
                    : <TextProperty caption='Location' value={newItem.location} />
                }
                { !editMode && 
                    <>
                        <FieldCaption caption='Teams' />
                        <span>{ user.teams.map(m => <Chip key={m.teamId} style={{ marginTop:'2px', marginRight:'2px', marginBottom:'17px' }} onClick={() => navigate(dciPaths.team.buildLink(m.teamId))} size='small' label={m.name} />)}</span>
                    </>
                }
                { editMode
                    ? <>
                        <Autocomplete<UserStub>
                            id="user"
                            options={users}
                            getOptionLabel={(user) => [ user.firstName, user.surname ].join(' ')}
                            isOptionEqualToValue={(o,v) => o.userId === v.userId}
                            style={{ maxWidth:'500px' }}
                            value={newItem.manager}
                            onChange={(obj: React.ChangeEvent<{}>, value: UserStub | null, reason: AutocompleteChangeReason) => setNewItem({ ...newItem, manager: value })}
                            loading={usersLoading}
                            size='small'
                            renderInput={(params) => 
                                <TextField 
                                    { ...params }
                                    label="Manager" 
                                    variant='standard'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                        <>
                                            { usersLoading ? <CircularProgress color="inherit" size={20} /> : null }
                                            { params.InputProps.endAdornment }
                                        </>
                                        ),
                                    }}
                                />}
                        />
                      </>
                    : <Box>
                        <FieldCaption caption='Manager' />
                        { user.manager 
                            ? <DciLink href={dciPaths.user.buildLink(user.manager!.userId)}>{[user.manager.firstName, user.manager.surname].join(' ')}</DciLink>
                            : <LightTypography className='root' variant='body2'>[empty]</LightTypography>
                        }
                      </Box>
                }
                {
                    editMode 
                        ? <div style={{ marginTop:'10px' }}>
                            <FieldCaption caption='Chart Colour' />
                            <Stack direction='row' style={{ minHeight:'40px' }}>
                                <Select
                                    variant='standard'
                                    style={{ width:'180px' }}
                                    size='small'
                                    value={newItem.chartColour === null ? 'system' : 'user'}
                                    onChange={value => onColourTypeDropDownChange(value.target.value)}
                                >
                                    <MenuItem value='system'>System Default</MenuItem>
                                    <MenuItem value='user'>User-defined</MenuItem>
                                </Select>
                                { newItem.chartColour === null ? null : <ColourPicker colour={newItem.chartColour} setColour={c => setNewItem({ ...newItem, chartColour: c })} /> }
                            </Stack>
                        </div>
                        : <Stack style={{ marginTop:'10px' }} direction='row'>
                            <TextProperty caption='Chart Colour' value={user.chartColour === null ? 'System Default' : 'User-defined'} />
                            { user.chartColour === null ? null : <Box style={{ backgroundColor: user.chartColour, width:'40px', height:'40px', marginLeft:'10px', borderRadius:'5px' }} /> }
                        </Stack>
                }
                { editMode &&
                    <>
                        <ControlButton onClick={saveChanges} disabled={!changesMade()} startIcon={<Save />} variant='contained'>Save</ControlButton>
                        <ControlButton onClick={cancelChanges} variant='contained'>Cancel</ControlButton>
                    </>
                }
            </FormCardContent>
        </Card>
    )
}

type UserViewProps = {
    id: number,
    setBreadcrumbs: React.Dispatch<React.SetStateAction<SegmentMap[]>>
};

const UserView: React.FC<UserViewProps> = ({ id, setBreadcrumbs }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [ user, setUser ] = useState<User | null>(null);
    const [ dummyRefresh, setDummyRefresh ] = useState(0);

    useEffect(() => {
        let cancellablePromise: CancellablePromise | null = null;
        const getData = async () => {
            const token = await getAccessTokenSilently();
            cancellablePromise = callDciApiCancellable(`{appUserById(id:${id}){userId,firstName,surname,emailAddress,branch,jobTitle,location,chartColour,manager{firstName,surname,userId}mobileTelephone,workExtension,workTelephone,userRoleTeam{team{teamId,name}}enabled}}`, token);

            cancellablePromise.promise
            .then(body => {
                if (body.errors) {
                    alert(body.errors[0].message);
                } else {
                    const newUser = mappingFunction(body.data.appUserById);
                    setUser(newUser);
                    setBreadcrumbs(oldValue => [ ...oldValue ].map((m, i) => i === oldValue.length - 1 ? { text: `${[newUser.firstName, newUser.surname].join(' ')}` } : m));
                }
            });
        };
        
        getData();
        return () => cancellablePromise?.abortController.abort();
    }, [ id, dummyRefresh ]);

    const mappingFunction = (item: ApiUser): User => {
        return {
            userId: item.userId!,
            enabled: item.enabled,
            firstName: item.firstName!,
            surname: item.surname!,
            emailAddress: item.emailAddress!,
            branch: item.branch!,
            jobTitle: item.jobTitle!,
            location: item.location!,
            manager: item.manager ? {
                firstName: item.manager.firstName!,
                surname: item.manager.surname!,
                userId: item.manager.userId!
            } : null,
            mobileTelephone: item.mobileTelephone!,
            workExtension: item.workExtension!,
            workTelephone: item.workTelephone!,
            chartColour: item.chartColour!,
            teams: item.userRoleTeam!.reduce<Team[]>((acc, m) => {
                if (acc.findIndex(x => x.teamId === m.team!.teamId) === -1) {
                    acc.push({
                        name: m.team!.name,
                        teamId: m.team!.teamId!
                    });
                }
    
                return acc;
            }, [])
        };
    };

    return (
        <OrgContainer>
            { user &&<EditAndDisplay user={user} refresh={() => setDummyRefresh(dummyRefresh + 1)} /> }
            <AuditItemsByEntity type='AppUser' entityKey={[{ fieldName:'UserId', fieldValue:id }]} dummyRefresh={dummyRefresh} />
        </OrgContainer>
    );
};

export default UserView;