import React, { useEffect, useState } from 'react';

import { CardContent, Card } from '@mui/material';
import { SegmentMap } from '../Layout/DciBreadcrumb';
import { CancellablePromise, callDciApiCancellable } from '../../utils/callDciApi';
import { useAuth0 } from '@auth0/auth0-react';

import { PageTitle, TextProperty } from '../DciControls';

type Table = {
    enabled: boolean,
    extractId: number,
    extractSequence: number,
    extractTableId: number,
    orderColumn: string | undefined,
    predicate: string | undefined,
    preExtractProcess: string | undefined,
    queryOverride: string | undefined,
    tableName: string
};

type TableProps = {
    extractTableId: number,
    setBreadcrumbs: React.Dispatch<React.SetStateAction<SegmentMap[]>>
};

const Table = ({ extractTableId, setBreadcrumbs }: TableProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const [ table, setTable ] = useState<Table | null>(null);

    useEffect(() => {

        let cancellablePromise: CancellablePromise | null = null;
        const getData = async () => {
            const token = await getAccessTokenSilently();
            cancellablePromise = callDciApiCancellable(`{coreExtractTableById(tableId:${extractTableId}){enabled,extractId,extractSequence,extractTableId,orderColumn,predicate,preExtractProcess,queryOverride,tableName}}`, token);

            cancellablePromise.promise
            .then(body => {
                if (body.errors) {
                    alert(body.errors[0].message);
                } else {
                    const newTable = body.data.coreExtractTableById as Table;
                    setTable(newTable);
                    setBreadcrumbs(oldValue => [ ...oldValue ].map((m, i) => i === oldValue.length - 1 ? { text: newTable.tableName } : m));
                }
            });
        };
        
        getData();
        return () => cancellablePromise?.abortController.abort();
    }, [ extractTableId ]);

    return (
        <Card>
            <CardContent>
                { table &&
                <>
                    <PageTitle title={`Table: ${table.tableName}`} />
                    <TextProperty caption='Sequence' value={table.extractSequence.toString()} />
                    <TextProperty caption='Extract ID' value={table.extractId.toString()} />
                    <TextProperty caption='Extract Table ID' value={table.extractTableId.toString()} />
                    <TextProperty caption='Order Column' value={table.orderColumn ? table.orderColumn : '[Empty]'} />
                    <TextProperty caption='Predicate' value={table.predicate ? table.predicate : '[Empty]'} />
                    <TextProperty caption='Pre-extract Process' value={table.preExtractProcess ? table.preExtractProcess : '[Empty]'} />
                    <TextProperty caption='Query Override' value={table.queryOverride ? table.queryOverride : '[Empty]'} />
                    <TextProperty caption='Enalbed' value={table.enabled ? 'Yes' : 'No'} />
                </>
                }
            </CardContent>
        </Card>
    );
};

export { Table };