import React from 'react';

type HeadlineCardData = {
    title: string,
    value: string | null,
    fetching: boolean,
    link?: string
}

const HeadlineCardDataContext = React.createContext<HeadlineCardData | null>(null);
export { HeadlineCardDataContext };
export type { HeadlineCardData };