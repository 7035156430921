import { createTheme, ThemeProvider } from "@mui/material";
import React, { useMemo, useState } from "react";

type ColorMode = 'light' | 'dark';

const DciThemeContext = React.createContext({
    toggleColorMode: () => {},
    setColorMode: (mode: ColorMode) => {}
});

type DciThemeProviderProps = {
    children: JSX.Element
}

const DciThemeProvider = ({ children }: DciThemeProviderProps) => {
    const [ colorMode, setColorMode ] = useState<ColorMode>('light');
    const dciTheme = useMemo(() => ({
        toggleColorMode: () => setColorMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light')),
        setColorMode: (mode: ColorMode) => setColorMode(mode)
    }), []);
    
    const theme = useMemo(() => createTheme({
        palette: {
            mode: colorMode,
            background: {
                default: colorMode === 'light' ? '#fff' : '#152141',
                paper: colorMode === 'light' ? '#fff' : '#152141'
            },
            primary: {
                main: colorMode === 'light' ? '#243151' : '#5a9ae8',
            },
            secondary: {
                main: '#93c01f'
            },
        }
    }), [ colorMode ])
    
    return (
        <DciThemeContext.Provider value={dciTheme}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </DciThemeContext.Provider>
    )
}

export { DciThemeContext, DciThemeProvider }
export type { ColorMode }