import { Auth0Client } from '@auth0/auth0-spa-js';
import { settings } from '../../settings';

const authClient = new Auth0Client({
    domain: settings.AUTH_DOMAIN,
    client_id: settings.AUTH_CLIENTID,
    audience: 'https://api.wealth-dci.com/',
    redirect_uri: window.location.origin
});

export default authClient;