import React, { useState } from 'react';
import {
    Card,
    CardContent,
    CircularProgress,
    Typography
} from '@mui/material';
import { Flag } from '@mui/icons-material';
import { encodeURI } from 'js-base64';
import { ConditionalLink } from '../ConditionalLink';
import { ActiveWorkItemCountByRegulatoryImpactDataContext } from '../DataProviders/ActiveWorkItemCountByRegulatoryImpactDataContext';
import { PARAMETER_TYPE, QueryParameter } from '../../GraphQLShared';
import { ChartStoreContext, createChartStore } from './Store/ChartStore';
import { NewChart } from './NewChart';

interface ActiveWorkItemCountByRegulatoryImpactCardProps {
    workQueueIds: number[],
    teamIds: number[]
}

const ActiveWorkItemCountByRegulatoryImpactCard = ({ workQueueIds, teamIds }: ActiveWorkItemCountByRegulatoryImpactCardProps) => {
    const data = React.useContext(ActiveWorkItemCountByRegulatoryImpactDataContext);

    const reportLink = (regImpactValue: number, label: string) => {
        var reportParameters = {
            title: `Active Work Items with Regulatory Impact: ${label}`,
            parameters: [
                {
                    name: "fixedFilter.regulatoryImpactId",
                    type: PARAMETER_TYPE.NUMBER,
                    value: [ regImpactValue ]
                },
                {
                    name: "fixedFilter.workItemStatusId",
                    type: PARAMETER_TYPE.NUMBER,
                    value: [ 1, 2, 3, 4, 6, 8 ]
                }
            ] as QueryParameter[]
        };

        if (workQueueIds.length > 0) {
            reportParameters.parameters.push({
                name: 'fixedFilter.workQueueId',
                type: PARAMETER_TYPE.NUMBER,
                value: workQueueIds
            });
        }

        if (teamIds.length > 0) {
            reportParameters.parameters.push({
                name: 'fixedFilter.teamId',
                type: PARAMETER_TYPE.NUMBER,
                value: teamIds
            });
        }

        const base64 = encodeURI(JSON.stringify(reportParameters));
        return `/workitems?q=${base64}`;
    };

    return data
      ? <Card>
            <CardContent>
                <Typography gutterBottom variant="h6">Active Work Items by Regulatory Impact</Typography>
                { data.fetching
                    ? <CircularProgress />
                    : data.rows.map(m => 
                        <ConditionalLink key={m.regulatoryImpactValue} link={reportLink(m.regulatoryImpactValue, m.label)}>
                            <Typography style={{ marginBottom:10 }}><Flag style={{ color:m.colour, marginBottom:-6 }} /> = {m.count}</Typography>
                        </ConditionalLink>)
                }
            </CardContent>
        </Card> 
      : <p>No data available.</p>;
};

export { ActiveWorkItemCountByRegulatoryImpactCard };