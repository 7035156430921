import { useNavigate } from "react-router-dom";
import dciPaths from "../utils/dciPaths";
import { createTableStore, TableStoreContext } from "./Table/Stores/TableStore";
import { StandardTextCell, Table } from "./Table/Table";
import { TableContainer } from "./Table/TableContainer";
import { useState } from "react";
import { PARAMETER_TYPE } from "../GraphQLShared";
import { format, parseISO } from "date-fns";
import { TableColumn } from "./Filtering/types";
import { TableCell } from "@mui/material";

type AuditItem = {
    auditId: number,
    ModifiedAt: Date,
    ModifiedByName: string,
    Message: string
}

interface EntityIdDescriptor {
    fieldName: string
    fieldValue: number
}

const mappingFunction = (row: any): AuditItem => {
    const auditContent = JSON.parse(row.auditContent);

    return {
        auditId: row.auditId,
        ...auditContent,
        ModifiedAt: parseISO(auditContent.ModifiedAt)
    };
}

const columns: TableColumn[] = [
    {
        displayName: "Timestamp",
        selector: "ModifiedAt",
        sortable: false,
        render: (row: AuditItem) => <StandardTextCell cellKey='ModifiedAt'>{format(row.ModifiedAt, 'yyyy-MM-dd HH:mm:ss')}</StandardTextCell>,
        style: { width:'200px' }
    },
    {
        displayName: 'User',
        selector: 'ModifiedByName',
        sortable: false,
        style: { width:'200px' }
    },
    {
        displayName: 'Message',
        selector: 'Message',
        sortable: false,
    }
];

const createStore = (type: string, entityKey: EntityIdDescriptor[]) => createTableStore(
    {
        graphQLQueryName: 'auditItemsByEntity',
        graphQLQueryColumns: '{auditId,auditContent}',
        idFromRow: (row: AuditItem) => row.auditId,
        uniqueSortColumn: 'auditId'
    }, 
    {
        paged: true,
        mappingFunction: mappingFunction,
        sortOrder: [{ column:'auditId', direction:'DESC' }],
        fixedParameters: [
            {
                name: 'type',
                type: PARAMETER_TYPE.RAW_STRING,
                value: type
            },
            {
                name: 'key',
                type: PARAMETER_TYPE.RAW_JSON,
                value: entityKey
            }
        ]
    });

interface AuditListProps {
    type: string
    entityKey: EntityIdDescriptor[]
}

const AuditList = ({ type, entityKey }: AuditListProps) => {
    const [ store ] = useState(() => createStore(type, entityKey));
    const navigate = useNavigate();
    const onRowClick = (row: AuditItem) => navigate(dciPaths.audit.buildLink(row.auditId));

    return (
        <TableStoreContext.Provider value={store}>
            <TableContainer
                title='Audit'
                paged
                enableGlobalSelectAll
                style={{ height:'100%', width:'100%' }}
                enableExport
            >
                <Table
                    uniqueSortColumn='auditId'
                    columns={columns}
                    idFromValue={(row: AuditItem) => row.auditId}
                    onRowClick={onRowClick}
                />
            </TableContainer>
        </TableStoreContext.Provider>
    )
}

export { AuditList }