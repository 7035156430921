import { withAuthenticationRequired } from "@auth0/auth0-react";
import { LinearProgress, Paper } from "@mui/material";
import React, { ComponentType } from "react";
import { DciLogo } from "./Components/Layout/DciLogo";

interface ProtectedRouteProps {
    component: ComponentType;
}

const LoadSplash = () => {
    return (
        <Paper style={{ height:'100vh', display:'flex', justifyContent:'center', alignContent:'center', flexDirection:'column' }}>
            <div style={{ width:'300px', margin: '25px auto' }}>
                <DciLogo />
            </div>
            <div style={{ width: '500px', margin: '0px auto' }}>
                <LinearProgress variant='determinate' value={0} color={'secondary'} />
                <p>Checking login status...</p>
            </div>
        </Paper>
    )
}
    
export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component }) => {
    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => <LoadSplash />,
    });
    
    return <Component />;
};