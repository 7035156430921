import {
    Typography
} from '@mui/material';
import ScreenCapture from './Images/RuleViewLayout.png';

const WNRuleViewLayout = () => {
    return (
        <>
            <Typography variant='h5'>Improved Rule View Layout</Typography>
            <Typography variant='body1'>The Rule View has received an update to its layout in order to improve usability with each piece of functionality appearing in its own tab.</Typography>
            <img style={{ display:'block', margin:'20px auto' }} src={ScreenCapture} alt="Rule View screen capture" />
        </>
    )
}

export { WNRuleViewLayout }