import React, { useState } from 'react';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import callDciApi from '../../utils/callDciApi';

type AddRuleGroupDialogProps = {
    isOpen: boolean,
    close: (refresh: boolean) => void
};

const AddRuleGroupDialog = ({ isOpen, close }: AddRuleGroupDialogProps) => {
    const [ ruleGroupName, setRuleGroupName ] = useState('');
    const { getAccessTokenSilently } = useAuth0();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const onSave = async () => {
        const token = await getAccessTokenSilently();
        callDciApi(`mutation{createRuleGroup(name:"${ruleGroupName}"){ruleGroupId}}`, token)
        .then(body => {
            if (!body.errors) {
                close(true);
            }
        })
        .catch(error => {
            console.error(`[AddRuleGroupDialog] createRuleGroup: ${error}`);
        });
    };

    return (
        <Dialog open={isOpen} fullScreen={fullScreen} maxWidth={'sm'} fullWidth aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Create Rule Group</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Type in a name for the new Rule Group"
                    type="text"
                    fullWidth
                    variant={'outlined'}
                    value={ruleGroupName}
                    onChange={(event) => setRuleGroupName(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close(false)}>Cancel</Button>
                <Button disabled={ruleGroupName === ''} onClick={onSave}>Create</Button>
            </DialogActions>
        </Dialog>
    );
};

export { AddRuleGroupDialog };