import { 
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import { FilterControlProps } from "./types";
import { FilterUrlValueDeserializer, FilterUrlValueSerializer } from "../Table/UrlRestorer";
import { Box } from "@mui/system";
import { addDays, format, parseISO } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers";
import { FILTER_TYPE, FilterToParameterConverter, FilterUrlSerializer, registerFilterUrlSerializer, registerParameterConverter } from "./FilterRepository";
import { PARAMETER_TYPE } from "../../GraphQLShared";

type DateComparator = '<=' | '<' | '=' | '>' | '>=';

interface DateSelection {
    comparator: DateComparator
    date: Date
}

const DateFilter = ({ editMode, value, setValue }: FilterControlProps) => {
    if (editMode) {
        return <Box>
            <Select
                value={value.comparator}
                onChange={e => setValue({ ...value, comparator: e.target.value }, true, false)}
                MenuProps={{ disablePortal: true }}
                size='small'
                sx={theme => ({
                    width: '65px',
                    borderRadius: 0,
                    marginRight:'5px',
                    height:'26px',
                    '& > input': {
                        border: 0,
                        padding:'2px 6px',
                        fontSize:'14px'
                    },
                    '& fieldset': {
                        border: `1px solid ${theme.palette.text.primary}`
                    },
                })}
            >
                <MenuItem value='<='>&lt;=</MenuItem>
                <MenuItem value='<'>&lt;</MenuItem>
                <MenuItem value='='>=</MenuItem>
                <MenuItem value='>'>&gt;</MenuItem>
                <MenuItem value='>='>&gt;=</MenuItem>
            </Select>
            <DatePicker
                format='dd/MM/yyyy'
                views={['year', 'month', 'day']}
                value={value.date}
                disableFuture
                onChange={newDate => setValue({ ...value, date: newDate }, true, false)}
                slotProps={{
                    textField: {
                      sx: theme => ({
                        height: '26px',
                        marginRight: '4px',
                        borderRadius: '0px',
                        '& input': {
                            padding: '3px 6px',
                            fontSize: '14px'
                        },
                        '& fieldset': {
                            borderRadius: '0px',
                            border: `1px ${theme.palette.text.primary} solid`
                        }
                      })
                    }
                  }}
            />
        </Box>
    }

    return (
        <Typography style={{
            padding:'0px 5px',
            fontSize:'14px',
            maxWidth:'150px',
            overflow:'hidden',
            textOverflow:'ellipsis',
            whiteSpace:'nowrap',
            fontStyle:!value || value === '' ? 'italic' : 'inherit'
        }}>
            {`${value.comparator} ${format(value.date, 'yyyy-MM-dd')}`}
        </Typography>
    )
}

const DateFilterDefaultValue: DateSelection = {
    comparator: '=',
    date: new Date()
};

const comparatorToGraphQlMapping = {
    '<=': 'atOrBefore',
    '<': 'before',
    '=': 'atOrAfter', // TODO: Needs looking at
    '>': 'after',
    '>=': 'atOrAfter'
};

const graphQlToComparatorMapping = {
    'lte': '<=',
    'lt': '<',
    'eq': '=',
    'gt': '>',
    'gte': '>='
};

const DateFilterUrlValueSerializer: FilterUrlValueSerializer = filterNode => {
    if (!filterNode.valueIsValid) {
        return null;
    }

    const value = filterNode.value as DateSelection;
    return `${comparatorToGraphQlMapping[value.comparator]}|${format(value.date, 'yyyy-MM-dd')}`;
}

const DateFilterUrlValueDeserializer: FilterUrlValueDeserializer = async value => {
    const regex = /^(lte|lt|eq|gte|gt)|(\d{4}-\d{2}-\d{2})$/;

    try {
        const matches = regex.exec(value);
        if (matches === null)
        {
            return DateFilterDefaultValue;
        }

        const urlDate = parseISO(matches[2]);
        if (isNaN(urlDate.getTime())) {
            console.log(`Invalid date value passed in URL. Date format incorrect.`);
            return DateFilterDefaultValue;
        }

        return {
            comparator: matches[1],
            date: urlDate
        } as DateSelection;
    } catch (error) {
        console.log(`Invalid date value passed in URL. Returned error: ${error}`);
        // TODO: Should this somehow inform the sender the value has changed so the URL can be updated to the actually set value?
        return DateFilterDefaultValue;
    }
}

const filterDateToParameterDate = (value: DateSelection) => {
    const parameterValue: any = {};
    switch(value.comparator) {
        case "<=":
            parameterValue.onOrBefore = value.date;
            break;
        case "<":
            parameterValue.onOrBefore = addDays(value.date, 1);
            break;
        case "=":
            parameterValue.on = [ value.date ]
            break;
        case ">":
            parameterValue.onOrAfter = addDays(value.date, -1);
            break;
        case ">=":
            parameterValue.onOrAfter = value.date;
            break;
    }

    return parameterValue;
}

const converter: FilterToParameterConverter = (name: string, value: DateSelection) => ({
    name: name,
    type: PARAMETER_TYPE.DATE,
    value: filterDateToParameterDate(value)
});

registerParameterConverter(FILTER_TYPE.DATE, converter);

const comparatorToUrl = (comparator: DateComparator) => {
    switch (comparator) {
        case '<=': return 'lte';
        case '<': return 'lt';
        case '=': return 'eq';
        case '>': return 'gt';
        case '>=': return 'gte';
    }
}

const urlToComparator = (url: string): DateComparator | null => {
    switch (url) {
        case 'lte': return '<=';
        case 'lt': return '<';
        case 'eq': return '=';
        case 'gt': return '>';
        case 'gte': return '>=';
        default: return null;
    }
}

const serializer: FilterUrlSerializer = {
    serialize: (f: DateSelection) => `${comparatorToUrl(f.comparator)}:${format(f.date, 'yyyy-MM-dd')}`,
    deserialize: async s => {
        const matches = s.match(/^(lte|lt|eq|gt|gte):(\d{4}-\d{2}-\d{2})$/);
        if (matches === null) {
            return null;
        }

        const comparator = urlToComparator(matches[1]);
        if (comparator === null) {
            return null;
        }

        const date = parseISO(matches[2]);
        if (isNaN(date.getDate())) {
            return null;
        }
        
        return {
            comparator: comparator,
            date: date
        } as DateSelection
    }
}

registerFilterUrlSerializer(FILTER_TYPE.DATE, serializer);

export {
    DateFilter,
    DateFilterDefaultValue,
    DateFilterUrlValueSerializer,
    DateFilterUrlValueDeserializer
}