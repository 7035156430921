import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Paper, Select, Stack, TextField, Typography, styled } from "@mui/material";
import { useChartState } from "./Store/ChartStore"
import { LineChart, LineItemIdentifier } from "@mui/x-charts";
import DciLink from "../DciLink";
import { exportImage } from "./ImageExport";
import { CSSProperties, useEffect, useRef, useState } from "react";
// import { Line } from "react-chartjs-2";
// import Color from "color";

const MessageContainer = styled(Paper)`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    height:100%;
`;

interface ExportDialogProps {
    isOpen: boolean
    width: number
    fileType: string
    setFileType: (fileType: string) => void
    setWidth: (width: number) => void
    height: number
    setHeight: (width: number) => void
    closeDialog: () => void
    exportChart: () => void
}

const ExportDialog = ({ isOpen, width, setWidth, height, setHeight, fileType, setFileType, closeDialog, exportChart }: ExportDialogProps) => {
    return <Dialog
        fullWidth
        maxWidth='md'
        open={isOpen}
    >
        <DialogTitle>Export Chart</DialogTitle>
        <DialogContent style={{ height:'400px' }}>
            <div style={{ display:'flex', flexDirection:'column', height:'100%' }}>
                <Select
                    value={fileType}
                    onChange={e => setFileType(e.target.value)}
                >
                    <MenuItem value='PNG'>PNG</MenuItem>
                    <MenuItem value='JPG'>JPG</MenuItem>
                </Select>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Width"
                    type='number'
                    fullWidth
                    variant={'outlined'}
                    value={width}
                    onChange={(event) => setWidth(parseInt(event.target.value))}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Height"
                    type='number'
                    fullWidth
                    variant={'outlined'}
                    value={height}
                    onChange={(event) => setHeight(parseInt(event.target.value))}
                />
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={closeDialog}>Cancel</Button>
            <Button onClick={()=> { exportChart(); closeDialog(); }}>Export</Button>
        </DialogActions>
    </Dialog>
}

const exportChart = (svg: SVGSVGElement, fileType: string, width: number, height: number) => {
    exportImage(svg, fileType, width, height);
}

const openDialog = (svg: SVGSVGElement, setDialogIsOpen: (o: boolean) => void, setWidth: (w: number) => void, setHeight: (h: number) => void) => {
    setWidth(svg.clientWidth);
    setHeight(svg.clientHeight);
    setDialogIsOpen(true);
}

// **NOTE** This is in the library but not exported for some reason, if that changes, remove this
type AxisData = {
    dataIndex: number;
    axisValue?: number | Date | string;
    seriesValues: Record<string, number | null | undefined>;
}

interface NewChartProps {
    allowExport?: boolean
    onMarkClick?: (event: React.MouseEvent<SVGElement, MouseEvent>, lineItemIdentifier: LineItemIdentifier) => void
    onAxisClick?: (event: MouseEvent, data: AxisData | null) => void
    style?: CSSProperties
}

const NewChart = ({ allowExport = false, onMarkClick, onAxisClick, style = {} }: NewChartProps) => {
    const [ dialogIsOpen, setDialogIsOpen ] = useState(false);
    const [ fileType, setFileType ] = useState('PNG');
    const [ width, setWidth ] = useState(1000);
    const [ height, setHeight ] = useState(700);

    const ref = useRef<SVGSVGElement>(null);
    const chartData = useChartState(s => s.chartData);
    const initialised = useChartState(s => s.initialised);
    const updateData = useChartState(s => s.updateChartData);

    useEffect(() => {
        if (!initialised) {
            updateData();
        }
    }, []);

    // TODO: Replace isReady for isFetching (reversing logic)
    if (chartData.isFetching || chartData.isError || chartData.isCancelled || chartData.data === null) {
        let message = 'No data to display';
        let showRetry = false;

        if (chartData.isFetching) {
            message = 'Loading...';
        } else if (chartData.isError) {
            message = 'An error has occurred';
            showRetry = true;
        } else if (chartData.isCancelled) {
            message = 'Loading was cancelled';
            showRetry = true;
        }

        return <MessageContainer>
            <p style={{ margin:'5px' }}>{message}</p>
            { showRetry ? <DciLink onClick={() => updateData()}>Retry</DciLink> : null }
        </MessageContainer>
    }
    
    if (chartData.isError || chartData.data === null) {
        return <ChartError />
    }

    return <Stack style={{ width:'100%', height:'100%', ...style }}>
            { allowExport && <Stack style={{ paddingRight:'10px' }} direction='row-reverse'>
                <Button size='small' onClick={() => exportImage(ref.current!, 'PNG', ref.current!.clientWidth, ref.current!.clientHeight)}>Export</Button>
                {/* <ExportDialog
                    isOpen={dialogIsOpen}
                    closeDialog={() => setDialogIsOpen(false)}
                    width={width}
                    setWidth={setWidth}
                    height={height}
                    setHeight={setHeight}
                    fileType={fileType}
                    setFileType={setFileType}
                    exportChart={() => exportChart(ref.current!, fileType, width, height)}
                /> */}
            </Stack>}
            <LineChart
                 slotProps={{
                    legend: {
                        itemMarkHeight: 10,
                        itemMarkWidth: 16,
                        labelStyle: {
                            fontSize: 13,
                        },
                    },
                }}
                ref={ref}
                onMarkClick={onMarkClick}
                onAxisClick={onAxisClick}
                xAxis={[{
                    scaleType: 'point',
                    data: chartData.data.labels
                }]}
                yAxis={[{
                    valueFormatter: (value, context) => {
                        if (`${value}`.includes('.')) {
                            return '';
                        }

                        return value;
                    }
                }]}
                series={chartData.data.dataSets.map((ds, i) => ({
                    data: ds.data,
                    color: ds.colour,
                    label: ds.label,
                    id: ds.groupId,
                    curve: 'monotoneX'
                }))}
                grid={{ horizontal: true, vertical: true }}
            />
        </Stack>
}

const ChartError = () => <Typography>Error</Typography>

export { NewChart }
export type { AxisData }