import { settings } from '../settings';

// TODO: If you are moving any more methods over to this usage, don't! Set up dedicated API methods instead
// using the new outside Auth0 interface
const postDciApi = async (queryOrMutation: string, token: string) => {
    const url = `${settings.DCI_API_SERVER}/graphql`;
    return fetch(url, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query: queryOrMutation })
    })
    .then(response => {
        if (!response.ok) {
            throw Error(response.statusText);
        }

        return response.json();
    });
}

// TODO: Deal with Auth0 ourselves instead of getting token passed in
export default async function callDciApi(queryOrMutation: string, token: string) {
    const url = `${settings.DCI_API_SERVER}/graphql?query=${encodeURIComponent(queryOrMutation)}`;
    return fetch(url, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        if (!response.ok) {
            throw Error(response.statusText);
        }

        return response.json();
    });
}

const getDciApiResponse = async (queryOrMutation: string, token: string) => {
    const url = `${settings.DCI_API_SERVER}/graphql?query=${encodeURIComponent(queryOrMutation)}`;
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return await response.json();
}

export type CancellablePromise<T = any> = {
    abortController: AbortController,
    promise: Promise<T>
};

const dciApiExportData = (query: string, token: string): CancellablePromise<Blob | null> => {
    const controller = new AbortController();
    const { signal } = controller;
    const url = `${settings.DCI_API_SERVER}/api/export`;

    const promise = fetch(url, {
        method: 'POST',
        body: JSON.stringify({ query }),
        signal,
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    }).then(response => {
        if (!response.ok) {
            throw Error(response.statusText);
        }

        return response.blob();
    })
    .catch((error) => {
        if (error.name === 'AbortError') {
            console.log('Request was cancelled');
            return null;
        } else {
            throw(error);
        }
    });

    return {
        abortController: controller,
        promise
    };
};

type ErrorLocation = {
    line: number,
    column: number
}

type Error = {
    message: string,
    locations : ErrorLocation[]
}

type Data<T = any> = {
    [key: string]: T
}

type ApiResponse<T = any> = {
    data?: Data
    errors?: Error[]
}

const callDciApiCancellable = <T = any>(queryOrMutation: string, token: string, uid?: string): CancellablePromise<T> => {
    const controller = new AbortController();
    const { signal } = controller;
    const url = `${settings.DCI_API_SERVER}/graphql?query=${encodeURIComponent(queryOrMutation)}`;

    if (uid) {
        console.debug(`${uid}|${Date.now()}|Fetching...`);
    }

    const promise = fetch(url, {
        signal: signal,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        if (uid) {
            console.debug(`${uid}|${Date.now()}|First response...`);
        }

        // Commented on 05/12/2022 as part of new table filtering design. Not sure if adversely affects other parts of the app
        // if (!response.ok) {
        //     throw Error(response.statusText);
        // }

        return response.json();
    })
    .catch((error) => {
        if (uid) {
            console.debug(`${uid}|${Date.now()}|Catch...`);
        }
        
        if (error.name === 'AbortError') {
            console.log(`${uid}|${Date.now()}|Request was cancelled`);
        } else {
            throw(error);
        }
    });

    return {
        abortController: controller,
        promise
    };
};

const callDciApiCancellableNew = <T = any>(queryOrMutation: string, token: string, uid?: string): CancellablePromise<T> => {
    const controller = new AbortController();
    const { signal } = controller;
    const url = `${settings.DCI_API_SERVER}/graphql`;
    
    const promise = fetch(url, {
        method: 'POST',
        signal: signal,
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query: queryOrMutation })
    })
    .then(response => {
        return response.json();
    })
    .catch((error) => {
        return {
            errors: [ error.name === 'AbortError' ? cancelledMessage : `Unknown error. Name: ${error.name}` ]
        }
    });

    return {
        abortController: controller,
        promise
    };
};

const callApi = <T>(
    dataSelector: string, 
    queryOrMutation: string, 
    token: string, 
    successHandler: (data: T) => void,
    apiErrorHandler?: (error: Error[]) => void
) => {
    const controller = new AbortController();
    const { signal } = controller;
    const url = `${settings.DCI_API_SERVER}/graphql?query=${encodeURIComponent(queryOrMutation)}`;

    const promise = fetch(url, {
        signal,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => response.json())
    .then(body => {
        const response = body as ApiResponse<T>;
        if (response.errors) {
            if (apiErrorHandler) {
                apiErrorHandler(response.errors);
            } else {
                console.error('Unhandle API errors.');
            }
        } else {
            successHandler(response.data![dataSelector]);
        }
    })
    .catch(error => {
        if (error.name === 'AbortError') {
            console.log(cancelledMessage);
        } else {
            throw(error);
        }
    });

    return {
        abortController: controller,
        promise: promise
    } as CancellablePromise;
}

const cancelledMessage = 'Request was cancelled.';

export { callDciApiCancellable, callDciApiCancellableNew, cancelledMessage, dciApiExportData, callApi, postDciApi, getDciApiResponse };
