import React, { CSSProperties } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { getDisplayName } from '../utils/getDisplayName';

// const useStyles = makeStyles(() => ({
//     root: {
//         marginBottom:10
//     }
// }));

type Field<TItem> = {
    selector?: string,
    displayName?: string,
    missingValue?: string,
    render?: (item: TItem) => JSX.Element,

    // TODO: Is this needed? It seems to duplicate the render functionality
    valueResolver?: (item: TItem) => string | JSX.Element
}

type ItemDisplayProps<TItem> = {
    item: TItem | null,
    fields: Field<TItem>[],
    className?: string,
    style?: CSSProperties,
    Controls?: React.ComponentType
}

function ItemDisplay<TItem extends { [key: string]: any }> ({ item, fields, className, style, Controls }: ItemDisplayProps<TItem>) {
    if (item === null) {
        return null;
    }

    // Property is valid if it has a value of null or is of type string, number or boolean
    const propertyIsValidType = (propertyName: string) => 
        typeof (['string', 'number', 'boolean'].find(x => x === typeof(item[propertyName]))) !== 'undefined' || item[propertyName] === null;

    let fieldLayouts: Field<TItem>[] = [];
    if (fields === null) {
        Object.keys(item).forEach(m => {
            if (propertyIsValidType(m)) {
                fieldLayouts.push({
                    selector: m,
                    displayName: getDisplayName(m)
                });
            }
        });
    }
    else {
        fieldLayouts = fields.map(m => ({
            ...m,
            displayName: m.displayName ?? (!m.render && m.selector ? getDisplayName(m.selector) : undefined)
        }));
    }

    return (
        <Card style={{ marginBottom:'10px', ...style }}>
            <CardContent>
                { Controls && <Box style={{ float:'right' }}><Controls /></Box> }
                { fieldLayouts.map((field, index) => 
                    <React.Fragment key={index}>
                        { field.render ? field.render(item) : (
                            <>
                                <Typography variant='caption' display='block'>{field.displayName}</Typography>
                                <Typography paragraph color="textSecondary" variant="body2">{ field.valueResolver 
                                    // ? field.valueResolver(item) 
                                    // : field.selector 
                                    //     ? item[field.selector].toString() 
                                    //     : !field.missingValue 
                                    //         ? '[empty]' 
                                    //         : field.missingValue}</Typography>
                                    ? field.valueResolver(item) 
                                    : field.selector && item[field.selector]
                                        ? item[field.selector].toString() 
                                        : field.missingValue 
                                            ? '[empty]' 
                                            : field.missingValue}
                                </Typography>
                            </>
                        )}
                    </React.Fragment>
                )}
            </CardContent>
        </Card>
    )
}

export { ItemDisplay }
export type { Field }