import { 
    alpha,
    Box,
    ClickAwayListener, 
    IconButton, 
    Stack 
} from "@mui/material";
import { HighlightOff, Report } from '@mui/icons-material';

interface FilterPillProps {
    editable?: boolean
    editMode: boolean
    setEditMode: (value: boolean) => void
    removable?: boolean
    valid: boolean
    onRemove?: () => void
    onClick?: () => void
    onClickAway?: () => void
    children?: React.ReactNode
}

const FilterPill = ({ editable, editMode, setEditMode, removable, valid, onClickAway, onRemove, children }: FilterPillProps) => {
    return (
        <ClickAwayListener onClickAway={editMode ? () => onClickAway?.() : () => {}}>
            <Stack
                direction='row'
                onClick={() => !editMode && editable && setEditMode(true)}
                sx={theme => ({
                    cursor: editMode ? 'auto' : 'pointer',
                    padding:`2px 2px 2px ${(editMode || valid) ? '12px' : '2px'}`,
                    display:'inline-flex',
                    alignItems:'center',
                    border:`1px ${theme.palette.text.primary} solid`,
                    borderColor:'primary',
                    borderRadius:'25px',
                    '&:hover': {
                        borderColor: theme.palette.action.disabledBackground,
                        backgroundColor: editMode ? 'initial' : alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity)
                    }
                })}
            >
                { !valid && !editMode && <Report color='error' /> }
                <Box>
                    { children }
                </Box>
                { removable && <IconButton onClick={() => onRemove && onRemove()} style={{ padding:'0px' }} size='small'><HighlightOff /></IconButton> }
            </Stack>
        </ClickAwayListener>
    )
}

export { FilterPill }