import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from '@mui/material';
import { callDciApiCancellable, CancellablePromise } from '../utils/callDciApi';

type AuditItem = {
    auditId: number,
    ModifiedAt: Date,
    ModifiedByName: string,
    Message: string
}

type AuditEntityKey = {
    fieldName: string,
    fieldValue: string | number
}

type AuditItemsByEntityProps = {
    type: string,
    entityKey?: AuditEntityKey[],
    dummyRefresh?: any,
    style?: React.CSSProperties
}

const AuditItemsByEntity = ({ type, entityKey = [], dummyRefresh, style }: AuditItemsByEntityProps) => {
    const navigate = useNavigate();
    const [ auditItems, setAuditItems ] = useState<AuditItem[]>([]);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        let cancelled = false;
        let cancellablePromise: CancellablePromise | null = null;
        const getAuditItems = async () => {
            const token = await getAccessTokenSilently();
            if (cancelled) {
                return;
            }

            let query = `{auditItemsByEntity(type:"${type}"`;
            if (entityKey.length > 0) {
                const keyValue = entityKey.map(m => `{fieldName:"${m.fieldName}",fieldValue:${m.fieldValue}}`).join();
                query += `,key:[${keyValue}]`
            }

            query += '){nodes{auditId,auditContent}}}';
            if (cancelled) {
                return;
            }

            cancellablePromise = callDciApiCancellable(query, token);
            cancellablePromise.promise
            .then(body => {
                if (!body.errors) {
                    var a = body.data.auditItemsByEntity.nodes.map((m: any) => {
                        const auditContent = JSON.parse(m.auditContent);
                        return {
                            auditId: m.auditId,
                            ...auditContent,
                            ModifiedAt: parseISO(auditContent.ModifiedAt)
                        };
                    }) as AuditItem[];
                    setAuditItems(a);
                }
            })
            .catch(error => {
                console.error(`[Team] auditItemsByEntity: ${error}`);
            });
        };

        getAuditItems();
        return () => {
            cancelled = true;
            cancellablePromise?.abortController.abort();
        }
    }, [ getAccessTokenSilently, JSON.stringify(entityKey), type, dummyRefresh ]);

    return (
        <Paper style={style}>
            <Toolbar>
                <Typography style={{ flexGrow: 1 }} variant='h6'>Audit</Typography>
            </Toolbar>
            <TableContainer>
                <Table style={{tableLayout: 'auto'}} size='small'>
                    <TableHead>
                    <TableRow>
                        <TableCell>Timestamp</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell>Message</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {auditItems.map(m => 
                            <TableRow onClick={() => navigate(`/audit/${m.auditId}`)} key={m.auditId} hover style={{ cursor: "pointer" }}>
                                <TableCell>{format(m.ModifiedAt, 'yyyy-MM-dd HH:mm:ss')}</TableCell>
                                <TableCell>{m.ModifiedByName}</TableCell>
                                <TableCell>{m.Message}</TableCell>
                            </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export { AuditItemsByEntity }