import React from 'react';
import { ChartElement } from '../../types';
import { PieChartDataContext } from '../DataProviders/PieChartDataContext';

import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartEvent, ActiveElement, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useTheme } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend, Title);

type DoughnutChartProps = {
  onClick?: (chartElement: ChartElement | null) => void
};

const DoughnutChart = ({ onClick }: DoughnutChartProps) => {
    const chartData = React.useContext(PieChartDataContext);
    const theme = useTheme();

    const chartOnClick = (event: ChartEvent, elements: ActiveElement[], chart: ChartJS) => {
        if (onClick && event.native) {
          if (elements.length > 0) {
              // In reality elements only seems to return 1 element at most
              const firstElement = elements[0];
              onClick({
                  datasetIndex: firstElement.datasetIndex,
                  dataIndex: firstElement.index
              });
          }
        }
    };

    if (chartData === null) {
        return (
            <div style={{ height:'100%', display:'flex', justifyContent:'center', alignItems:'center' }}>
                { chartData === null && <p>No chart data available.</p> }
            </div>
        );
    }

    return (
        <div style={{ width:'100%', height:'100%' }}>
            <Doughnut
                data={{
                    labels: chartData.series.map(m => m.label),
                    datasets: [{
                        data: chartData.series.map(m => m.data),
                        backgroundColor: chartData.series.map(m => m.colour),
                        borderWidth: 3,
                        borderColor: theme.palette.background.paper
                    }],

                }} 
                options={{
                    onClick: chartOnClick,
                    maintainAspectRatio: false,
                    plugins: {
                        title: {
                            display: true,
                            text: chartData.title,
                            color: theme.palette.text.primary
                        },
                        legend: {
                            labels: {
                                color: theme.palette.text.primary
                            }
                        }
                    }
                }} />
        </div>
    )
};

export { DoughnutChart };