import { useState } from 'react';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import callDciApi from '../../utils/callDciApi';

interface AddRoleDialogProps {
    isOpen: boolean,
    close: (refresh: boolean) => void
}

const AddRoleDialog = ({ isOpen, close }: AddRoleDialogProps) => {
    const [ roleName, setRoleName ] = useState('');
    const { getAccessTokenSilently } = useAuth0();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const onSave = async () => {
        const token = await getAccessTokenSilently();
        callDciApi(`mutation{createRole(name:"${roleName}"){roleId}}`, token)
        .then(body => {
            if (!body.errors) {
                close(true);
                setRoleName('');
            } else {
                alert(body.errors[0].message);
            }
        })
        .catch(error => {
            console.error(`[AddRoleDialog] createRuleGroup: ${error}`);
        });
    };

    return (
        <Dialog open={isOpen} fullScreen={fullScreen} maxWidth={'sm'} fullWidth aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Create Role</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Type in a name for the new role"
                    type="text"
                    fullWidth
                    variant={'outlined'}
                    value={roleName}
                    onChange={(event) => setRoleName(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close(false)}>Cancel</Button>
                <Button disabled={roleName === ''} onClick={onSave}>Create</Button>
            </DialogActions>
        </Dialog>
    );
};

export { AddRoleDialog }