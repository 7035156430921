import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './Components/Layout/App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';
import DciAuthProvider from './Components/Auth/DciAuthProvider';
import authClient from './Components/Auth/auth-client';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <DciAuthProvider auth0Client={authClient}>
      <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <App />
      </SnackbarProvider>
    </DciAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
