import { useState } from 'react';
import {
    IconButton,
    TableCell
} from '@mui/material';
import { AddBox, Check, Clear } from '@mui/icons-material';
import { AddRuleGroupDialog } from './AddRuleGroupDialog';
import { useNavigate } from 'react-router-dom';
import dciPaths from '../../utils/dciPaths';
import OrgContainer from '../Organisation/OrgContainer';
import { TableStoreContext, createTableStore } from '../Table/Stores/TableStore';
import { TableContainer } from '../Table/TableContainer';
import { Table } from '../Table/Table';
import { TableColumn } from '../Filtering/types';

type RuleGroupRow = {
    ruleGroupId: number,
    name: string
};

const columns: TableColumn[] = [
    {
        displayName: 'Name',
        selector: 'name'
    },
    {
        displayName: "Enabled",
        selector: "enabled",
        render: row => (<TableCell key={'enabled'} style={{ textAlign: 'center' }} padding={'checkbox'}>{row.enabled ? <Check /> : <Clear />}</TableCell>)
    }
];

const createStore = () => createTableStore({
    graphQLQueryName: 'ruleGroups',
    graphQLQueryColumns: '{ruleGroupId,name,enabled}',
    idFromRow: (row: RuleGroupRow) => row.ruleGroupId,
    uniqueSortColumn: 'ruleGroupId'
},
{
    paged: true
});

const RuleGroups = () => {
    const [ store ] = useState(() => createStore())

    const navigate = useNavigate();
    const [ addRuleGroupDialogIsOpen, setAddRuleGroupDialogIsOpen ] = useState(false);
    const [ refresh, setRefresh ] = useState(0);
    
    const AddRuleGroupButton = () =>
        <>
            <IconButton onClick={() => setAddRuleGroupDialogIsOpen(true)} aria-label='add rule group' component='span'><AddBox /></IconButton>
            <AddRuleGroupDialog isOpen={addRuleGroupDialogIsOpen} close={close} />
        </>;

    const close = (refreshCaller: boolean) => {
        setAddRuleGroupDialogIsOpen(false);
        if (refreshCaller === true) {
            setRefresh(refresh + 1);
        }
    };

    return (
        <OrgContainer>
            <TableStoreContext.Provider value={store}>
                <TableContainer
                    title='Rule Groups'
                    paged
                    style={{ height:'100%', width:'100%' }}
                    enableExport
                    toolbarButtons={<AddRuleGroupButton />}
                >
                    <Table
                        uniqueSortColumn='ruleGroupId'
                        columns={columns}
                        idFromValue={row => row.ruleGroupId}
                        onRowClick={item => navigate(dciPaths.ruleGroup.buildLink(item.ruleGroupId))}
                    />
                </TableContainer>
            </TableStoreContext.Provider>
        </OrgContainer>
    );
}

export { RuleGroups };