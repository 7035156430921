import React from 'react';
import { Link } from 'react-router-dom';
import { WithChildren } from '../types/WithChildren';

type ConditionalLinkProps = WithChildren<{
    link?: string
}>;

const ConditionalLink = ({ link, children}: ConditionalLinkProps) => {
    return link ? <Link style={{ textDecoration:'none', color:'inherit' }} to={link}>{children}</Link> : children ? children : null;
};

export { ConditionalLink }