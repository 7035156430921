import React, { useEffect, useState } from 'react';
import { callDciApiCancellable, CancellablePromise } from '../../utils/callDciApi';
import { useAuth0 } from '@auth0/auth0-react';
import { PieChartDataContext } from './PieChartDataContext';
import { PieChartData } from '../../types';
import { WithChildren } from '../../types/WithChildren';
import { format } from 'date-fns';

type TopRulesByVolumeDataProviderProps = WithChildren<{
    start: Date,
    end: Date,
    limit: number,
    workQueueIds: number[],
    teamIds: number[],
}>;

const TopRulesByVolumeDataProvider = ({ start, end, limit, children, workQueueIds, teamIds }: TopRulesByVolumeDataProviderProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const [ data, setData ] = useState<PieChartData | null>(null);
    const [ loading, setLoading ] = useState(true);
    
    const startString = format(start, 'yyyy-MM-dd');
    const endString = format(end, 'yyyy-MM-dd');

    useEffect(() => {
        let mounted = true;
        let cancellablePromise: CancellablePromise | null = null;
        async function fetchData() {
            const token = await getAccessTokenSilently();
            
            if (mounted) {
                setLoading(true);
                cancellablePromise = callDciApiCancellable(`{totalWorkItemCountByRule(workQueueIds:[${workQueueIds.join(',')}],teamIds:[${teamIds.join(',')}],start:"${startString}",end:"${endString}",limit:${limit}){title,series{identifier,colour,data,label}}}`, token);
                cancellablePromise.promise
                .then(body => {
                    if (body.errors) {
                        console.error(body.errors[0].message);
                    } else {
                        setData(body.data.totalWorkItemCountByRule);
                        setLoading(false);
                    };
                });
            }            
        }

        fetchData();
        return () => {
            mounted = false;
            cancellablePromise?.abortController.abort();
        }
    }, [ start, end, limit, workQueueIds.length, teamIds.length ]);

    if (loading === true) {
        return (
            <div style={{ height:'100%', display:'flex', justifyContent:'center', alignItems:'center' }}>
                <p>Loading...</p>
            </div>
        )
    } else {
        return (
            <PieChartDataContext.Provider value={data}>
                { children }
            </PieChartDataContext.Provider>
        )
    }
};

export { TopRulesByVolumeDataProvider };