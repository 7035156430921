import React from 'react';

type ValueWithUnit = {
    value: string,
    unit: string
};

type CoreKpiValues = {
    numberOfRules: ValueWithUnit | null,
    numberOfDataChecks: ValueWithUnit | null,
    averageCreated: ValueWithUnit | null,
    averageCreatedRedRegImpact: ValueWithUnit | null,
    averageCreatedPriority1: ValueWithUnit | null,
    averageClosed: ValueWithUnit | null,
    averageClosedRedRegImpact: ValueWithUnit | null,
    averageClosedPriority1: ValueWithUnit | null,
    numOverrides: ValueWithUnit | null
};

const CoreKpiDataContext = React.createContext<CoreKpiValues | null>(null);
export { CoreKpiDataContext };
export type { CoreKpiValues };