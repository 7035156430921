import React from 'react';
import { ChartData } from "../../types";

type WithFetching<T> = {
    data: T,
    fetching: boolean
}

const DciChartDataContext = React.createContext<WithFetching<ChartData | null>  | null>(null);
export { DciChartDataContext };

export type { WithFetching }