import {
    Typography
} from '@mui/material';
import ScreenCapture from './Images/RuleStats.png';

const WNRuleStatistics = () => {
    return (
        <>
            <Typography variant='h5'>Rule Statistics</Typography>
            <Typography variant='body1'>The previous change has allowed us to provide a new place to view statistics for individual rules.</Typography>
            <img style={{ display:'block', margin:'20px auto' }} src={ScreenCapture} alt="Rule statistics screen capture" />
        </>
    )
}

export { WNRuleStatistics }