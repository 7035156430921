import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Autocomplete,
    TextField,
    Typography,
    Card,
    CardContent,
} from '@mui/material';
import { ControlButton } from './DciControls';

export default function ItemEdit({ item, fields, referenceData, onSave, saveErrors }) {
    const navigate = useNavigate();
    const [ newValue, setNewValue ] = useState({ ...item });

    const renderReadOnly = field => 
        <>
            <Typography variant='caption' display='block'>{field.displayName}</Typography>
            <Typography paragraph color="textSecondary" variant="body2">{field.valueResolver ? field.valueResolver(item) : item[field.selector] != null ? item[field.selector].toString() : !item.missingValue ? '[empty]' : item.missingValue}</Typography>
        </>;

    const renderTextField = field =>
        <TextField
            variant='standard'
            style={{ marginBottom:'12px' }}
            margin="dense"
            id={field.selector}
            label={field.displayName}
            type="text"
            fullWidth
            multiline={typeof(multiline) === 'boolean' ? field.multiline : false}
            value={newValue[field.selector]}
            onChange={(event) => setNewValue({ ...newValue, [field.selector]: event.target.value })}
        />;

    const renderAutocomplete = field =>
        <Autocomplete
            id={field.selector}
            options={referenceData[field.options]}
            getOptionLabel={field.getOptionLabel}
            isOptionEqualToValue={field.getOptionSelected}
            style={{ marginBottom:'10px' }}
            renderInput={(params) => <TextField {...params} label={field.displayName} />}
            value={newValue[field.selector]}
            onChange={(event, value) => setNewValue({ ...newValue, [field.selector]: value })}
        />;

    const typeRenderFunctions = {
        readonly: renderReadOnly,
        text: renderTextField,
        autocomplete: renderAutocomplete
    };

    const renderField = (m, i) =>
        <React.Fragment key={i}>
            {typeof(m.render) === 'function' ? m.render(item) : typeRenderFunctions[typeof(m.type) === 'string' ? m.type : 'readonly'](m)}
        </React.Fragment>;

    return (
        <Card>
            <CardContent>
                {fields.map((m, i) => renderField(m, i))}
                <ControlButton variant='contained' onClick={() => onSave(newValue)}>Save</ControlButton>
                <ControlButton variant='contained' onClick={() => navigate(-1)}>Cancel</ControlButton>
                {saveErrors && saveErrors.map((m, i) => <Typography key={i} style={{ color:'red', fontSize:'0.75rem' }}>{m.message}</Typography>)}
            </CardContent>
        </Card>
    );
};

ItemEdit.propTypes = {
    item: PropTypes.object.isRequired,
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            displayName: PropTypes.string,
            type: PropTypes.oneOf([ 'readonly', 'text', 'autocomplete' ]),
            value: PropTypes.any,
            selector: PropTypes.string,
            valueResolver: PropTypes.func,
            missingValue: PropTypes.string,

            // Text fields
            multiline: PropTypes.bool,

            // Autocomplete
            options: PropTypes.string,
            getOptionLabel: PropTypes.func,
            getOptionSelected: PropTypes.func,

            onSave: PropTypes.func
        })
    ),
    referenceData: PropTypes.objectOf(PropTypes.array)
};