import {
    Box,
    Checkbox,
    Stack,
    styled,
    Table as MuiTable,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel
} from '@mui/material';
import DciLink from '../DciLink';
import { useTableState } from './Stores/TableStore';
import { TableColumn } from '../Filtering/types';
import { AsyncData, ColumnSort, GlobalSelectAllState, RowId } from './Shared';

const MessageContainer = styled(Box)`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
`;

type TableProps = {
    onRowClick?: (row: any) => void,
    idFromValue: (row: any) => RowId,
    uniqueSortColumn: string,
    selectable?: boolean,
    columns: TableColumn[],
    size?: 'small' | 'medium'
}

const StandardTextCell = ({ cellKey, justify = 'normal', children }: { cellKey: string, justify?: string, children: React.ReactNode }) => 
    <TableCell key={cellKey}>
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '0px 10px',
                height: '50px',
                maxHeight: '50px',
                justifyContent: justify
            }}
        >
            <p style={{
                margin: '0px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical'
            }}>
                {children}
            </p>
        </Box>
</TableCell>

interface TableInnerProps {
    onRowClick?: (row: any) => void
    idFromValue: (row: any) => RowId
    uniqueSortColumn: string
    selectable?: boolean
    columns: TableColumn[]
    data: AsyncData
    updateData: (initialLoad: boolean, retainSelection: boolean) => void
    selectedData: any[]
    selectAllClick: () => void
    rowSelectClick: (row: any) => void
    selectionEnabled: boolean
    globalSelectAllState: GlobalSelectAllState
    sortOrder: ColumnSort[]
    toggleSortColumn: (column: string, uniqueSortColumn: string) => void
    clickable: boolean
    size?: 'small' | 'medium'
}

function TableInner({
    onRowClick,
    uniqueSortColumn,
    idFromValue, 
    selectable = false,
    columns,
    data,
    updateData,
    selectedData,
    selectAllClick,
    rowSelectClick,
    selectionEnabled,
    globalSelectAllState,
    sortOrder,
    toggleSortColumn,
    clickable,
    size = 'medium'
}: TableInnerProps) {
    type ColumnProps = {
        column: TableColumn,
        row: any
    }

    const Column = ({ column, row }: ColumnProps) => {
        if (column.render) {
            return column.render(row);
        }

        return (
            <TableCell key={column.selector}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0px 10px',
                        height: '50px',
                        maxHeight: '50px',
                        justifyContent: column.justify
                    }}
                >
                    <p style={{
                        margin: '0px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical'
                    }}>
                        {row[column.selector]}
                    </p>
                </Box>
            </TableCell>
        )
    }

    const onSortClick = (column: string) => toggleSortColumn(column, uniqueSortColumn);

    const getSortDirection = (column: string) => {
        const sortEntry = sortOrder.find(s => s.column === column);
        if (!sortEntry) {
            return undefined;
        }

        return sortEntry.direction === 'DESC' ? 'desc' : 'asc';
    }

    const renderHeaderColumn = (column: TableColumn) => {
        const direction = getSortDirection(column.sortable || column.selector);
        const canSort = column.sortable !== false;

        return (
            <TableCell
                key={column.selector}
                style={{ padding: '10px', ...column.style }}
                align={column.justify === 'center' ? 'center' : column.justify === 'flex-end' ? 'right' : 'left' } // Not pleasant - change this
            >
                <Stack direction='row'>
                    <span style={{ flexShrink:1, whiteSpace:'nowrap', textOverflow:'ellipsis', overflow:'hidden' }} >{ column.displayName }</span>
                    { canSort && <span style={{ width:'28px' }}>
                        <TableSortLabel
                            active={direction !== undefined}
                            direction={direction}
                            onClick={_event => onSortClick(typeof column.sortable === 'string' ? column.sortable : column.selector)}
                        />
                    </span> }
                </Stack>
            </TableCell>
        )
    }

    const onRowSelect = (event: React.MouseEvent, row: any) => {
        rowSelectClick(row);
        event.stopPropagation();
        event.preventDefault();
    }

    const renderRow = (row: any) => {
        const rowId = idFromValue(row);
        const rowIsSelected = globalSelectAllState === 'ACTIVE' || selectedData.findIndex(r => idFromValue(r) === rowId) !== -1;

        return (
            <TableRow
                onClick={_ => onRowClick ? onRowClick(row) : null} 
                key={idFromValue(row)} 
                hover={clickable}
                sx={{
                    cursor: clickable ? 'pointer' : 'inherit',
                    '& .MuiTableCell-root': {
                        padding: '0px'
                    }
                }}
            >
                { selectable ? <TableCell style={{ textAlign:'center' }} padding='checkbox'><Checkbox color='secondary' disabled={!selectionEnabled} checked={rowIsSelected} onClick={e => onRowSelect(e, row)} /></TableCell> : null }
                { columns.map(n => <Column key={n.selector} column={n} row={row} />) }
            </TableRow>
        )
    }

    return (
        <Stack direction='column' height={'100%'}>
            <MuiTable stickyHeader={true} size={size}>
                <TableHead>
                    <TableRow>
                        { selectable && <TableCell style={{ width:'48px', padding:'0px 3px' }}><Checkbox color='secondary' disabled={!selectionEnabled} checked={data.data.length !== 0 && (globalSelectAllState === 'ACTIVE' || selectedData.length === data.data.length)} onClick={selectAllClick} /></TableCell> }
                        { columns.map(renderHeaderColumn) }
                    </TableRow>
                </TableHead>
                { !data.isFetching && data.data.length > 0 &&
                    <TableBody>
                        {data.data.map((m: any) => renderRow(m))}
                    </TableBody>
                }
            </MuiTable>
            { (data.isError || data.isFetching || data.isCancelled || data.data.length === 0) && 
            <MessageContainer flexGrow={1}>
                { data.isFetching && <p>Loading...</p> }
                { data.isError && <><p style={{ margin:'5px' }}>An error has occurred</p><DciLink onClick={() => updateData(false, true)}>Retry</DciLink></> }
                { data.isCancelled && <><p style={{ margin:'5px' }}>Cancelled</p><DciLink onClick={() => updateData(false, true)}>Retry</DciLink></> }
                { !data.isFetching && !data.isError && !data.isCancelled && data.data.length === 0 && <p>No items to display</p> }
            </MessageContainer>
            }
        </Stack>
    )
}

function Table({
    onRowClick,
    uniqueSortColumn,
    idFromValue, 
    selectable = false,
    columns,
    size = 'medium'
}: TableProps) {
    const rowData = useTableState(s => s.rowData);
    const updateData = useTableState(s => s.updateRowData);
    const selectedData = useTableState(s => s.selectedData);
    const selectAllClick = useTableState(s => s.selectAllClick);
    const rowSelectClick = useTableState(s => s.rowSelectClick);
    const selectionEnabled = useTableState(s => s.selectionEnabled);
    const globalSelectAllState = useTableState(s => s.globalSelectAllState);
    const sortOrder = useTableState(s => s.sortOrder);
    const toggleSortColumn = useTableState(s => s.toggleSortColumn);
    const clickable = onRowClick ? true : false;

    return <TableInner
        onRowClick={onRowClick}
        uniqueSortColumn={uniqueSortColumn}
        idFromValue={idFromValue}
        selectable={selectable}
        columns={columns}
        data={rowData}
        updateData={updateData}
        selectedData={selectedData}
        selectAllClick={selectAllClick}
        rowSelectClick={rowSelectClick}
        selectionEnabled={selectionEnabled}
        globalSelectAllState={globalSelectAllState}
        sortOrder={sortOrder}
        toggleSortColumn={toggleSortColumn}
        clickable={clickable}
        size={size}
    />
}

export { Table, TableInner, StandardTextCell };
export type { TableProps };