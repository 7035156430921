import { TableCell } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import { TableStoreContext, createTableStore } from '../Table/Stores/TableStore';
import { TableContainer } from '../Table/TableContainer';
import { Table } from '../Table/Table';
import { TableColumn } from '../Filtering/types';

type ReportStatistics = {
    reportTestGroupId: number,
    reportTestId: number,
    reportTestVersionNumber: number,
    dataPointsInspected: number,
    reportableEvents: number,
    runStartDatetime: Date,
    runEndDatetime: Date,
    durationSeconds: number,
    workItemsCreated: number
}

type ReportStatisticsListProps = {
    reportingRunHistoryId: number
}

const displayColumns: TableColumn[] = [
    {
        displayName:'Rule',
        selector:'reportTestId'
    },
    {
        displayName:'Data Points Inspected',
        selector:'dataPointsInspected'
    },
    {
        displayName:'Reportable Events',
        selector:'reportableEvents'
    },
    {
        displayName:'Work Items Created',
        selector:'workItemsCreated'
    },
    {
        displayName:'Run Start',
        selector: 'NOT USED - Run Start',
        render: row => <TableCell key='runStartDatetime'>{format(row.runStartDatetime, 'yyyy-MM-dd HH:mm:ss')}</TableCell>
    },
    {
        displayName:'Run End',
        selector: 'NOT USED - Run End',
        render: row => <TableCell key='runEndDatetime'>{format(row.runEndDatetime, 'yyyy-MM-dd HH:mm:ss')}</TableCell>
    }
]

const mappingFunction = (input: any): ReportStatistics => ({
    ...input,
    runStartDatetime: parseISO(input.runStartDatetime),
    runEndDatetime: parseISO(input.runEndDatetime),
})

const createStore = (reportingRunHistoryId: number) => createTableStore({
        graphQLQueryName: 'reportStatisticsByReportingRunHistoryId',
        graphQLQueryColumns: '{reportTestGroupId,reportTestId,reportTestVersionNumber,dataPointsInspected,reportableEvents,runStartDatetime,runEndDatetime,durationSeconds,workItemsCreated}',
        uniqueSortColumn: 'reportTestId',
        idFromRow: row => row.reportTestId
    },
    {
        mappingFunction: mappingFunction,
        sortOrder: [{ column:'reportTestId' }]
    });

const ReportStatisticsList = ({ reportingRunHistoryId }: ReportStatisticsListProps) => {
    const [ store ] = useState(() => createStore(reportingRunHistoryId))

    return (
        <TableStoreContext.Provider value={store}>
            <TableContainer
                title='Reporting Run History'
                paged
                style={{ height:'100%', width:'100%' }}
                enableExport
            >
                <Table
                    uniqueSortColumn='reportingRunHistoryId'
                    columns={displayColumns}
                    idFromValue={row => row.reportTestId}
                />
            </TableContainer>
        </TableStoreContext.Provider>
    )
}

export { ReportStatisticsList }