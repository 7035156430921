import {
    Typography
} from '@mui/material';

const WNFixesJune23 = () => {
    return (
        <>
            <Typography variant='h5'>Fixes and Minor Improvements</Typography>
            <ul>
                <li>Breadcrumb links no longer reload whole page (e.g. Teams)</li>
                <li>Improved link behaviour in whole application</li>
                <li>Better experience navigating back to views - no loading and view settings retained</li>
                <li>Views remember selection after performing actions on Work Items</li>
            </ul>
        </>
    )
}

export { WNFixesJune23 }