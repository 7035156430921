import {
    Typography
} from '@mui/material';
import ScreenCapture from './Images/WorkItemsForTeam.png';

const WNViewItemsForTeam = () => {
    return (
        <>
            <Typography variant='h5'>View Active Work Items for Team</Typography>
            <img style={{ display:'block', margin:'20px auto' }} src={ScreenCapture} alt="View Items for Team" />
            <Typography variant='body1'>It is now possible view all active Work Items for a given team in the same way
                as has previously been possible for Work Queues and Rules. Simply go to the Team view and click the
                link next to the team name.
            </Typography>
        </>
    )
}

export { WNViewItemsForTeam }