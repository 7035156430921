import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ProtectedApp from './ProtectedApp';
import { ProtectedRoute } from '../../ProtectedComponent';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DciThemeProvider } from './DciTheme';
    
const queryClient = new QueryClient();
    
const App = () => {
    return (
        <BrowserRouter>
            <DciThemeProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <QueryClientProvider client={queryClient}>
                        <Routes>
                            <Route path="/*" element={<ProtectedRoute component={ProtectedApp} />}>
                            </Route>
                        </Routes>
                    </QueryClientProvider>
                </LocalizationProvider>
            </DciThemeProvider>
        </BrowserRouter>
    );
}
        
export { App };
        