import { useNavigate } from 'react-router-dom';
import OrgContainer from './OrgContainer';
import { SingleItem } from '../SingleItem';
import { ItemDisplay, Field } from '../ItemDisplay';
import { 
    Button,
    Typography
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import { AuditItemsByEntity } from '../AuditItemsByEntity';
import { permissions } from '../../utils/dciConstants';
import { useStore } from 'zustand';
import { appGlobalStore } from '../../AppGlobalStore';
import userHasPermission from '../../utils/userHasPermission';

type Organisation = {
    name: string,
    description: string,
    country: string,
    organisationId: number
}

const OrganisationView = () => {
    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const navigate = useNavigate();

    const mappingFunction = (item: any): Organisation => ({
        name: item.organisationName,
        description: item.description,
        country: item.country.description,
        organisationId: item.organisationId
    });

    const fields: Field<Organisation>[] = [
        { render: item => <Typography variant='h5' paragraph>Organisation: {item.name}</Typography> },
        { selector:'description' },
        { selector:'country' },
    ]

    const hasEditPermission = userHasPermission(currentUser, permissions.EDIT_SYSTEM_SETTINGS);

    const Controls = () => <>{ hasEditPermission ? <Button startIcon={<Edit />} onClick={() => navigate('/configuration/organisation/edit')}>Edit</Button> : null }</>;

    return (
        <OrgContainer>
            <SingleItem<Organisation>
                queryName='currentOrganisation'
                queryColumns='{organisationId,country{countryIsoCode,description}description,organisationName}'
                mappingFunction={mappingFunction}
                ItemComponent={({ item }) => <>
                        <ItemDisplay<Organisation> item={item} Controls={Controls} fields={fields} />
                        <AuditItemsByEntity type='Organisation' entityKey={[{ fieldName:'OrganisationId', fieldValue:item.organisationId }]} />
                    </>}
            />
        </OrgContainer>
    );
}

export { OrganisationView }