import { useTheme } from "@mui/material"

const lightModeFill: React.CSSProperties = {
    fill: '#223457'
};

const darkModeFill: React.CSSProperties = {
    fill: '#fff'
};

const DciLogo = () => {
    const theme = useTheme();
    const fill = theme.palette.mode === 'light' ? lightModeFill : darkModeFill;

    return (
        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="138.1 148.3 580.3 280.8">
        <g>
            <g>
                <path style={fill} d="M266.7 148.6H138.1v48h238.1c-21.1-32.5-57.1-48-109.5-48z"/>
                <path style={fill} d="M334.3 288.8c0 69.2-16.4 91.5-68.8 91.5h-68.4V211h-59v218.1h130.1c34 0 60.2-2.7 87.6-24.6 28.9-23.1 42.9-59.8 42.9-110.6 0-33.6-4.8-61.2-14.6-82.9h-70.3c14.9 13.4 20.5 37.8 20.5 77.8zm77.7 5.1c0 50.8 14.1 87.5 43 110.6 27.4 21.9 53.5 24.6 87.5 24.6h99.7v-48.8h-96.9c-49.7 0-68.8-21.1-68.8-84 0-42.4 7.1-70.1 23.8-85.3h-73.6c-9.9 21.7-14.7 49.3-14.7 82.9zm230.2-145.3h-98.1c-52.4 0-88.5 15.5-109.5 48h207.6zm14.4 280.5h61.8V211h-61.8z"/>
                <path d="M718.4 148.3h-61.8v48.3h61.8z" style={{ fill:'#8dc63f' }}/>
            </g>
        </g>
        </svg>
    )
}

export { DciLogo }