import {
    Typography
} from '@mui/material';
import ScreenCapture from './Images/DisableWorkQueueButton.png';

const WNDisableWorkQueue = () => {
    return (
        <>
            <Typography variant='h5'>Disable / Delete Work Queue</Typography>
            <Typography variant='body1'>You now have the ability to disable or delete Work Queues.</Typography>
            <img style={{ display:'block', margin:'20px auto' }} src={ScreenCapture} alt="Disable Work Queue Button" />
            <Typography variant='body1'>When you click the disable button, DCI Analyst will perform some checks. 
                If there are any active Work Items, Rules or Rule Groups associated with the Work Queue a message 
                will be displayed. If there are none of these active items but previously have been, the Work Queue
                will be disabled. If no items have ever been associated with the Work Queue, it will be deleted.
            </Typography>
        </>
    )
}

export { WNDisableWorkQueue }