import React, { useEffect, useState } from 'react';
import { callDciApiCancellable, CancellablePromise } from '../../utils/callDciApi';
import { useAuth0 } from '@auth0/auth0-react';
import { ActiveWorkItemCountByRegulatoryImpactDataContext, WorkItemCountByRegulatoryImpact, WorkItemCountByRegulatoryImpactDataSet } from './ActiveWorkItemCountByRegulatoryImpactDataContext';

type ActiveWorkItemCountByRegulatoryImpactDataProviderProps = {
    workQueueIds: number[],
    teamIds: number[],
    children: JSX.Element
};

const ActiveWorkItemCountByRegulatoryImpactDataProvider = ({ children, workQueueIds, teamIds }: ActiveWorkItemCountByRegulatoryImpactDataProviderProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const [ data, setData ] = useState<WorkItemCountByRegulatoryImpactDataSet | null>(null);
    
    useEffect(() => {
        let mounted = true;
        let cancellablePromise: CancellablePromise | null = null;
        async function fetchData() {
            const token = await getAccessTokenSilently();
            
            if (mounted) {
                setData({
                    rows: [],
                    fetching: true
                });

                cancellablePromise = callDciApiCancellable(`{activeWorkItemsByRegulatoryImpact(workQueueIds:[${workQueueIds.join(',')}],teamIds:[${teamIds.join(',')}]){regulatoryImpactValue,label,colour,count}}`, token);
                cancellablePromise.promise
                .then(body => {
                    if (body.errors) {
                        console.error(body.errors[0].message);
                    } else {
                        setData({
                            rows: body.data.activeWorkItemsByRegulatoryImpact,
                            fetching: false
                        });
                    };
                });
            }
        }

        fetchData();
        return () => {
            mounted = false;
            cancellablePromise?.abortController.abort();
        }
    }, [ workQueueIds.length, teamIds.length ]);

    return (
        <ActiveWorkItemCountByRegulatoryImpactDataContext.Provider value={data}>
            { children }
        </ActiveWorkItemCountByRegulatoryImpactDataContext.Provider>
    )
};

export { ActiveWorkItemCountByRegulatoryImpactDataProvider };