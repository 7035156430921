import { useAuth0 } from "@auth0/auth0-react";
import { Archive, AssignmentInd, AssignmentLate, AssignmentReturn, AssignmentTurnedIn, Block, Check, FolderShared, Info, KeyboardArrowDown, MoveUp, SupervisorAccount } from "@mui/icons-material"
import { alpha, Button, Menu, MenuItem, MenuProps, styled } from "@mui/material"
import { ReactNode, useEffect, useState } from "react";
import callDciApi from "../../utils/callDciApi";
import { workItemStatus } from "../../utils/dciConstants";
import { AssignToQueueDialog } from "../AssignToQueueDialog";
import { OverrideRequestWorkItemActionDialog } from "./OverrideRequestWorkItemActionDialog";
import { StatusChangeWorkItemActionDialog } from "./StatusChangeWorkItemActionDialog";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 26,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

type WorkItemActionsMenuProps = {
    workItemIds: number[],
    refreshParent?: () => void
}

interface ApiWorkItemAction {
    operationName: string
    displayName: string
}

const actionIcons: Record<string, ReactNode> = {
    AssignToWorkQueue: <AssignmentInd />,
    AcceptWorkItem: <FolderShared />,
    MarkAsCorrectionApplied: <Check />,
    MarkAsInformationRequired: <Info />,
    ReferToManager: <SupervisorAccount />,
    CancelReferral: <Archive />,
    ReturnToQueue: <AssignmentReturn />,
    RequestOverride: <MoveUp />,
    CancelOverrideRequest: <Archive />,
    ApproveOverrideRequest: <AssignmentTurnedIn />,
    ApproveOverrideRequestForSameDataOnly: <AssignmentLate />,
    DenyOverrideRequest: <AssignmentReturn />,
    ReallocateWorkQueue: <AssignmentReturn />,
    CancelOverride: <Block />,
}

const WorkItemActionsMenu = ({ workItemIds, refreshParent }: WorkItemActionsMenuProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
    const [ actionsRetrievedAt, setActionsRetrievedAt ] = useState<number | null>();
    const [ actionsRetrievedForIds, setActionsRetrievedForIds ] = useState<string | null>();
    const [ operations, setOperations ] = useState<ApiWorkItemAction[]>([]);
    const [ loading, setLoading ] = useState(false);

    const [ applyActionDialogIsOpen, setApplyActionDialogIsOpen ] = useState(false);
    const [ statusChangeDialogTitle, setStatusChangeDialogTitle ] = useState('');
    const [ statusChangeAction, setStatusChangeAction ] = useState<string>();

    const [ overrideRequestDialogIsOpen, setOverrideRequestDialogIsOpen ] = useState(false);

    const [ assignDialogIsOpen, setAssignDialogIsOpen ] = useState(false);

    const isOpen = Boolean(anchorEl);

    const openApplyActionDialog = (action: ApiWorkItemAction) => {
        setApplyActionDialogIsOpen(true);
        setStatusChangeDialogTitle(action.displayName);
        setStatusChangeAction(action.operationName);
    };

    const acceptWorkItems = async () => {
        const token = await getAccessTokenSilently();
        callDciApi(`mutation{acceptWorkItems(workItemIds:[${workItemIds.join()}])}`, token)
        .then(body => {
            if (!body.errors) {
                if (refreshParent) {
                    refreshParent();
                    getAvailableActions(workItemIds.join());
                }
            } else {
                alert(body.errors[0].message);
            }
        });
    };
    
    // const operationMenuItems = [
    //     {
    //         operationName: "AssignToWorkQueue",
    //         icon: <AssignmentInd />,
    //         caption: "Assign to Work Queue",
    //         handleClick: () => setAssignDialogIsOpen(true)
    //     },
    //     {
    //         operationName: "AcceptWorkItem",
    //         icon: <FolderShared />,
    //         caption: "Accept Work Item",
    //         handleClick: acceptWorkItems
    //     },
    //     {
    //         operationName: "MarkAsCorrectionApplied",
    //         icon: <Check />,
    //         caption: "Correction Applied",
    //         handleClick: () => openApplyActionDialog(workItemStatus.CORRECTED, "Correction Applied")
    //     },
    //     {
    //         operationName: "MarkAsInformationRequired",
    //         icon: <Info />,
    //         caption: "Information Required",
    //         handleClick: () => openApplyActionDialog(workItemStatus.AWAITING_INFORMATION, "Information Required")
    //     },
    //     {
    //         operationName: "ReferToManager",
    //         icon: <SupervisorAccount />,
    //         caption: "Refer to Manager",
    //         handleClick: () => openApplyActionDialog(workItemStatus.REFERRED, "Refer to Manager")
    //     },
    //     {
    //         operationName: "CancelReferral",
    //         icon: <Archive />,
    //         caption: "Cancel Referral",
    //         handleClick: () => openApplyActionDialog(workItemStatus.PROCESSING, "Cancel Referral")
    //     },
    //     {
    //         operationName: "ReturnToQueue",
    //         icon: <AssignmentReturn />,
    //         caption: "Return to Queue",
    //         handleClick: () => openApplyActionDialog(workItemStatus.QUEUED, "Return to Queue")
    //     },
    //     {
    //         operationName: "RequestOverride",
    //         icon: <MoveUp />,
    //         caption: "Request Override",
    //         handleClick: () => setOverrideRequestDialogIsOpen(true)
    //     },
    //     {
    //         operationName: "CancelOverrideRequest",
    //         icon: <Archive />,
    //         caption: "Cancel Override Request",
    //         handleClick: () => openApplyActionDialog(workItemStatus.PROCESSING, "Cancel Override Request")
    //     },
    //     {
    //         operationName: "ApproveOverrideRequest",
    //         icon: <AssignmentTurnedIn />,
    //         caption: "Approve Override Request",
    //         handleClick: () => openApplyActionDialog(workItemStatus.OVERRIDE_APPROVED, "Approve Override Request")
    //     },
    //     {
    //         operationName: "ApproveOverrideRequestForSameDataOnly",
    //         icon: <AssignmentLate />,
    //         caption: "Approve Override Request for Same Data Only",
    //         handleClick: () => openApplyActionDialog(workItemStatus.OVERRIDE_APPROVED_SAME_DATA_ONLY, "Approve Override Request for Same Data Only")
    //     },
    //     {
    //         operationName: "DenyOverrideRequest",
    //         icon: <AssignmentReturn />,
    //         caption: "Deny Override Request",
    //         handleClick: () => openApplyActionDialog(workItemStatus.QUEUED, "Deny Override Request")
    //     },
    //     {
    //         operationName: "ReallocateWorkQueue",
    //         icon: <AssignmentReturn />,
    //         caption: "Re-allocate Work Queue",
    //         handleClick: () => openApplyActionDialog(workItemStatus.RECEIVED, "Re-allocate Work Queue")
    //     },
    //     {
    //         operationName: "CancelOverride",
    //         icon: <Block />,
    //         caption: "Cancel Override",
    //         handleClick: () => openApplyActionDialog(workItemStatus.QUEUED, "Cancel Override", 'cancelOverride')
    //     },
    // ];

    const getAvailableActions = async (joinedWorkItemIds: string) => {
        if (joinedWorkItemIds === '') {
            setOperations([]);
            setActionsRetrievedAt(Date.now());
            setActionsRetrievedForIds(joinedWorkItemIds);
            return;
        }

        const token = await getAccessTokenSilently();
        const query = `{availableWorkItemActions(workItemIds:[${joinedWorkItemIds}]){operationName,displayName}}`;

        setLoading(true);
        callDciApi(query, token)
        .then(body => {
            setLoading(false);
            if (!body.errors) {
                const newOperations = body.data.availableWorkItemActions as ApiWorkItemAction[];
                setOperations(newOperations);
                setActionsRetrievedAt(Date.now());
                setActionsRetrievedForIds(joinedWorkItemIds);
                if (newOperations.length < 2 && anchorEl !== null) {
                    setAnchorEl(null);
                }
            }
        });
    }

    useEffect(() => {
        getAvailableActions(workItemIds.join());
    }, [ workItemIds.join() ])

    const menuOpenHandleClick = (event: React.MouseEvent<HTMLElement>) => {
        const twoMinutes = 120000;
        const currentIds = workItemIds.join();
        const now = Date.now();
        if (!actionsRetrievedAt || now - actionsRetrievedAt > twoMinutes || actionsRetrievedForIds !== currentIds) {
            getAvailableActions(currentIds);
        }

        setAnchorEl(event.currentTarget);
    };

    const menuHandleClose = () => {
        setAnchorEl(null);
    };

    const closeStatusChangeDialog = (refresh: boolean) => {
        setApplyActionDialogIsOpen(false);
        if (refresh && refreshParent) {
            refreshParent();
        }
    };

    const closeOverrideRequestDialog = (refresh: boolean) => {
        setOverrideRequestDialogIsOpen(false);
        if (refresh && refreshParent) {
            refreshParent();
        }
    };

    const closeAssignToQueueDialog = (refresh: boolean) => {
        setAssignDialogIsOpen(false);
        if (refresh && refreshParent) {
            refreshParent();
        }
    };

    const handleOptionClick = (action: ApiWorkItemAction) => {
        switch(action.operationName) {
            case 'AssignToWorkQueue':
                setAssignDialogIsOpen(true);
                break;
            case 'AcceptWorkItem':
                acceptWorkItems();
                break;
            case 'RequestOverride':
                setOverrideRequestDialogIsOpen(true);
                break;
            default:
                openApplyActionDialog(action);
                break;
        }
    }

    return (
    <>
        { operations.length === 1
            ? <Button
                id="single-workitem-action-button"
                style={{ margin: '0px 8px' }}
                variant='contained'
                disableElevation
                onClick={() => handleOptionClick(operations[0])}
                startIcon={actionIcons[operations[0].operationName]}
            >
                {operations[0].displayName}
            </Button>
            : <>
                <Button
                    id="workitem-actions-menu-button"
                    style={{ margin: '0px 4px' }}
                    aria-controls={ isOpen ? 'workitem-actions-menu' : undefined }
                    aria-haspopup="true"
                    aria-expanded={ isOpen ? 'true' : undefined }
                    variant='contained'
                    disableElevation
                    disabled={workItemIds.length === 0 || operations.length === 0}
                    onClick={menuOpenHandleClick}
                    endIcon={<KeyboardArrowDown />}
                >
                    Actions
                </Button>
                <StyledMenu
                    id='workitem-actions-menu'
                    anchorEl={anchorEl}
                    open={isOpen}
                    onClose={menuHandleClose}
                >
                    { loading 
                        ? <MenuItem disabled>
                            Loading...
                        </MenuItem>
                        : operations.length === 0
                            ? <MenuItem disabled>
                                No Actions Available
                            </MenuItem>
                            : operations
                                .map(m => <MenuItem
                                        key={m.operationName}
                                        disableRipple
                                        onClick={() => { setAnchorEl(null); handleOptionClick(m); }}
                                    >
                                        {actionIcons[m.operationName]}
                                        {m.displayName}
                                    </MenuItem>)
                }
                </StyledMenu>
        </>}

        <StatusChangeWorkItemActionDialog 
            workItemIds={workItemIds}
            isOpen={applyActionDialogIsOpen}
            close={closeStatusChangeDialog}
            title={statusChangeDialogTitle}
            action={statusChangeAction}
        />

        <OverrideRequestWorkItemActionDialog
            workItemIds={workItemIds}
            isOpen={overrideRequestDialogIsOpen}
            close={closeOverrideRequestDialog}
        />

        {/* 
            This is never likely to be available from a menu of multiple options, but we will leave
            it here anyway, as the resulting behaviour will be very difficult to debug
            */}
        <AssignToQueueDialog
            workItemIds={workItemIds}
            isOpen={assignDialogIsOpen}
            close={closeAssignToQueueDialog}
        />
    </>
    )
}

export { WorkItemActionsMenu }