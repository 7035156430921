import { addDays, format, subDays } from 'date-fns';
import { range, sum } from 'lodash';

const num30DayMonths = 13;

const riRedCreatedDaily = [ 0,1,0,0,0,3,0,0,0,0,1,0,0,0,4,0,0,0,0,2,0,0,5,0,0,1,0,1,0,0 ];
const riAmberCreatedDaily = [ 0,0,5,0,0,0,0,0,15,0,0,2,3,0,0,17,0,0,0,0,22,0,0,6,0,2,1,6,8,0 ];
const riGreenCreatedDaily = [ 24,5,3,0,55,0,6,44,0,12,2,8,62,0,0,25,25,22,0,0,12,0,3,0,5,18,0,22,5,1 ];

const riCreatedData = [
    [ 0,1,0,0,0,3,0,0,0,0,1,0,0,0,4,0,0,0,0,2,0,0,5,0,0,1,0,1,0,0 ],
    [ 0,0,5,0,0,0,0,0,15,0,0,2,3,0,0,17,0,0,0,0,22,0,0,6,0,2,1,6,8,0 ],
    [ 24,5,3,0,55,0,6,44,0,12,2,8,62,0,0,25,25,22,0,0,12,0,3,0,5,18,0,22,5,1 ]
];

const calculateCumulative = (month: number[]) => {
    const preMonthTally = sum(month) * num30DayMonths;
    return month.map((_, index, arr) => preMonthTally + sum(arr.slice(0, index + 1)));
}

const riRedCreatedCumulative = calculateCumulative(riRedCreatedDaily);
const riAmberCreatedCumulative = calculateCumulative(riAmberCreatedDaily);
const riGreenCreatedCumulative = calculateCumulative(riGreenCreatedDaily);

const riCreatedCumulativeData = [
    calculateCumulative(riRedCreatedDaily),
    calculateCumulative(riAmberCreatedDaily),
    calculateCumulative(riGreenCreatedDaily)
];

const riResolvedData = [
    [ 0, 0, 0, 0, 1, 2, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 3, 0, 0, 2, 0, 0, 1, 0, 0, 1, 1, 0, 0, 0 ],
    [ 4, 2, 0, 6, 1, 2, 0, 0, 0, 3, 4, 1, 7, 2, 0, 0, 1, 5, 3, 2, 0, 0, 4, 4, 0, 5, 4, 7, 2, 4 ],
    [ 10, 12, 5, 6, 15, 21, 4, 10, 10, 13, 14, 1, 8, 2, 20, 17, 21, 15, 0, 0, 10, 15, 14, 14, 2, 15, 24, 21, 22, 14 ]
];

const currentActiveByRi = [
    5,
    68,
    283
];

const calculateCumulativeFromEndNumber = (negData: number[][], posData: number[][], endNumbers: number[]) => {
    const res = range(0, endNumbers.length).map(r => [] as number[]);
    const acc = [ ...endNumbers ];
    for (let i = negData[0].length - 1; i >= 0; i--) {
        for (let j = 0; j < acc.length; j++) {
            res[j][i] = acc[j];
            acc[j] += (negData[j][i] - posData[j][i]);
        }
    }

    return res;
};

const riActiveCumulative = calculateCumulativeFromEndNumber(riResolvedData, riCreatedData, currentActiveByRi);

// ===== Priority =====

const priorityCreatedData = [
    [ 0,1,0,0,0,2,0,1,0,0,1,0,0,0,4,5,0,1,0,2,2,0,4,0,0,1,0,2,0,0 ],
    [ 1,0,4,0,1,1,0,4,4,0,0,2,1,0,0,12,0,1,0,0,0,0,1,0,0,2,0,8,2,0 ],
    [ 0,2,0,0,9,0,0,3,1,12,1,0,12,0,0,0,13,3,0,0,17,0,0,3,2,0,0,12,0,0 ],
    [ 8,3,0,0,10,0,6,5,6,0,1,4,0,0,0,18,0,17,0,0,13,0,2,1,0,4,1,0,11,0 ],
    [ 15,0,4,0,35,0,0,31,4,0,0,4,52,0,0,7,12,0,0,0,2,0,1,2,3,14,0,7,0,1 ]
];

const priorityResolvedData = [
    [0,0,0,2,0,1,0,1,0,0,2,0,0,1,2,0,4,0,3,2,1,1,2,1,0,3,0,0,0,0],
    [0,2,4,4,1,0,0,6,3,4,1,1,5,6,1,4,5,0,0,0,1,2,3,1,1,2,2,1,2,6],
    [4,2,6,1,1,0,1,0,0,7,5,2,0,0,0,0,4,7,6,5,1,0,0,1,1,5,6,3,5,3],
    [4,1,4,6,7,1,2,0,0,5,4,1,3,10,2,4,3,4,4,1,0,0,1,0,0,4,9,5,4,4],
    [10,7,8,4,1,3,3,11,5,6,7,0,1,1,15,1,3,4,8,6,1,6,0,3,0,7,12,19,13,5]
];

const currentActiveByPriority = [
    3,
    28,
    66,
    118,
    141
];

const overdueByRi = [
    [  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  2,  1,  1,  0,  0,  0,  0,  0,  0,  0,  0 ],
    [  5,  8,  8,  8,  4,  3,  2,  2,  2,  2,  1,  0,  0,  0,  4,  4,  9,  5,  5,  5,  6,  6,  3,  3,  3,  2,  1,  1,  0,  4 ],
    [ 17, 17, 14, 15, 15, 12, 12, 13, 15, 16, 17, 17, 17, 11, 12, 12,  9, 12, 12, 12, 17, 19, 19, 21, 21, 14, 14, 19, 19, 19 ]
];

const overdueByPriority = [
    [ 0,0,0,0,0,0,0,0,1,1,2,0,0,0,0,0,0,0,0,2,1,1,0,0,0,0,0,0,0,1 ],
    [ 0,3,6,6,3,2,2,2,2,5,1,1,1,1,4,4,8,3,3,1,2,3,3,3,3,2,1,1,0,2 ],
    [ 3,3,3,3,5,2,0,0,3,7,6,2,0,0,0,0,0,2,4,2,5,4,0,7,2,4,2,3,3,4 ],
    [ 6,6,0,1,0,0,3,4,4,1,5,0,3,2,3,2,0,1,0,2,7,5,4,5,6,3,3,4,4,4 ],
    [ 13,13,13,13,11,11,9,9,7,4,4,14,13,8,9,10,10,11,10,12,9,13,15,9,13,7,9,12,12,12 ]
];

const priorityActiveCumulative = calculateCumulativeFromEndNumber(priorityResolvedData, priorityCreatedData, currentActiveByPriority);

const priorityCumulativeData = [
    calculateCumulative(priorityCreatedData[0]),
    calculateCumulative(priorityCreatedData[1]),
    calculateCumulative(priorityCreatedData[2]),
    calculateCumulative(priorityCreatedData[3]),
    calculateCumulative(priorityCreatedData[4]),
];

const priorityColours = [
    '#2D9BF0',
    '#93C01F',
    '#DA0063',
    '#12CDD4',
    '#652CB3'
];

const thirtyDaysAgo = subDays(new Date(), 30);

const last30DaysIdentifiers = range(1, 31).map(m => format(addDays(thirtyDaysAgo, m), 'yyyy-MM-dd'));
const last30DaysLabels = range(1, 31).map(m => format(addDays(thirtyDaysAgo, m), 'dd/MM/yyyy'));

type FakeData = {
    [metric: string]: {
        [grouping: string]: {
            colour: string,
            data: number[],
            groupId: number,
            label: string
        }[]
    }
}

const ColourRed = 'red';
const ColourAmber = '#fa9000';
const ColourGreen = 'green';

const riColours = [
    ColourRed,
    ColourAmber,
    ColourGreen
];

const riDescriptions = [
    'Potential reportable breach',
    'Potential non-reportable breach',
    'No regulatory impact'
];

const demoData: FakeData = {
    'Active': {
        'None': [
            {
                colour: priorityColours[0],
                data: range(0, riActiveCumulative[0].length).map(r => riActiveCumulative[0][r] + riActiveCumulative[1][r] + riActiveCumulative[2][r]),
                groupId: 0,
                label: 'All'
            }
        ],
        'Overdue Status': [
            {
                colour: 'red',
                data: range(0, riActiveCumulative[0].length).map(r => 
                    (overdueByRi[0][r] + overdueByRi[1][r] + overdueByRi[2][r])
                ),
                groupId: 0,
                label: 'Overdue'
            },
            {
                colour: priorityColours[0],
                data: range(0, riActiveCumulative[0].length).map(r => 
                    (riActiveCumulative[0][r] + riActiveCumulative[1][r] + riActiveCumulative[2][r]) - (overdueByRi[0][r] + overdueByRi[1][r] + overdueByRi[2][r])
                ),
                groupId: 1,
                label: 'On target'
            }
        ],
        'RegulatoryImpactId': [ 0, 1, 2 ].map(m => ({
            colour: riColours[m],
            data: riActiveCumulative[m],
            groupId: m,
            label: riDescriptions[m]
        })),
        'RulePriority': priorityActiveCumulative.map((m, i) => ({
            colour: priorityColours[i],
            data: m,
            groupId: i,
            label: `Priority ${i + 1}`
        })),
        'WorkQueue': [ 0, 1, 2 ].map(m => ({
            colour: priorityColours[m],
            data: riActiveCumulative[m],
            groupId: m,
            label: [ 'Operations', 'Front Office', 'Risk' ][m]
        }))
    },
    'Added': {
        'None': [
            {
                colour: priorityColours[0],
                data: range(0, riCreatedData[0].length).map(r => riCreatedData[0][r] + riCreatedData[1][r] + riCreatedData[2][r]),
                groupId: 0,
                label: 'All'
            }
        ],
        'RegulatoryImpactId': [ 0, 1, 2 ].map(m => ({
            colour: riColours[m],
            data: riCreatedData[m],
            groupId: m,
            label: riDescriptions[m]
        })),
        'RulePriority': [ 0, 1, 2, 3, 4 ].map(m => ({
            colour: priorityColours[m],
            data: priorityCreatedData[m],
            groupId: m,
            label: `Priority ${m + 1}`
        })),
        'WorkQueue': [ 0, 1, 2 ].map(m => ({
            colour: priorityColours[m],
            data: riCreatedData[m],
            groupId: m,
            label: [ 'Operations', 'Front Office', 'Risk' ][m]
        }))
    },
    'Overdue': {
        'None': [
            {
                colour: priorityColours[0],
                data: range(0, overdueByRi[0].length).map(r => overdueByRi[0][r] + overdueByRi[1][r] + overdueByRi[2][r]),
                groupId: 0,
                label: 'All'
            }
        ],
        'RegulatoryImpactId': [ 0, 1, 2 ].map(m => ({
            colour: riColours[m],
            data: overdueByRi[m],
            groupId: m,
            label: riDescriptions[m]
        })),
        'RulePriority': overdueByPriority.map((m, i) => ({
            colour: priorityColours[i],
            data: m,
            groupId: i,
            label: `Priority ${i + 1}`
        })),
        'WorkQueue': [ 0, 1, 2 ].map(m => ({
            colour: priorityColours[m],
            data: overdueByRi[m],
            groupId: m,
            label: [ 'Operations', 'Front Office', 'Risk' ][m]
        }))
    },
    'Resolved': {
        'None': [
            {
                colour: priorityColours[0],
                data: range(0, riResolvedData[0].length).map(r => riResolvedData[0][r] + riResolvedData[1][r] + riResolvedData[2][r]),
                groupId: 0,
                label: 'All'
            }
        ],
        'RegulatoryImpactId': [ 0, 1, 2 ].map(m => ({
            colour: riColours[m],
            data: riResolvedData[m],
            groupId: m,
            label: riDescriptions[m]
        })),
        'RulePriority': priorityResolvedData.map((m, i) => ({
            colour: priorityColours[i],
            data: m,
            groupId: i,
            label: `Priority ${i + 1}`
        })),
        'WorkQueue': [ 0, 1, 2 ].map(m => ({
            colour: priorityColours[m],
            data: riResolvedData[m],
            groupId: m,
            label: [ 'Operations', 'Front Office', 'Risk' ][m]
        }))
    }
};

export { 
    riRedCreatedDaily,
    riAmberCreatedDaily,
    riGreenCreatedDaily,
    riRedCreatedCumulative,
    riAmberCreatedCumulative,
    riGreenCreatedCumulative,
    last30DaysIdentifiers,
    last30DaysLabels,

    demoData
 }