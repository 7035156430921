import React, { useEffect } from 'react';
import { differenceInCalendarDays, format, parseISO } from 'date-fns';
import { getBreadcrumbMapping, SegmentMap } from '../Layout/DciBreadcrumb';
import {
    Grid,
    styled,
    Typography,
} from '@mui/material';

import { ApiNote, NotesDisplay } from '../NotesDisplay';
import { ChildItemProps, SingleItem } from '../SingleItem';
import { Field, ItemDisplay } from '../ItemDisplay';
import { AuditItemsByEntity } from '../AuditItemsByEntity';
import DciLink from '../DciLink';
import dciPaths from '../../utils/dciPaths';
import useReferrer from '../../utils/useReferrer';
import { WorkItemActionsMenu } from './WorkItemActionsMenu';

const ControlBar = styled('div')`
    text-align:right;
    margin-bottom:10px;    
`;

type WorkItemViewProps = {
    id: number,
    setBreadcrumbs: React.Dispatch<React.SetStateAction<SegmentMap[]>>
}

const WorkItemView = ({ id, setBreadcrumbs }: WorkItemViewProps) => {
    const referrer = useReferrer();
    
    useEffect(() => setBreadcrumbs(_ => {
        const breadcrumbs = [{ text:'Work Items' }] as SegmentMap[];
        if (referrer) {
            const mapping = getBreadcrumbMapping(referrer.substring(referrer.lastIndexOf('/') + 1));
            if (mapping) {
                breadcrumbs.push({
                    text: mapping.text,
                    linkTo: referrer
                })
            }
        }

        breadcrumbs.push({ text:`Work Item ${id}` });
        return breadcrumbs;
    }), [ id, referrer ]);
    
    const ItemComponent = ({ item, refresh }: ChildItemProps<WorkItem>) => {
        const today = new Date();
        const age = !item ? 0 : differenceInCalendarDays(today, item.creationDatetime);
        const ageDisplay = age === 0 ? '(today)' : `(${age} day${(age === 1 ? '' : 's')} ago)`;

        const target = ! item ? 0 : differenceInCalendarDays(today, item.dueDate);;
        const targetDisplay = target === 0 ? '(today)' : `(${Math.abs(target)} day${(target === 1 ? '' : 's')} ${target < 0 ? 'away' : 'overdue'})`;
        const targetColour = target < 0 ? 'inherit' : 'error';

        const renderStatus = (item: WorkItem) => {
            let value = item.workItemStatusDescription;
            if (item.overrideUntil) {
                value += ` until ${format(item.overrideUntil, 'dd/MM/yyyy')}`;
            }

            return <>
                <Typography variant='caption' display='block'>Status</Typography>
                <Typography paragraph color="textSecondary" variant="body2">{value}</Typography>
            </>
        }

        const displayFields: Field<WorkItem>[] = [
            { render: item => <Typography key='item' variant='h5' paragraph>Work Item {item.workItemId}</Typography> },
            { 
                render: item => (
                    <div key='found'>
                        <Typography display='inline'>Found: {item.creationDatetime.toLocaleDateString()}</Typography>
                        <Typography display='inline' variant='caption' style={{ marginLeft: 5 }}>{ageDisplay}</Typography>
                    </div>
                )
            },
            { 
                render: item => (
                    <>{ item.workItemStatusCurrentStatus &&
                        <div key='due' style={{ marginBottom:'10px'}}>
                            <Typography display='inline'>Due: {item.dueDate.toLocaleDateString()}</Typography>
                            <Typography gutterBottom variant='caption' color={targetColour} style={{ marginLeft: 5 }} display='inline'>{targetDisplay}</Typography>
                        </div>
                    }</> 
                )
            },
            { selector: 'reportMessage' },
            { selector: 'reportFixMessage', displayName: 'Resolution Recommendation' },
            { selector: 'workItemPriority', displayName: 'Priority' },
            { selector: 'workQueueName', displayName: 'Work Queue', missingValue: 'Not Assigned' },
            { displayName: 'Assigned To', valueResolver: item => item.processingUserId ? <DciLink href={dciPaths.user.buildLink(item.processingUserId)}>{item.processingUserFirstName} {item.processingUserSurname}</DciLink> : 'Not Assigned' },
            { displayName: 'Rule', valueResolver: item => <DciLink href={dciPaths.rule.buildLink(item.reportTestId)}>{item.reportTestId}</DciLink> },
            { render: renderStatus },
            { displayName: 'Last Updated By', valueResolver: item => item.lastUpdatedBy ? <DciLink href={dciPaths.user.buildLink(item.lastUpdatedBy.userId)}>{item.lastUpdatedBy.firstName} {item.lastUpdatedBy.surname}</DciLink> : '[System]' },
            { displayName: 'Last Updated At', valueResolver: item => item.lastUpdateDatetime.toLocaleString() }
        ];

        const Controls = () => 
            <React.Fragment key='controls'>
                <ControlBar>
                    <WorkItemActionsMenu workItemIds={[ id ]} refreshParent={refresh} />
                </ControlBar>
            </React.Fragment>;

        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <ItemDisplay<WorkItem> item={item} fields={displayFields} Controls={Controls} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <NotesDisplay workItemId={id} initialNotes={item.notes} />
                </Grid>
                <Grid item xs={12}>
                    <AuditItemsByEntity type='WorkItem' entityKey={[{ fieldName:'WorkItemId', fieldValue:id }]} />
                </Grid>
            </Grid>
        );
    };

    const mappingFunction = (item: any): WorkItem => ({
        creationDatetime: parseISO(item.creationDatetime),
        dueDate: parseISO(item.dueDate),
        lastUpdateDatetime: parseISO(item.lastUpdateDatetime),
        lastUpdatedBy: item.lastUpdatedBy,
        notes: item.notes,
        processingUserId: !item.processingUser ? null : item.processingUser.userId,
        processingUserFirstName: !item.processingUser ? null : item.processingUser.firstName,
        processingUserSurname: !item.processingUser ? null : item.processingUser.surname,
        reportFixMessage: item.reportFixMessage,
        reportMessage: item.reportMessage,
        reportTestId: item.reportTest.reportTestId,
        reportTestVersionNumber: item.reportTest.versionNumber,
        workItemId: item.workItemId,
        workItemPriority: item.workItemPriority,
        workItemStatusId: item.workItemStatus.workItemStatusId,
        workItemStatusDescription: item.workItemStatus.description,
        workItemStatusCurrentStatus: item.workItemStatus.currentStatus,
        workQueueId: !item.workQueue ? null : item.workQueue.workQueueId,
        workQueueName: !item.workQueue ? null : item.workQueue.name,
        overrideUntil: item.overrideUntil ? parseISO(item.overrideUntil) : null
    });

    return (
        <SingleItem<WorkItem>
            queryName={'workItemById'}
            queryParameters={`id:${id}`}
            queryColumns={'{workItemId,creationDatetime,lastUpdateDatetime,overrideUntil,lastUpdatedBy{firstName,surname,userId}dueDate,reportMessage,reportFixMessage,processingUser{userId,firstName,surname}workItemStatus{description,workItemStatusId,currentStatus}reportTest{reportTestId,versionNumber}workItemPriority,workQueue{workQueueId,name}notes{note,workItemNoteId,createdByName,noteType{noteTypeId,description}creationDatetime}}'}
            mappingFunction={mappingFunction}
            ItemComponent={({item, refresh}) => <ItemComponent item={item} refresh={refresh} />}
        />
    );
};

type User = {
    userId: number,
    firstName: string,
    surname: string
}

type WorkItem = {
    creationDatetime: Date,
    dueDate: Date,
    lastUpdateDatetime: Date,
    lastUpdatedBy: User,
    notes: ApiNote[],
    processingUserId: number | null,
    processingUserFirstName: string | null,
    processingUserSurname: string | null,
    reportFixMessage: string,
    reportMessage: string,
    reportTestId: number,
    reportTestVersionNumber: number,
    workItemId: number,
    workItemPriority: number,
    workItemStatusId: number,
    workItemStatusDescription: string,
    workItemStatusCurrentStatus: boolean,
    workQueueId: number | null,
    workQueueName: string | null,
    overrideUntil: Date | null,
    [key: string]: any
}

export { WorkItemView }