import { StandardTextCell, Table } from "../Table/Table"
import { TableContainer } from "../Table/TableContainer"
import { TableColumn, TableColumnFilter } from "../Filtering/types";
import { format, parseISO } from "date-fns";
import { FILTER_TYPE } from "../Filtering/FilterRepository";
import { createTableStore, TableStoreContext } from "../Table/Stores/TableStore";
import { useState } from "react";
import { FilterStoreContext } from "../Filtering/StoreFunctions";

const filterColumns: TableColumnFilter[] = [
    {
        name: 'userId',
        displayName: 'User',
        type: FILTER_TYPE.USER
    },
    {
        name: 'date',
        displayName: 'Date',
        type: FILTER_TYPE.DATE
    },
];

const idFromValue = (row: UserActivity) => `${row.userId}-${format(row.date, 'yyyy-MM-dd')}`;

const columns: TableColumn[] = [
    {
        displayName: "Date",
        selector: "date",
        render: (row: UserActivity) => <StandardTextCell cellKey='date'>{format(row.date, 'yyyy-MM-dd')}</StandardTextCell>,
        style: { width:'78px' }
    },
    {
        displayName: 'User',
        selector: 'userName',
        sortable: false,
        style: { width:'58px' }
    },
    {
        displayName: "Number of Interactions",
        selector: 'apiCallCount',
        style: { width:'96px' }
    },
    { 
        displayName: "Earliest Interaction",
        selector: 'earliestActivity',
        render: (row: UserActivity) => <StandardTextCell cellKey='earliestActivity'>{format(row.earliestActivity, 'HH:mm:ss')}</StandardTextCell>,
        style: { width:'96px' }
    },
    {
        displayName: "Latest Interaction",
        selector: 'latestActivity',
        render: (row: UserActivity) => <StandardTextCell cellKey='latestActivity'>{format(row.latestActivity, 'HH:mm:ss')}</StandardTextCell>,
        style: { width:'96px' }
    }
];

const UserActivityView = () => {
    const [ store ] = useState(() => {
        return createTableStore({
            graphQLQueryName: 'userActivity',
            graphQLQueryColumns: '{date apiCallCount earliestActivity latestActivity organisationId user{userId firstName surname}}',
            idFromRow: idFromValue,
            uniqueSortColumn: 'userId' // TODO: This is not really unique but is it unique enough?
        },
        {
            paged: true,
            sortOrder: [{ column: 'date', direction: 'DESC' }],
            filterColumns: filterColumns,
            mappingFunction: mappingFunction
        });
    })

    return (
        <TableStoreContext.Provider value={store}>
            <FilterStoreContext.Provider value={store}>
                <TableContainer
                    title='User Activity'
                    paged
                    style={{ height:'100%', width:'100%' }}
                    enableFilter
                    enableExport
                    >
                    <Table
                        uniqueSortColumn='workItemId'
                        columns={columns}
                        idFromValue={idFromValue}
                        />
                </TableContainer>
            </FilterStoreContext.Provider>
        </TableStoreContext.Provider>
    )
}
const mappingFunction = (row: any) => ({
    date: parseISO(row.date),
    userId: row.user.userId,
    userName: [ row.user.firstName, row.user.surname ].join(' '),
    apiCallCount: row.apiCallCount,
    earliestActivity: parseISO(row.earliestActivity),
    latestActivity: parseISO(row.latestActivity),
})

interface UserActivity {
    date: Date,
    userId: number,
    userName: string,
    apiCallCount: number,
    earliestActivity: Date,
    latestActivity: Date,
}

export { UserActivityView }