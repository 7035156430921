import React, { useEffect, useMemo, useState } from 'react';
import { callDciApiCancellable, CancellablePromise } from '../../utils/callDciApi';
import { useAuth0 } from '@auth0/auth0-react';
import dciPaths from '../../utils/dciPaths';
import { encodeURI } from 'js-base64';
import { ReportTypes } from '../../utils/ReportTypes';
import { HeadlineCardData, HeadlineCardDataContext } from './HeadlineCardDataContext';
import { PARAMETER_TYPE, QueryParameter } from '../../GraphQLShared';
import { addDays } from 'date-fns';

type OverdueWorkItemCountHeadlineCardDataProviderProps = {
    workQueueIds: number[],
    teamIds: number[],
    children: JSX.Element
};

const OverdueWorkItemCountHeadlineCardDataProvider = ({ children, workQueueIds, teamIds }: OverdueWorkItemCountHeadlineCardDataProviderProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const [ value, setValue ] = useState<string | null>(null);
    const [ loading, setLoading ] = useState(true);

    const reportLink = useMemo(() => {
        var reportParameters = {
            title: "Overdue Work Items",
            parameters: [
                {
                    name: "fixedFilter.workItemStatusId",
                    type: PARAMETER_TYPE.NUMBER,
                    value: [ 1, 2, 3, 4, 6, 8 ]
                },
                {
                    name: 'fixedFilter.dueDate',
                    type: PARAMETER_TYPE.DATE,
                    value: { onOrBefore: addDays(new Date(), -1) }
                }
            ] as QueryParameter[]
        };

        if (workQueueIds.length > 0) {
            reportParameters.parameters.push({
                name: 'fixedFilter.workQueueId',
                type: PARAMETER_TYPE.NUMBER,
                value: workQueueIds
            });
        }

        if (teamIds.length > 0) {
            reportParameters.parameters.push({
                name: 'fixedFilter.teamId',
                type: PARAMETER_TYPE.NUMBER,
                value: teamIds
            });
        }

        const base64 = encodeURI(JSON.stringify(reportParameters));
        return `/workitems?q=${base64}`;
    }, [ workQueueIds.length, teamIds.length ]);

    useEffect(() => {
        let mounted = true;
        let cancellablePromise: CancellablePromise | null = null;
        async function fetchData() {
            const token = await getAccessTokenSilently();
            
            if (mounted) {
                setLoading(true);
                cancellablePromise = callDciApiCancellable(`{totalWorkItemsOverdue(workQueueIds:[${workQueueIds.join(',')}],teamIds:[${teamIds.join(',')}])}`, token);
                cancellablePromise.promise
                .then(body => {
                    setLoading(false);
                    if (body.errors) {
                        console.error(body.errors[0].message);
                    } else {
                        setValue(body.data.totalWorkItemsOverdue);
                    };
                });
            }
        }

        fetchData();
        return () => {
            mounted = false;
            cancellablePromise?.abortController.abort();
        }
    }, [ workQueueIds.length, teamIds.length ]);

    const headlineCardData: HeadlineCardData = {
        title: 'Overdue Work Items',
        value: value,
        fetching: loading,
        link: reportLink
    };

    return (
        <HeadlineCardDataContext.Provider value={headlineCardData}>
            { children }
        </HeadlineCardDataContext.Provider>
    )
}

export { OverdueWorkItemCountHeadlineCardDataProvider };